import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css"
import { AppRouter } from './routes/AppRouter';
import 'antd/dist/antd.css';
import { Provider } from 'react-redux';
import { store } from './store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={ store }>
        <ToastContainer />
        <AppRouter></AppRouter>
    </Provider>
        
);
