import React, { useCallback } from "react";
import {
	AutoComplete,
	Input,
	Col,
	Row,
	Dropdown,
	Menu,
	Space,
	Select,
} from "antd";

import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { startLogout } from "../../store/auth";
import { useState, useEffect } from "react";
import { getQueryDocByCollection } from "../../helpers/globalHelper";

export const NavBar = () => {
	const [searchBarUsers, setSearchBarUsers] = useState([]);
	const [userSearchList, setUserSearchList] = useState([]);

	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(startLogout());
	};

	const { Option } = Select;

	useEffect(() => {
		/* let reportUserProfiles = getQueryDocByCollection(
			"profile",
			"residentialsIds",
			"array-contains",
			"Yd5MV7jkA0MjQeCmzKrZ" // Tuscania ID
		).then((searchBarUsers) => setSearchBarUsers(searchBarUsers)); */
	}, []);

	searchBarUsers.map(async (item, index) => {
		/* userSearchList.push({ label: item.name, value: item.id }); */
		let fullname = item.name + " " + item.lastname;

		userSearchList.push(
			<Option key={index} value={item.id} label={fullname}>
				{/* <a href={"/reporte-actividad/" + item.id}>{fullname}</a> */}
				{fullname}
			</Option>
		);
		return item;
	});
	const SearchIcon = () => <img src="/assets/icons/search.svg" />;

	const menu = (
		<Menu
			items={[
				{
					key: "1",
					danger: true,
					label: (
						<a target="_blank" rel="noopener noreferrer" onClick={handleLogout}>
							Salir
						</a>
					),
				},
			]}
		/>
	);

	const goToUser = (id) => {
		/* navigate(`/reporte-actividad/${id}`, { replace: false, key: "test" }); */
		window.location = `/reporte-actividad/${id}`;
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<div className="navbar">
						<Row>
							<Col span={19} sm={18} className="search-navbar">
								<div className="container-search">
									{/* <AutoComplete
											style={{
												width: "100%",
											}}
											listHeight={10}
											placeholder="Buscar por nombre"
											options={userSearchList}
											prefix={<SearchOutlined />}
										/> */}

									<Select
										placeholder="Buscar cuenta"
										style={{
											width: "70%",
										}}
										allowClear
										showSearch
										filterOption={(input, option) => {
											return (
												option.label
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											);
										}}
										onChange={(id) => {
											goToUser(id);
											console.log("i", id);
										}}
									>
										{console.log("test")}
										{userSearchList}
									</Select>
								</div>
							</Col>

							<Col span={5} sm={6} className="option-navbar">
								<span className="item">
									<Dropdown overlay={menu}>
										<a onClick={(e) => e.preventDefault()}>
											<Space>
												Administrador
												<img
													className="img-profile"
													src="/assets/images/user-profile.png"
													alt=""
												/>
												<DownOutlined />
											</Space>
										</a>
									</Dropdown>
								</span>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</>
	);
};
