import React, { useEffect, useRef, useState } from 'react'
import {useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { outsideClicks } from '../../helpers/useOutsideClicksHelper';
import { clearSubmenu } from '../../store/submenu/submenuSlice';

export const Submenu = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false)
    const { menu } = useSelector( state => state.submenu );
    const menuRef = useRef(null)

    useEffect(()=>{
        outsideClicks(menuRef, setIsOpen);
        if(menu.title && menu.items.length > 0){
            setIsOpen(true);
        }
    })

    
    useEffect(()=>{
        if(!isOpen){            
            dispatch( clearSubmenu() );
            setIsOpen(false);
        }
    }, [ isOpen ])

    

    return (
        <>
            {
                ( isOpen ) &&
                <div className='submenu' ref={menuRef}>
                    <div className='submenu-container'>
                        <div className="submenu-header">
                            <h2>{menu.title}</h2>
                        </div>
                        <ul>
                            { 
                                menu.items.map((item, key) => {
                                    return (
                                        <li key={key}>
                                            <NavLink to={item.url} onClick={ () => { setIsOpen(false) }}>{item.name}</NavLink>
                                        </li>
                                    )
                                }) 
                            }
                        </ul>
    
                    </div>
                </div>
            }
        </>
    )
}
