import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	TimePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import Imgtest from "./imgtest";
import { AppBanner } from "./AppBanner";
import {
	onCreateOrUpdate,
	onUpdateDocWithRefInfo,
} from "../../../../helpers/globalHelper";
import { useNavigate } from "react-router-dom";
import { Upload, message } from "antd";
import { handleNewsImageUpload } from "../../../../helpers/newsHelper";

export const EventEvent = ({ accounts, accountsOp, data }) => {
	const [color, setColor] = useState("#10B48C");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [isScratch, setIsScratch] = useState(false);
	const [loadNewButton, setLoadNewButton] = useState(false);
	const [loadScratchButton, setLoadScratchButton] = useState(false);
	const [imageUpload, setImageUpload] = useState(null);
	const [imageUrls, setImageUrls] = useState("");
	const [selectedAccounts, setSelectedAccounts] = useState([]);
	const [accountsOptions, setAccountsOptions] = useState([]);
	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};
	const format = "HH:mm";
	const { TextArea } = Input;
	const navigate = useNavigate();

	useEffect(() => {
		if (data) {
			setTitle(data.title);
			setDescription(data.subtitle);
			setColor(data.color);
		}
	}, []);

	useEffect(() => {
		setAccountsOptions(accountsOp);
	}, [accountsOp]);

	const onFinishCreateEventForm = async (e) => {
		if (isScratch === true) {
			setLoadScratchButton(true);
			const uploadImg = await handleNewsImageUpload(imageUpload, setImageUrls);
			const newsValues = {
				action: "_self",
				active: false,
				color: color.replace("#", ""),
				created_at: data
					? moment(data.created_at, "YYYY-MM-DD").toDate()
					: moment(new Date(), "YYYY-MM-DD").toDate(),
				date: moment(
					`${e.fecha.format("YYYY-MM-DD")} ${e.hora.format("HH:mm:ss")}`
				).toDate(),
				description: e.subtitulo,
				icon: "",
				img: uploadImg,
				inscription: e.inscription,
				large_description: e.description,
				place: e.location,
				residentials: selectedAccounts,
				title: e.titulo,
				type: "event",
				url: "",
				who_invites: e.provider,
			};
			createOrEditNewsEvent(newsValues, data);
			setSelectedAccounts([]);
			setLoadScratchButton(false);
			navigate(`/noticias`, {
				replace: false,
			});
			console.log("BORRADOR", newsValues);
		} else {
			setLoadNewButton(true);
			const uploadImg = await handleNewsImageUpload(imageUpload, setImageUrls);
			const newsValues = {
				action: "_self",
				active: true,
				color: color.replace("#", ""),
				created_at: data
					? moment(data.created_at, "YYYY-MM-DD").toDate()
					: moment(new Date(), "YYYY-MM-DD").toDate(),
				date: moment(
					`${e.fecha.format("YYYY-MM-DD")} ${e.hora.format("HH:mm:ss")}`
				).toDate(),
				description: e.subtitulo,
				icon: "",
				img: uploadImg,
				inscription: e.inscription,
				large_description: e.description,
				place: e.location,
				residentials: selectedAccounts,
				title: e.titulo,
				type: "event",
				url: "",
				who_invites: e.provider,
			};
			createOrEditNewsEvent(newsValues, data);
			setSelectedAccounts([]);
			setLoadNewButton(false);
			navigate(`/noticias`, {
				replace: false,
			});
			console.log("DE VERDAD", newsValues);
		}
	};

	const createOrEditNewsEvent = async (newsValues, data) => {
		if (data === null) {
			console.log("nuevo");
			const collectionRef = "banner";
			const createNews = await onCreateOrUpdate(collectionRef, newsValues);
		} else {
			const id = data.id;
			const collectionRef = `/banner/${id}`;
			const editNews = await onUpdateDocWithRefInfo(collectionRef, newsValues);
			console.log("editado");
		}
	};

	const onChangeTitle = (e) => {
		setTitle(e.target.value);
	};

	const onChangeDate = (date, dateString) => {
		console.log(date, dateString);
	};

	const onChangeDescription = (e) => {
		setDescription(e.target.value);
	};

	const initialFormValues = {
		titulo: data ? data.title : "",
		subtitulo: data ? data.subtitle : "",
		description: data ? data.description : "",
		color: data ? data.color : "#10B48C",
		fecha: data ? moment(data.date, "YYYY-MM-DD") : "",
		hora: data ? moment(data.date_time, "HH:mm") : "",
		location: data ? data.place : "",
		provider: data ? data.promoter : "",
		inscription: data ? data.inscription : "",
		img: data ? data.img : "",
	};

	const showCategory = (category) => {
		let name = "";
		if (category === "event") {
			name = "Evento";
		}
		if (category === "news") {
			name = "Noticia";
		}
		if (category === "info") {
			name = "Notificación";
		}
		return name;
	};

	function previewImage() {
		var preview = document.getElementById("image-preview");
		var file = document.getElementById("image-input").files[0];
		var reader = new FileReader();

		reader.onloadend = function () {
			preview.src = reader.result;
			preview.style.display = "block";
		};

		if (file) {
			reader.readAsDataURL(file);
		} else {
			preview.src = "";
			preview.style.display = "none";
		}
	}

	const handleAccountSelect = (e) => {
		let selected = [];
		let array = [...e];
		let count = e.length;

		for (let i = 0; i < count; i++) {
			selected.push(accounts[array[i]].id);
		}

		setSelectedAccounts(selected);
	};

	return (
		<>
			<Form
				name="create_event"
				onFinish={onFinishCreateEventForm}
				initialValues={initialFormValues}
			>
				<Row className={{ margin: "10px" }}>
					<Col span={12}>
						<div className="create-news">
							<p>TÍTULO:</p>

							<FormItem name="titulo" {...config}>
								<Input onChange={onChangeTitle} maxLength={21}></Input>
							</FormItem>
						</div>
						<div className="create-news">
							<p>SUBTITULO:</p>
							<FormItem name="subtitulo" {...config}>
								<Input onChange={onChangeDescription} maxLength={70}></Input>
							</FormItem>
						</div>
						<div className="create-news">
							<Row>
								<Col span={12}>
									<p>FECHA:</p>
									<FormItem name="fecha" {...config}>
										<DatePicker
											onChange={onChangeDate}
											style={{ width: "90%" }}
										></DatePicker>
									</FormItem>
								</Col>
								<Col span={12}>
									<p>HORA:</p>
									<FormItem name="hora" {...config}>
										<TimePicker format={format} />
									</FormItem>
								</Col>
							</Row>
						</div>
						<div className="create-news">
							<p>LUGAR:</p>
							<FormItem name="location" {...config}>
								<Input></Input>
							</FormItem>
						</div>
						<div className="create-news">
							<p>DESCRIPCIÓN:</p>
							<FormItem name="description" {...config}>
								<TextArea rows={4}></TextArea>
							</FormItem>
						</div>
						<div className="create-news">
							<p>¿QUIÉN INVITA?:</p>
							<FormItem name="provider" {...config}>
								<Input></Input>
							</FormItem>
						</div>
						<div className="create-news">
							<p>CUENTA:</p>
							<FormItem name="accounts" {...config}>
								{accountsOptions ? (
									<Select
										mode="multiple"
										placeholder="Buscar por nombre"
										style={{
											width: "100%",
										}}
										allowClear
										showSearch
										filterOption={(input, option) => {
											return (
												option.label
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											);
										}}
										onChange={handleAccountSelect}
									>
										{accountsOptions}
									</Select>
								) : null}
							</FormItem>
						</div>
						<div className="create-news">
							<Card
								style={{
									width: "100%",
									borderRadius: "10px",
								}}
							>
								<p>REQUIERE INSCRIPCIÓN</p>

								<FormItem name="inscription" {...config}>
									<Radio.Group>
										<Space>
											<Radio value={true}>SI</Radio>
											<Radio value={false}>NO</Radio>
										</Space>
									</Radio.Group>
								</FormItem>
							</Card>
						</div>
					</Col>
					<Col span={12}>
						<div style={{ marginTop: "0rem", marginLeft: "4rem" }}>
							<div className="create-news">
								<p>PREVIEW EN BANNER:</p>

								<AppBanner
									bannerColor={color}
									title={title}
									description={description}
								/>
							</div>
							<div className="create-news" style={{ marginTop: "2rem" }}>
								<p>COLOR:</p>
								<FormItem name="color" {...config}>
									<Space>
										<img
											src="/assets/images/green_circle.png"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setColor("#10B48C");
											}}
										/>
										<img
											src="/assets/images/darkblue_circle.png"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setColor("#232C4A");
											}}
										/>
										<img
											src="/assets/images/blue_circle.png"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setColor("#1264E0");
											}}
										/>
										<img
											src="/assets/images/gray_circle.png"
											style={{ cursor: "pointer" }}
											onClick={() => {
												setColor("#686868");
											}}
										/>
									</Space>
								</FormItem>

								<Button>Seleccionar ícono</Button>
							</div>
							<div className="create-news" style={{ marginTop: "5rem" }}>
								<p>IMAGEN PRINCIPAL</p>
								{/* {data ? (
									<img
										src={data.imgURL}
										style={{ width: "295px", height: "230px" }}
									/>
								) : (
									<></>
								)} */}
								<img
									id="image-preview"
									src="#"
									alt="Preview Image"
									style={{ width: "295px", height: "230px", display: "none" }}
								></img>

								<input
									type="file"
									id="image-input"
									accept="image/jpeg,image/jpg,image/png"
									style={{ marginTop: "2rem" }}
									multiple={false}
									required
									onChange={(event) => {
										previewImage();
										setImageUpload(event.target.files[0]);
									}}
								/>
							</div>
						</div>
					</Col>
					<Col span={24} align="middle">
						<div style={{ marginTop: "3rem" }}>
							<Button
								htmlType="submit"
								loading={loadScratchButton}
								style={{
									marginRight: "1rem",
									borderColor: "#CEB457",
									background: "#CEB457",
									paddingBottom: "15px",
									width: "300px",
								}}
								shape="round"
								size="large"
								onClick={() => setIsScratch(true)}
							>
								<span className="title" style={{ color: "#fff" }}>
									GUARDAR Y CONTINUAR DESPUÉS
								</span>
							</Button>
							<Button
								htmlType="submit"
								loading={loadNewButton}
								style={{
									marginLeft: "1rem",
									borderColor: "#10b48c",
									background: "#10b48c",
									paddingBottom: "15px",
									width: "250px",
								}}
								shape="round"
								size="large"
								onClick={() => setIsScratch(false)}
							>
								<span
									className="title"
									style={{ color: "#fff", textTransform: "uppercase" }}
								>
									CREAR {data ? showCategory(data.type) : "EVENTO"}
								</span>
							</Button>
						</div>
					</Col>
				</Row>
			</Form>
		</>
	);
};
