import {
	collection,
	query,
	where,
	getDocs,
	doc,
	getDoc,
	orderBy,
} from "firebase/firestore";
import { FirebaseDB } from "../firebase/config";
import moment from "moment";
import "moment-timezone";
import { getColumnSearchProps } from "../utils/tables";

export const getPaymentsSettlementHelper = async (fechaInicio, fechaFinal) => {
	const collectionRef = collection(FirebaseDB, `/payments`);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(23, 59, 59, 0);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "in", [
			"NzDZxRcxqRV2dHtDETj9", //LAS LUCES - RESIDENCIAL
			"bEytjH9pEGS4nsPKf05E", //LA GLORIA - RESIDENCIAL
			"cfQ3edDuBHHB09DAeumQ", //GYM POWER - GIMNASIO
			"nqLvvhjKOJ8P6C08iGRk", //CLG - OFICINA PRIVADA
			"ow5D60c2R0tMWX2m0kx4", //LOS SUEÑOS - RESIDENCIAL
			"vLNhENQZIuDs6h58RAZK", //PALO ALTO - RESIDENCIAL
			"hspYWQ7JliJRQbeWx10N", //CDI - CLUB
			"Yd5MV7jkA0MjQeCmzKrZ", //TUSCANIA - RESIDENCIAL
		]),
		where("status", "==", "Pagado"),
		where("created_at", ">=", new Date(dateStart)),
		where("created_at", "<=", new Date(dateEnd)),
		orderBy("created_at", "desc")
	);
	/* const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("status", "==", "Pagado"),
		where("created_at", ">=", new Date(dateStart)),
		where("created_at", "<=", new Date(dateEnd)),
		orderBy("created_at", "desc")
	); */
	const querySnapshot = await getDocs(queryMessages);
	const list = await paymentBody(querySnapshot);

	return list;
};

export const getPaymentsSettlementSingleHelper = async (
	fechaInicio,
	fechaFinal,
	residential_id
) => {
	const collectionRef = collection(FirebaseDB, `/payments`);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(23, 59, 59, 0);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("payment_settle", "==", false),
		where("status", "==", "Pagado"),
		where("created_at", ">=", new Date(dateStart)),
		where("created_at", "<=", new Date(dateEnd)),
		orderBy("created_at", "desc")
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await paymentBody(querySnapshot);

	return list;
};

export const getAccreditedPaymentsSingleHelper = async (
	fechaInicio,
	fechaFinal,
	residential_id
) => {
	const collectionRef = collection(FirebaseDB, `/payments_deposit`);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(23, 59, 59, 0);

	const queryMessages = query(
		collectionRef,
		where("residentialId", "==", residential_id),
		where("fechaRegistro", ">=", new Date(dateStart)),
		where("fechaRegistro", "<=", new Date(dateEnd)),
		orderBy("fechaRegistro", "desc")
	);

	const querySnapshot = await getDocs(queryMessages);
	const list = await accreditedPaymentBody(querySnapshot);

	return list;
};

export const getPaymentById = async (id) => {
	const collectionResidentialRef = doc(FirebaseDB, "/payments/" + id);

	const docSanp = await getDoc(collectionResidentialRef);
	const data = docSanp.data();

	return data;
};
export const getPaymentsHelperByDateAndProfileId = async (
	residential_id,
	fechaInicio,
	fechaFinal,
	profileId
) => {
	const collectionRef = collection(FirebaseDB, `/payments`);

	const dateStart = fechaInicio.setHours(0, 0, 0, 0);
	const dateEnd = fechaFinal.setHours(0, 0, 0, 0);

	const queryMessages = query(
		collectionRef,
		where("active", "==", true),
		where("residential_id", "==", residential_id),
		where("profile_id", "==", profileId),
		where("status", "==", "Pagado"),
		where("created_at", ">=", new Date(dateStart)),
		where("created_at", "<=", new Date(dateEnd)),
		orderBy("created_at", "desc")
	);
	const querySnapshot = await getDocs(queryMessages);
	const list = await paymentBody(querySnapshot);

	console.log("query", list);

	return list;
};

const paymentBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			active: data.active,
			amount: data.amount,
			automatic_payment: data.automatic_payment,
			card: data.card,
			concept: data.concept,
			created_at: moment(data.created_at.toDate()).format("Y-MM-DD"),
			created_time: moment(data.created_at.toDate()).format("HH:mm"),
			details: data.details,
			payment_details: data.payment_details ? data.payment_details : null,
			expiration_date:
				data.expiration_date &&
				moment(data.expiration_date.toDate()).format("Y-MM-DD"),
			expiration_time:
				data.expiration_date &&
				moment(data.expiration_date.toDate()).format("HH:mm"),
			/* payment_date:
				data.payment_date &&
				moment(data.payment_date.toDate()).format("Y-MM-DD"),
			payment_date_time:
				data.payment_date && moment(data.payment_date.toDate()).format("HH:mm"), */
			mora: data.mora ? data.mora : "0.00",
			num_auth: data.num_auth,
			num_ref: data.num_ref,
			payment_date: moment(data.payment_date.toDate()).format("Y-MM-DD"),
			payment_time: moment(data.payment_date.toDate()).format("HH:mm"),
			payment_type: data.payment_type,
			porcentaje_mora: data.porcentaje_mora ? data.porcentaje_mora : "0.00",
			profile_id: data.profile_id ? data.profile_id : null,
			residential_id: data.residential_id ? data.residential_id : null,

			status: data.status,
			type: data.type,
			user_name: data.user_name,
		};

		list.push(itemData);
	});

	return list;
};

const accreditedPaymentBody = async (querySnapshot) => {
	const list = [];
	querySnapshot.forEach(async (documment) => {
		let data = documment.data();
		let itemData = {
			id: documment.id,
			residential_id: data.residentialId,
			registered_at: moment(data.fechaRegistro.toDate()).format("Y-MM-DD"),
			registered_time: moment(data.fechaRegistro.toDate()).format("HH:mm"),
			taxFee: data.impuesto,
			accFee: data.comision,
			feeTopia: data.feeTopia,
			total: data.total,
			settle_amount: data.liquidado,
			account_number: data.account_number,
			bank: data.bank,
			ref_number: data.refDeposito,
		};

		list.push(itemData);
	});

	return list;
};
