import React from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import "./styles.css";
import { useEffect, useState } from "react";

export const ReportLiquidacionTable = () => {
	const [accounts, setAccounts] = useState([]);
	const [detailModalActive, setDetailModalActive] = useState(false);
	let trans = [];
	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};

	const accountColumns = [
		{
			title: "FECHA",
			dataIndex: "date_action",
			key: "date_action",
			align: "center",
			/* sorter: (a, b) => a.constructionFee - b.constructionFee,
                render: (text, record) => (
                    <>{moment(record.date_action).format("YY/MM/D")}</>
                ), */
		},
		{
			title: "CUENTA",
			dataIndex: "account",
			key: "account",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "CONCEPTO",
			dataIndex: "concept",
			key: "concept",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "MONTO",
			dataIndex: "amount",
			key: "amount",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "DETALLE",
			dataIndex: "details",
			key: "details",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
			/* render: (id, record, index) => (
                    <div className="">$ {parseFloat(record.total).toFixed(2)}</div>
                ), */
		},
		{
			title: "HISTORIAL",
			dataIndex: "history",
			key: "history",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
	];

	const handleDetailModal = (statusModal) => {
		setTimeout(() => {
			setDetailModalActive(statusModal);
		}, 50);
	};
	return (
		<>
			<br />
			<Table
				className="primary-table"
				columns={accountColumns}
				dataSource={accounts}
				pagination={{ defaultPageSize: 20, simple: true }}
				loading="true"
				//rowKey="id"
				locale={locale}
			/>
			<Button
				onClick={() => {
					handleDetailModal(true);
				}}
				shape="round"
				style={{ width: "120px", borderColor: "#10b48c" }}
			>
				modal
			</Button>
			<Modal
				className="side-modal"
				title="Detalle de pago recibido"
				style={{ top: 0, right: 0 }}
				visible={detailModalActive}
				onCancel={() => handleDetailModal(false)}
				footer={null}
				bodyStyle={{
					overflowY: "auto",
					maxHeight: "calc(100vh - 100px)",
				}}
				width={600}
			>
				<>
					<Row className="section-modal">
						<Col span={18}>
							<Row>
								<Col span={4}>
									<img src="/assets/images/recibo.png" />
								</Col>
								<Col span={20}>
									<div>
										<span className="description">CONCEPTO</span>
										<br />
										<span className="title_big">Pago de uso de app</span>
										<br />
										<span className="description">
											FECHA DE RECEPCIÓN: 10/08/22 - 9:06AM
										</span>
									</div>
								</Col>
							</Row>
						</Col>
						<Col span={6}>
							<div>
								<span className="description">CORRELATIVO</span>
								<br />
								<span className="title_big">RT 010810</span>
								<br />
							</div>
						</Col>
					</Row>
					<Row className="section-modal">
						<Col span={24}>
							<span className="description">CUENTA</span>
							<br />
							<span className="title_small">Residencial Tuscania</span>
							<img
								className="iconPerfil"
								src="/assets/icons/shape08.svg"
								style={{ cursor: "pointer" }}
								alt="Topia"
							/>
							<br />
							<br />
							<span className="description">NÚMERO DE USUARIOS ACTIVOS:</span>
							<br />
							<span className="title_small">426</span>
						</Col>
					</Row>
					<Row className="section-modal">
						<Col span={12}>
							<span className="description">MONTO COBRADO:</span>
							<br />
							<span className="green-description">$ 1,138.00</span>
							<br />
						</Col>
						<Col span={12}>
							<span className="description">MÉTODO DE PAGO:</span>
							<br />
							<span className="description">Deposito a cuenta</span>
							<br />
						</Col>
						<Col span={12}>
							<br />
							<span className="description">PERIODO FACTURADO:</span>
							<br />
							<span className="description">10/07/2022 - 10/08/2022</span>
							<br />
						</Col>
						<Col span={12} align="middle">
							<br />
							<span className="description">HISTORIAL DE PAGOS:</span>
							<br />
						</Col>
					</Row>
					<Row className="section-modal">
						<Col span={24}>
							<span className="description">ADMINISTRADOR RESIDENCIAL</span>
							<br />
							<span className="description-big">Manuel Enrique Espinoza</span>
							<br />
							<img src="/assets/icons/phone.svg" />
							<span className="green-description">7856-2310</span>
							<br />
						</Col>
					</Row>
				</>
			</Modal>
		</>
	);
};
