import React from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
const { RangePicker } = DatePicker;

export const CommissionsTable = () => {
	const [commissions, setCommissions] = useState([]);
	const [detailModalActive, setDetailModalActive] = useState(false);
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");

	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};

	const commissionColumns = [
		{
			title: "FECHA",
			dataIndex: "date_action",
			key: "date_action",
			align: "center",
			/* sorter: (a, b) => a.constructionFee - b.constructionFee,
                render: (text, record) => (
                    <>{moment(record.date_action).format("YY/MM/D")}</>
                ), */
		},
		{
			title: "CUENTA",
			dataIndex: "account",
			key: "account",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "TRANSACCIONES",
			dataIndex: "payments",
			key: "payments",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "COMISIÓN TOPIA",
			dataIndex: "commission",
			key: "commission",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "DETALLE",
			dataIndex: "details",
			key: "details",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
			/* render: (id, record, index) => (
                    <div className="">$ {parseFloat(record.total).toFixed(2)}</div>
                ), */
		},
		{
			title: "HISTORIAL",
			dataIndex: "history",
			key: "history",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
	];

	const reportColumns = [
		{
			title: "TIPO DE OPERACIÓN",
			dataIndex: "type",
			key: "type",
			width: "50%",
		},
		{
			title: "TRANSACCIÓN",
			dataIndex: "payment",
			key: "payment",
			width: "25%",
		},
		{
			title: "COMISIÓN",
			dataIndex: "comision",
			key: "comision",
			/* summary: (data) => {
                let total = 0;
                data.forEach((item) => {
                  total += item.salary;
                });
                return `Total: ${total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`; // format the total as currency
            }, */
		},
	];

	const handleDetailModal = (statusModal) => {
		setTimeout(() => {
			setDetailModalActive(statusModal);
		}, 50);
	};
	const onChangeFecha = (date, dateString) => {
		console.log(date, dateString);
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
	};

	return (
		<>
			<Table
				className="primary-table"
				columns={commissionColumns}
				dataSource={commissions}
				pagination={{ defaultPageSize: 20, simple: true }}
				loading="true"
				//rowKey="id"
				locale={locale}
			/>
			<Button
				onClick={() => {
					handleDetailModal(true);
				}}
				shape="round"
				style={{ width: "120px", borderColor: "#10b48c" }}
			>
				modal
			</Button>
			<Modal
				className="side-modal"
				title="Detalle de pago recibido"
				style={{ top: 0, right: 0 }}
				visible={detailModalActive}
				onCancel={() => handleDetailModal(false)}
				footer={null}
				bodyStyle={{
					overflowY: "auto",
					maxHeight: "calc(100vh - 100px)",
				}}
				width={600}
			>
				<>
					<Row>
						<Col span={24}>
							<Row>
								<Col span={3}>
									<img src="/assets/images/calendario_verde.png" />
								</Col>
								<Col span={21}>
									<div>
										<span className="description">REPORTE DE COMISIONES</span>
										<br />
										<span className="title_big">Residencial Tuscania</span>
										<br />
									</div>
								</Col>
							</Row>
						</Col>
						<Col span={24} align="right" style={{ marginTop: "10px" }}>
							<RangePicker
								defaultValue={[
									moment(new Date(), dateFormat),
									moment(new Date(), dateFormat),
								]}
								format={customFormat}
								style={{
									width: "220px",
								}}
								onCalendarChange={onChangeFecha}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={24} style={{ marginTop: "3rem" }}>
							<Table
								className="primary-table"
								columns={reportColumns}
								pagination={{ defaultPageSize: 20, simple: true }}
								loading="true"
								//rowKey="id"
								locale={locale}
							/>
						</Col>
					</Row>
				</>
			</Modal>
		</>
	);
};
