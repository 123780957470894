import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ImportantPage } from "../pages/important/ImportantPage";
import { AccessPage } from "../pages/access/AccessPage";
import { Sidebar } from "../components/Sidebar";
import { NavBar } from "../components/NavBar";
import { PaymentsPage } from "../pages/payments/PaymentsPage";
import { UsersPage } from "../pages/users/UsersPage";
import { Layout } from "antd";
import { Submenu } from "../components/Submenu";
import { AlertsReceivedPage } from "../pages/alertsReceived/AlertsReceivedPage";
import { ReservationsRequestPage } from "../pages/reservationsRequest/ReservationsRequestPage";
import { AnnouncementPage } from "../pages/announcement/AnnouncementPage";
import { SurveyPage } from "../pages/Surveys/SurveyPage";
import { AdminCobrosPage } from "../pages/adminCobros/AdminCobrosPage";
import { useDispatch, useSelector } from "react-redux";
import { ZonasReceivedPage } from "../pages/zonas/ZonasReceivedPage";
import { ConfiguracionInicialPage } from "../pages/configuracionInicial/ConfiguracionInicialPage";
import { NomenclaturaPage } from "../pages/configuracionInicial/nomenclatura/NomenclaturaPage";
import { AprobacionUsersPage } from "../pages/configuracionInicial/aprobacion/AprobacionUsersPage";
import UserProfilePage from "../pages/userProfilePage/UserProfilePage";
import PackagesPage from "../pages/packages/PackagesPage";
import LostObjectsPage from "../pages/lostObjects/LostObjectsPage";
import NewsPage from "../pages/news/NewsPage";
import ReportLiquidacionPage from "../pages/reporteLiquidacion/ReportLiquidacionPage";
import PerfilLiquidacionPage from "../pages/perfilLiquidacion/PerfilLiquidacionPage";
import DeactivatedUsersPage from "../pages/deactivatedUsers/DeactivatedUsersPage";
import PaymentsHistoryPage from "../pages/paymentsHistory/PaymentsHistoryPage";
import CommissionsPage from "../pages/commissions/CommissionsPage";
import CreateNewsPage from "../pages/news/components/CreateNewsPage";
import SettlementPage from "../pages/settlements/SettlementPage";
import { EditNewsPagePrincipal } from "../pages/news/editComponents/EditNewsPagePrincipal";
const { Header, Sider, Content } = Layout;

export const DashboardRouter = () => {
	const [collapsed, setCollapsed] = useState(true);
	const { committee } = useSelector((state) => state.auth);

	return (
		<Layout className="wrapper-container alto">
			<Sider
				className="sidebar"
				trigger={null}
				collapsible
				collapsed={collapsed}
				style={{ height: "100vh" }}
			>
				<Sidebar
					rol={
						typeof committee.residentialData === "undefined"
							? ""
							: committee.residentialData.entity_type.path
					}
				></Sidebar>
			</Sider>
			<Submenu></Submenu>
			<Layout className="site-layout">
				<Header style={{ padding: 0 }}>
					<NavBar />
				</Header>

				<Content style={{ minHeight: 280 }}>
					<Routes>
						{/* <Route
							path="configuracion-inicial"
							element={<ConfiguracionInicialPage />}
						/>
						<Route
							path="configuracion-inicial/nomenclatura"
							element={<NomenclaturaPage />}
						/>
						<Route
							path="configuracion-inicial/aprobacion-usuarios"
							element={<AprobacionUsersPage />}
						/> */}
						{/* <Route path="destacados" element={<ImportantPage />} /> */}
						{/* <Route path="accesos" element={<AccessPage />} />
						<Route path="pagos" element={<PaymentsPage />} /> */}
						<Route path="comisiones" element={<CommissionsPage />} />
						<Route path="caja" element={<ReportLiquidacionPage />} />
						<Route path="liquidaciones" element={<SettlementPage />} />
						<Route path="historial" element={<PaymentsHistoryPage />} />
						<Route path="noticias" element={<NewsPage />} />
						<Route path="noticias/:id" element={<EditNewsPagePrincipal />} />
						<Route path="crear-noticia" element={<CreateNewsPage />} />
						{/* <Route path="usuarios" element={<UsersPage />} />
						<Route
							path="usuarios-desactivados"
							element={<DeactivatedUsersPage />}
						/>
						<Route path="reporte-actividad/:id" element={<UserProfilePage />} />
						<Route path="alertas-recibidas" element={<AlertsReceivedPage />} />
						<Route
							path="zonas"
							element={
								<ZonasReceivedPage
									residentialData={committee.residentialData}
								/>
							}
						/>
						<Route
							path="solicitudes-de-reserva"
							element={<ReservationsRequestPage />}
						/>
						<Route path="convocatorias" element={<AnnouncementPage />} />
						<Route path="encuestas" element={<SurveyPage />} />
						<Route path="objetos-perdidos" element={<LostObjectsPage />} />
						
						<Route path="paquetes-en-recepcion" element={<PackagesPage />} />

						<Route path="admin-cobros" element={<AdminCobrosPage />} /> */}

						<Route path="/*" element={<Navigate to="/caja" />} />
					</Routes>
				</Content>
			</Layout>
		</Layout>
	);
};
