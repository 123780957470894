import { loginWithEmailPassword, registerUserWithEmailPassword, singInWithGoogle, logoutFirebase } from '../../firebase/authProvider';
import { getQueryDocByCollection, getReferenceData } from '../../helpers/globalHelper';
import { checkingCredentials, logout, login } from './';

export const checkingAuthentication = () => {
    return async( dispatch ) => {

        dispatch( checkingCredentials() );
        
    }
}


export const startGoogleSignIn = () => {
    return async( dispatch ) => {

        dispatch( checkingCredentials() );

        const result = await singInWithGoogle();
        if ( !result.ok ) return dispatch( logout( result.errorMessage ) );

        dispatch( login( result ))

    }
}


export const startCreatingUserWithEmailPassword = ({ email, password, displayName }) => {
    return async( dispatch ) => {

        dispatch( checkingCredentials() );

        const result = await registerUserWithEmailPassword({ email, password, displayName });
        if ( !result.ok ) return dispatch( logout( result.errorMessage ) );

        dispatch( login( result ))

    }

}


export const startLoginWithEmailPassword = ({ email, password }) => {
    return async( dispatch ) => {

        dispatch( checkingCredentials() );
        const result = await loginWithEmailPassword({ email, password });
        if ( !result.ok ) return dispatch( logout( result ) );
        
        const committee = await getQueryDocByCollection('committee', "user_id", "==", result.uid);
        
        // Verify committee
        if ( committee.length < 1 ) return dispatch( logout() );

        // Verify residential in committee
        if(!committee[0].residential_id) return dispatch( logout() );

         //verificar el rol de la residencial
         const residenciaData = await getQueryDocByCollection('residentials', "idResidential", "==", committee[0].residential_id);
         var rolResidential = residenciaData[0].entity_type.path

        result.committee = {
            lastname: committee[0].lastname,
            name: committee[0].name,
            superadmin: committee[0].superadmin,
            residential_id: committee[0].residential_id,
            user_id: committee[0].user_id,
            residentialData: residenciaData[0],
            rolResidential: rolResidential
        }
        dispatch( login( result ));

    }
}


export const startLogout = () => {
    return async( dispatch ) => {
        
        await logoutFirebase();

        dispatch( logout() );

    }
}