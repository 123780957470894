import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const announcementSlice = createSlice({
    name: 'announcement',
    initialState: {
        announcement: {
            data: [],
            active: null
        },
        errorMessage: null
    },
    reducers: {
        setAnnouncement: ( state, { payload } ) => {
            state.announcement.data = payload;
        },
        createAnnouncement: ( state, { payload } ) => {
            state.announcement.data.push(payload);
            toast.success("Convocatoria guardada");
        },
        setActiveAnnouncement: (state, { payload })=>{
            state.announcement.active = payload
        }
    }
});


export const { 
    createAnnouncement,
    setAnnouncement,
    setActiveAnnouncement,
} = announcementSlice.actions;