import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import { Col, Row, Button, DatePicker, Tabs } from "antd";
import { UserProfileAccess } from "./UserProfileAccess";
import { UserProfileAlerts } from "./UserProfileAlerts";
import { UserProfilePayments } from "./UserProfilePayments";
import { UserProfilePermissions } from "./UserProfilePermissions";
import { UserProfileRequest, userProfileRequest } from "./UserProfileRequest";

const { RangePicker } = DatePicker;

export const UserProfileContainerTabs = (props) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const { TabPane } = Tabs;

	const { buscarFechas, loadingBt, id, data, permissionData } = props;

	const onChangeFecha = (date, dateString) => {
		console.log(date, dateString);
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		buscar();
	};

	const buscar = async () => {
		if (startDate == "") {
			var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));
			buscarFechas(fechaIn, fechaFi);
		} else {
			var fechaI = new Date(moment(startDate, "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(endDate, "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));
			buscarFechas(fechaIn, fechaFi);
		}
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const buscarBoton = async (
		numOfDays,
		fechaI = new Date(),
		fechaF = new Date()
	) => {
		var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));

		fechaIn.setDate(fechaIn.getDate() - numOfDays);
		console.log("boton apretado", fechaIn, fechaFi);
		buscarFechas(fechaIn, fechaFi);
	};

	return (
		<>
			<Row type="flex" align="middle">
				<Col span={24}></Col>
				<Col span={6}>
					<div style={{ marginLeft: "20px" }}>
						<Button
							style={{
								color: "#10b48c",
								background: "white",
								border: "none",
								fontWeight: "bold",
							}}
							onClick={() => {
								buscarBoton(0);
							}}
							size="small"
						>
							<span className="title">ESTE DÍA</span>
						</Button>
						<Button
							style={{
								color: "#10b48c",
								background: "white",
								marginLeft: "20px",
								border: "none",
								fontWeight: "bold",
							}}
							onClick={() => {
								buscarBoton(7);
							}}
							size="small"
						>
							<span className="title">ESTA SEMANA</span>
						</Button>
						<Button
							style={{
								color: "#10b48c",
								background: "white",
								marginLeft: "20px",
								border: "none",
								fontWeight: "bold",
							}}
							onClick={() => {
								buscarBoton(31);
							}}
							size="small"
						>
							<span className="title">ESTE MES</span>
						</Button>
					</div>
				</Col>
				<Col span={6}></Col>
				<Col span={6}></Col>
				<Col span={6} style={{ justifyContent: "right" }}>
					<RangePicker
						defaultValue={[
							moment(new Date(), dateFormat),
							moment(new Date(), dateFormat),
						]}
						format={customFormat}
						style={{
							width: "270px",
						}}
						onCalendarChange={onChangeFecha}
					/>
				</Col>
			</Row>
			<Row>
				<Col span="20" lg={24} xs={24}>
					<div className="wrapper" style={{ marginTop: "30px" }}>
						<Tabs className="general-tabs" defaultActiveKey="1">
							<TabPane
								tab={
									<>
										<img src="/assets/images/shape33.png" alt="Topia" />
										Accesos propios
									</>
								}
								key="1"
							>
								<UserProfileAccess id={id} data={data} />
							</TabPane>
							<TabPane
								tab={
									<>
										<img src="/assets/images/shape32.png" alt="Topia" />
										Permisos creados
									</>
								}
								key="2"
							>
								<UserProfilePermissions permissionDataN={permissionData} />
							</TabPane>
							<TabPane
								tab={
									<>
										<img src="/assets/images/shape02.png" alt="Topia" />
										Solicitudes
									</>
								}
								key="3"
							>
								<UserProfileRequest />
							</TabPane>
							<TabPane
								tab={
									<>
										<img src="/assets/images/shape03.png" alt="Topia" />
										Pagos realizados
									</>
								}
								key="4"
							>
								<UserProfilePayments />
							</TabPane>
							<TabPane
								tab={
									<>
										<img src="/assets/images/shape01.png" alt="Topia" />
										Alertas
									</>
								}
								key="5"
							>
								<UserProfileAlerts />
							</TabPane>
						</Tabs>
					</div>
				</Col>
			</Row>
		</>
	);
};
