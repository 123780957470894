import React from "react";
import * as XLSX from "xlsx";
import { useEffect, useState, useRef } from "react";
import {
	Col,
	Row,
	Layout,
	Button,
	DatePicker,
	Tabs,
	Form,
	Select,
	Input,
} from "antd";
import moment from "moment";
import { SettlementContainer } from "./components/SettlementContainer";
import { Content, Footer } from "antd/lib/layout/layout";
import { PendingSettlementTab } from "./components/PendingSettlementTab";
import { AccreditedSettlementTab } from "./components/AccreditedSettlementTab";
import "./styles.css";
import { getPaymentsSettlementHelper } from "../../../helpers/settlementsHelper";
import {
	getAllResidentialsWithAccreditedPayments,
	getAllResidentialsWithPayments,
} from "../../../helpers/globalHelper";
const { RangePicker } = DatePicker;

const SettlementPage = () => {
	const [loadingBt, setLoadBt] = useState(false);
	const [showFooter, setShowFooter] = useState(false);
	const [residentialsPending, setResidentialsPending] = useState([]);
	const [residentialsAccredited, setResidentialsAccredited] = useState([]);
	const [selectedResidentials, setSelectedResidentials] = useState([]);
	const [pendingTransactions, setPendingTransactions] = useState([]);
	const formRef = useRef();
	const { TabPane } = Tabs;
	//INICIO VARIABLES PARA FECHA AHORA
	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		const pending = getAllResidentialsWithPayments(
			fechaInicio,
			fechaFinal
		).then((result) => setResidentialsPending(result));

		const accredited = getAllResidentialsWithAccreditedPayments(
			fechaInicio,
			fechaFinal
		).then((result) => setResidentialsAccredited(result));
		setLoadBt(true);
	}, []);

	//BUSCA FECHA INICIO
	const searchPendingByDate = async (fechaInicio, fechaFin) => {
		setLoadBt(true);

		const pending = getAllResidentialsWithPayments(fechaInicio, fechaFin).then(
			(result) => setResidentialsPending(result)
		);
	};
	const searchAccreditedByDate = async (fechaInicio, fechaFin) => {
		setLoadBt(true);

		const accredited = getAllResidentialsWithAccreditedPayments(
			fechaInicio,
			fechaFin
		).then((result) => setResidentialsAccredited(result));
	};
	const countAllLiquidations = (transData) => {
		let total = 0;
		let totalDiscount = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.amount).toFixed(2);
			if (item.payment_details) {
				totalDiscount += +(
					item.payment_details.accFee +
					item.payment_details.taxFee +
					item.payment_details.topiaFee
				);
			}
		});
		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};

	const exportAgricolaToCsv = (residentialList, comment) => {
		let data = [];
		residentialList.map((item, index) => {
			data.push({
				accountNumber: item.financial_details.account,
				name: item.name,
				space: "",
				amount: countAllLiquidations(item.payments).toFixed(2),
				//houseType: item.houseType,
				comment: comment,
				email: item.email,
			});
		});
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A1",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "Liquidacion Agricola.csv", { bookType: "csv" });
	};
	const exportOtherToCsv = (residentialList, comment) => {
		let data = [];
		residentialList.map((item, index) => {
			data.push({
				accountNumber: item.financial_details.account,
				bankCode: item.financial_details.bankCode,
				//bankType: item.financial_details.type,
				//documentType: item.documentType,
				//documentNumber: item.documentNumber,
				description: item.name,
				charge: "",
				amount: countAllLiquidations(item.payments).toFixed(2),
				comment: comment,
			});
		});
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet([]);
		XLSX.utils.sheet_add_json(ws, data, {
			origin: "A1",
			skipHeader: true,
		});
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "Liquidacion.csv", { bookType: "csv" });
	};

	const onDownloadForm = async (e) => {
		if (selectedResidentials.length > 0) {
			console.log("ok");
			if (selectedResidentials[0].financial_details.bankCode === "13") {
				exportAgricolaToCsv(selectedResidentials, e.comment);
				console.log("1");
			} else {
				exportOtherToCsv(selectedResidentials, e.comment);
				console.log("2");
			}
		}
		formRef.current.resetFields();
	};
	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={24}>
								<h2 className="title-page">Liquidaciones</h2>
							</Col>
						</Row>

						<br />
						<Row>
							<Col span={24}>
								<Tabs className="primary-tabs" defaultActiveKey="1">
									<TabPane tab="LIQUIDACIONES PENDIENTES" key="1">
										<PendingSettlementTab
											searchPendingByDate={searchPendingByDate}
											loadingBt={loadingBt}
											data={residentialsPending}
											showFooter={showFooter}
											setShowFooter={setShowFooter}
											selectedResidentialsFooter={setSelectedResidentials}
											searchAccreditedByDate={searchAccreditedByDate}
										/>
									</TabPane>
									<TabPane tab="LIQUIDACIONES ACREDITADAS" key="2">
										<AccreditedSettlementTab
											searchAccreditedByDate={searchAccreditedByDate}
											loadingBt={loadingBt}
											data={residentialsAccredited}
										/>
									</TabPane>
								</Tabs>
							</Col>
						</Row>
					</div>
				</Content>
				{showFooter ? (
					<Footer
						className={showFooter ? "scale-up-ver-bottom" : "scale-up-ver-top"}
						style={{
							textAlign: "center",
							backgroundColor: "#232C4A",
							height: "80px",
						}}
					>
						<Form
							ref={formRef}
							name="bank_codes_form"
							onFinish={onDownloadForm}
							className=""
						>
							<Row>
								<Col span={12} align="right">
									<Form.Item
										name="comment"
										style={{ display: "space-between" }}
										{...config}
										className=""
									>
										<Input
											maxLength={50}
											style={{
												width: "342px",
												borderRadius: "5px",
												marginRight: "10px",
											}}
											placeholder="Concepto de acreditación"
										/>
									</Form.Item>
								</Col>
								<Col span={12} align="left">
									<Button
										style={{
											borderColor: "#10b48c",
											background: "#10b48c",
											borderRadius: "5px",
											marginLeft: "10px",
											width: "263px",
										}}
										htmlType="submit"
										shape="square"
									>
										<span
											className="title"
											style={{
												color: "#FFFFFF",
												fontSize: "13px",
												fontWeight: "500",
											}}
										>
											DESCARGAR CÓDIGO BANCARIO
										</span>
									</Button>
								</Col>
							</Row>
						</Form>
					</Footer>
				) : (
					<></>
				)}
			</Layout>
		</>
	);
};

export default SettlementPage;
