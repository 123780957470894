import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import FormItem from "antd/lib/form/FormItem";
import { useNavigate } from "react-router-dom";
import { EventEvent } from "./EventEvent";
import { NewsEvent } from "./NewsEvent";
import { NotificationEvent } from "./NotificationEvent";
import { InformationEvent } from "./InformationEvent";
import { getAllResidentials } from "../../../../helpers/globalHelper";

const CreateNewsPage = () => {
	const [type, setType] = useState(0);
	const [accounts, setAccounts] = useState([]);
	const [accountsOptions, setAccountsOptions] = useState([]);
	const { Option } = Select;

	useEffect(() => {
		getAccounts();
	}, []);

	useEffect(() => {
		let accountOptions = [];
		accounts.map((item, index) => {
			let id = item.id;
			accountOptions.push(
				<Option key={index} value={index} label={item.name}>
					{item.name}
				</Option>
			);
			return item;
		});
		setAccountsOptions(accountOptions);
	}, [accounts]);

	const getAccounts = async () => {
		const accounts = await getAllResidentials().then((result) =>
			setAccounts(result)
		);
	};

	const renderFormByType = (option) => {
		switch (option) {
			case 1:
				return (
					<NewsEvent
						accounts={accounts}
						accountsOp={accountsOptions}
						data={null}
					/>
				);
			case 2:
				return (
					<EventEvent
						accounts={accounts}
						accountsOp={accountsOptions}
						data={null}
					/>
				);
			case 3:
				return (
					<NotificationEvent
						accounts={accounts}
						accountsOp={accountsOptions}
						data={null}
					/>
				);
			/* case 4:
				return <InformationEvent data={null} />; */
			default:
				return <></>;
		}
	};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={24}>
								<h2 className="title-page">Nueva noticia</h2>
							</Col>
							<Col span={24}>
								<Card
									style={{
										width: "100%",
										borderRadius: "10px",
										boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
										marginTop: "30px",
										marginBottom: "30px",
									}}
									bordered
								>
									<Col span={24} style={{ marginBottom: "2rem" }}>
										<div className="create-news">
											<p>TIPO:</p>

											<Radio.Group
												onChange={(e) => {
													setType(e.target.value);
												}}
												value={type}
											>
												<Space>
													<Radio value={1}>NOTICIA</Radio>
													<Radio value={2}>EVENTO</Radio>
													<Radio value={3}>NOTIFICACIÓN</Radio>
													{/* <Radio value={4}>INFORMACIÓN</Radio> */}
												</Space>
											</Radio.Group>
										</div>
									</Col>
									{renderFormByType(type)}
								</Card>
							</Col>
						</Row>
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default CreateNewsPage;
