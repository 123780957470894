import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import FormItem from "antd/lib/form/FormItem";
import { MiddleNewsPage } from "./components/MiddleNewsPage";
import { useNavigate } from "react-router-dom";
import { getNewsHelper } from "../../../helpers/newsHelper";

const NewsPage = () => {
	const [allNews, setAllNews] = useState([]);
	const [newPublicationModalActive, setNewPublicationModalActive] =
		useState(false);
	const [filteredNews, setfilteredNews] = useState([]);

	const { TextArea } = Input;

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};
	const navigate = useNavigate();

	useEffect(() => {
		const getNews = async () => {
			let today = new Date();
			var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
			var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

			const news = await getNewsHelper(fechaInicio, fechaFinal).then((result) =>
				setAllNews(result)
			);
		};

		getNews().catch(console.error);
	}, []);

	const buscarFechas = async (fechaInicio, fechaFin) => {
		const news = await getNewsHelper(
			"Yd5MV7jkA0MjQeCmzKrZ",
			fechaInicio,
			fechaFin
		).then((result) => setAllNews(result));
	};
	const IconButtonEdit = () => (
		<img
			style={{ width: "16px", height: "16px", marginLeft: "15px" }}
			src="/assets/images/icon_editar.png"
		/>
	);

	const onChangeTypeSelector = (value, data) => {
		if (value === "all") {
			setfilteredNews(data);
		} else if (value === "clubs") {
			const newData = data.reduce(function (filtered, option) {
				if (option.residentials.includes("hspYWQ7JliJRQbeWx10N")) {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setfilteredNews(newData);
		} else if (value === "priv_offices") {
			const newData = data.reduce(function (filtered, option) {
				if (
					option.residentials.includes("nqLvvhjKOJ8P6C08iGRk") ||
					option.residentials.includes("OwEoolPfoMXQCb6G0LLN")
				) {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setfilteredNews(newData);
		} else if (value === "residentials") {
			const newData = data.reduce(function (filtered, option) {
				if (
					option.residentials.includes("NzDZxRcxqRV2dHtDETj9") ||
					option.residentials.includes("bEytjH9pEGS4nsPKf05E") ||
					option.residentials.includes("ow5D60c2R0tMWX2m0kx4") ||
					option.residentials.includes("vLNhENQZIuDs6h58RAZK") ||
					option.residentials.includes("Yd5MV7jkA0MjQeCmzKrZ")
				) {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setfilteredNews(newData);
		} else if (value === "gym") {
			const newData = data.reduce(function (filtered, option) {
				if (option.residentials.includes("cfQ3edDuBHHB09DAeumQ")) {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setfilteredNews(newData);
		}
	};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={20}>
								<h2 className="title-page">Noticias y publicidad</h2>
							</Col>
							<Col span={4}>
								<Button
									style={{
										borderColor: "#10b48c",
										background: "#10b48c",
										width: "80%",
									}}
									onClick={() => {
										navigate("/crear-noticia");
									}}
									shape="round"
								>
									<img
										src="/assets/images/plus_icon_white.png"
										alt=""
										style={{
											width: "26px",
											height: "26px",
											marginRight: "20px",
										}}
									/>
									<span
										className="title"
										style={{
											color: "#FFFFFF",
											fontSize: "13px",
											fontWeight: "500",
										}}
									>
										NUEVA NOTICIA
									</span>
								</Button>
							</Col>
							<Col span={24} style={{ marginLeft: "1.5rem" }}>
								<Select
									defaultValue={{
										value: "all",
										label: "Todos",
									}}
									placeholder="Tipo de cuenta"
									className="select_style"
									style={{ width: "261px", marginLeft: "10px" }}
									options={[
										{
											value: "all",
											label: "Todos",
										},
										{
											value: "clubs",
											label: "Clubes",
										},
										{
											value: "priv_offices",
											label: "Oficinas privadas",
										},
										{
											value: "residentials",
											label: "Residenciales",
										},
										{
											value: "gym",
											label: "Gimnasios",
										},
									]}
									onChange={(e) => {
										onChangeTypeSelector(e, allNews);
									}}
								></Select>
							</Col>
						</Row>

						<MiddleNewsPage buscarFechas={buscarFechas} data={filteredNews} />
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default NewsPage;
