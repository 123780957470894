import React from "react";
import { useEffect, useState } from "react";
import { Col, Row, Layout, Button } from "antd";
import moment from "moment";
import { ReportLiquidacionContainer } from "./components/ReportLiquidacionContainer";
import { Content, Footer } from "antd/lib/layout/layout";
import { ReportLiquidacionFooter } from "./components/ReportLiquidacionFooter";

const ReportLiquidacionPage = () => {
	const [loadingBt, setLoadBt] = useState(false);
	//INICIO VARIABLES PARA FECHA AHORA
	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		setLoadBt(true);
	}, []);

	//BUSCA FECHA INICIO
	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);
	};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={12}>
								<h2 className="title-page">Caja</h2>
							</Col>
							<Col span={12} align="right">
								<Button
									style={{
										borderColor: "#10B48C",
										background: "#10B48C",
										width: "35%",
									}}
									onClick={() => {}}
									shape="round"
								>
									<span
										className="title"
										style={{
											color: "#FFFFFF",
											fontSize: "13px",
											fontWeight: "500",
										}}
									>
										REGISTRO DE NUEVO PAGO
									</span>
								</Button>
							</Col>
						</Row>

						<br />
						<Row>
							<Col span={24}>
								<ReportLiquidacionContainer
									buscarFechas={buscarFechas}
									loadingBt={loadingBt}
								/>
							</Col>
						</Row>
					</div>
				</Content>
				{/* <Footer
					style={{
						textAlign: "center",
						backgroundColor: "#232C4A",
						height: "75px",
					}}
				>
					<ReportLiquidacionFooter />
				</Footer> */}
			</Layout>
		</>
	);
};

export default ReportLiquidacionPage;
