
/**
 * 
 * @param {*} menuRef 
 * @param {*} setIsOpen 
 * @returns 
 */
export const outsideClicks = (
    menuRef,
    setIsOpen
  ) => {
    if (!menuRef.current) return;
      
    [`click`, `touchstart`].forEach((type) => {
        document.addEventListener(type, (evt) => {
            const cur = menuRef.current
            const node = evt.target
            if(cur){
                
                if (cur.contains(node)) return
                setIsOpen(false);
            }
        })
    })
  }