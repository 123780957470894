import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import "../styles.css";
import { useNavigate } from "react-router-dom";

export const NewsContainer = ({ data }) => {
	const [news, setNews] = useState([]);
	const [newsDetailModalActive, setNewsDetailModalActive] = useState(false);
	const [descriptionModalActive, setDescriptionModalActive] = useState(false);
	const [editNewsModalActive, setEditNewsModalActive] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		setNews(data);
	}, [data]);
	const { TextArea } = Input;

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const IconButtonReport = () => (
		<img
			style={{ width: "40px", height: "40px" }}
			src="/assets/icons/text.svg"
		/>
	);

	const IconButtonEye = () => (
		<img
			style={{ width: "16px", height: "16px", marginLeft: "15px" }}
			src="/assets/images/shape25.png"
		/>
	);

	const IconButtonEdit = () => (
		<img
			style={{ width: "16px", height: "16px", marginLeft: "15px" }}
			src="/assets/images/icon_editar.png"
		/>
	);

	const handleEditModal = (statusModal, index = null) => {
		if (index != null) {
		}
		setTimeout(() => {
			setEditNewsModalActive(statusModal);
		}, 50);
	};

	const handleDetailsModal = (statusModal, index = null) => {
		if (index != null) {
		}
		setTimeout(() => {
			setNewsDetailModalActive(statusModal);
		}, 50);
	};

	const handleDescriptionModal = (statusModal, index = null) => {
		if (index != null) {
		}
		setTimeout(() => {
			setDescriptionModalActive(statusModal);
		}, 50);
	};

	const showCategory = (category) => {
		let name = "";
		if (category === "event") {
			name = "Evento";
		}
		if (category === "news") {
			name = "Noticia";
		}
		if (category === "info") {
			name = "Notificación";
		}
		return name;
	};
	return (
		<>
			{news ? (
				<>
					<Card
						className="boxcontent-container"
						style={{
							width: "100%",
							borderRadius: "10px",
							boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
							marginTop: "30px",
							marginBottom: "30px",
						}}
						bordered
					>
						<Row>
							<Col span={2}>
								<Button
									type="link"
									style={{
										color: "#10b48c",
										background: "white",
										paddingBottom: "15px",
									}}
									shape="round"
									icon={<IconButtonReport />}
									size="large"
									disabled
								></Button>
							</Col>
							<Col span={10}>
								<div>
									<span className="card-title">
										{news.type === "event" ? news.date : news.created_at}
									</span>
								</div>

								<div>
									<p className="card-description">
										{news.title ? news.title : "N/A"}
									</p>
								</div>
							</Col>
							<Col span={3}>
								<div>
									<span className="card-title">CATEGORÍA</span>
								</div>
								<div>
									<span
										className="card-title"
										style={{
											color: "#10b48c",
											fontSize: "14px",
											textTransform: "uppercase",
										}}
									>
										{news.type ? showCategory(news.type) : "N/A"}
									</span>
								</div>
							</Col>
							<Col span={3}>
								<div>
									<span className="card-title">ESTATUS</span>
								</div>
								<div style={{ marginLeft: "1rem" }}>
									{news.active ? (
										<img
											src="/assets/images/green_circle.png"
											style={{ width: "14px", height: "14px" }}
											alt="estatus"
										/>
									) : (
										<img
											src="/assets/images/gray_circle.png"
											style={{ width: "14px", height: "14px" }}
											alt="estatus"
										/>
									)}
								</div>
							</Col>
							<Col span={3}>
								<div>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginTop: "8px",
										}}
										shape="round"
										icon={<IconButtonEye />}
										size="small"
										onClick={() => {
											handleDescriptionModal(true);
										}}
									>
										<span
											className="title"
											style={{ marginLeft: "10px", marginRight: "15px" }}
										>
											Ver
										</span>
									</Button>
								</div>
							</Col>
							<Col span={3}>
								<div>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginTop: "8px",
										}}
										shape="round"
										size="small"
										icon={<IconButtonEdit />}
										onClick={() => {
											navigate(`/noticias/${news.id}`, {
												replace: false,
											});
										}}
									>
										<span
											className="title"
											style={{ marginLeft: "10px", marginRight: "15px" }}
										>
											Editar
										</span>
									</Button>
								</div>
							</Col>
						</Row>
					</Card>

					<Modal
						className="side-modal"
						title={"detalle de " + showCategory(news.type) + ":"}
						style={{ top: 0, right: 0 }}
						visible={descriptionModalActive}
						width={400}
						onCancel={() => handleDescriptionModal(false)}
						footer={null}
						bodyStyle={{
							overflowY: "auto",
							maxHeight: "calc(100vh - 100px)",
						}}
					>
						<Row>
							<Col span={24} align="middle">
								{news.type === "info" ? (
									<></>
								) : (
									<img
										style={{
											width: "350px",
											height: "262px",
										}}
										src={news.imgURL}
									/>
								)}
							</Col>
							<Col span={24}>
								<div className="despliegue">
									<span
										className="subtitle"
										style={{ textTransform: "uppercase" }}
									>
										{showCategory(news.type)}:
									</span>
									<br />
									<span className="title">{news.title}</span>
									<br />
									<span className="subtitle">
										{news.type === "event" ? (
											<>{"Invita: " + news.promoter}</>
										) : (
											<>{"Fecha: " + news.date}</>
										)}
									</span>
									<br />
									<br />
									{news.type === "event" ? (
										<>
											<Space direction="vertical" style={{ marginLeft: "5px" }}>
												<div>
													<img
														style={{ width: "18px", height: "18px" }}
														src="/assets/icons/Calendar_icon.svg"
													/>
													<span className="subtitle">FECHA:</span>
													<br />
													<span
														className="result"
														style={{ marginLeft: "18px" }}
													>
														{news.date}
													</span>
												</div>
												<div>
													<img
														style={{ width: "18px", height: "18px" }}
														src="/assets/icons/Clock_icon.svg"
													/>
													<span className="subtitle">HORA:</span>
													<br />
													<span
														className="result"
														style={{ marginLeft: "18px" }}
													>
														{news.date_time}
													</span>
												</div>
												<div>
													<img
														style={{ width: "18px", height: "18px" }}
														src="/assets/icons/ubicacion__3_.svg"
													/>
													<span className="subtitle">LUGAR:</span>
													<br />
													<span
														className="result"
														style={{ marginLeft: "18px" }}
													>
														{news.place}
													</span>
												</div>
											</Space>
											<br />
											<br />
										</>
									) : (
										<></>
									)}

									<span className="description">{news.description}</span>
								</div>
							</Col>
							<Col span={24} align="middle"></Col>
						</Row>
					</Modal>
				</>
			) : null}
		</>
	);
};
