import React from "react";
import { useParams } from "react-router-dom";
import {
	getUserDataProfileById,
	getQueryDocByCollection,
} from "../../../helpers/globalHelper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Col,
	Row,
	Button,
	DatePicker,
	Tabs,
	Modal,
	Select,
	Radio,
	Space,
	Form,
	Input,
	Table,
	Switch,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import {
	getAccessHistorialByProfileIdAndDate,
	getScheduleVisitorsByUserHelper,
} from "../../../helpers/accessHelper";
import "./style.css";
import { UserProfileContainerTabs } from "./components/UserProfileContainerTabs";
import moment from "moment";
import { getPaymentsHelperByAccount } from "../../../helpers/paymentsHelper";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const UserProfilePage = () => {
	const { id } = useParams();
	const [loadingBt, setLoadBt] = useState(false);
	const [profileModalActive, setProfileModalActive] = useState(false);
	const [accountStatementModalActive, setAccountStatementeModalActive] =
		useState(false);
	const [accountActive, setAccountActive] = useState([]);
	const [user, setUser] = useState({});
	const [userProfileActive, setUserProfileActive] = useState([]);
	const [permissionData, setPermissionData] = useState([]);
	const [userHistoricalData, setUserHistoricalData] = useState([]);

	const [admonishModalActive, setAdmonishModalActive] = useState(false);
	const [eliminateModalActive, setEliminateModalActive] = useState(false);
	const [userActivationModalActive, setUserActivationModalActive] =
		useState(false);

	const IconButtonReport = () => <img src="/assets/images/shape25.png" />;

	//INICIO VARIABLES PARA FECHA AHORA
	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(24, 0, 0, 0));

		let history = getAccessHistorialByProfileIdAndDate(
			id,
			fechaInicio,
			fechaFinal
		).then((userHistoricalData) => setUserHistoricalData(userHistoricalData));
		console.log("buscar fechas1", userHistoricalData);

		let permData = getScheduleVisitorsByUserHelper(
			"Yd5MV7jkA0MjQeCmzKrZ", //cambiar a la id residencial
			id, //User_id que tiene eventos
			fechaInicio,
			fechaFinal
		).then((permissionData) => setPermissionData(permissionData));
		console.log("PRUEBA1", permissionData);
		setLoadBt(true);
	}, []);

	//BUSCA FECHA INICIO
	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);
		let history = getAccessHistorialByProfileIdAndDate(
			"Yd5MV7jkA0MjQeCmzKrZ", //ID que tiene historial de salidas, cambiar a id
			fechaInicio,
			fechaFin
		).then((userHistoricalData) => setUserHistoricalData(userHistoricalData));
		let permData = getScheduleVisitorsByUserHelper(
			"Yd5MV7jkA0MjQeCmzKrZ", //cambiar a la id residencial
			id, //User_id que tiene eventos
			fechaInicio,
			fechaFin
		).then((permissionData) => setPermissionData(permissionData));
		console.log("PRUEBA2", permissionData);
		console.log("buscar fechas1", userHistoricalData);
	};

	useEffect(() => {
		let profile = getUserDataProfileById(id).then((user) => setUser(user));
	}, []);

	const handleProfileModal = async (statusModal, index = null) => {
		let active_user = user;
		if (active_user.ownerHome) {
			// Agregamosal detalle los dependientes de los socios
			let relativesWithAccess = await getQueryDocByCollection(
				"/family_access",
				"idProfileMain",
				"==",
				active_user.id
			);
			//console.log(relativesWithAccess);
			active_user = {
				...active_user,
				relativesWithAccess: relativesWithAccess,
			};
			if (active_user.origin_fam) {
				const billingDataHistory = await getPaymentsHelperByAccount(
					active_user.origin_fam
				).then((billingD) => {
					active_user.billingData = billingD;
				});
			}
			setUserProfileActive(active_user);
		}

		setTimeout(() => {
			setProfileModalActive(statusModal);
		}, 50);
	};
	const handleAccountStatementModal = async (statusModal, index = null) => {
		let active_user = user;
		if (active_user.origin_fam) {
			const billingDataHistory = await getPaymentsHelperByAccount(
				active_user.origin_fam
			).then((billingD) => {
				active_user.billingData = billingD;
			});
		}
		setAccountActive(active_user);
		setTimeout(() => {
			setAccountStatementeModalActive(statusModal);
		}, 50);
	};

	const accountColumns = [
		{
			title: "Fecha",
			dataIndex: "invoice_date",
			key: "invoice_date",
			width: "9%",
			/* render: (record) => <>{record.origin_fam}</>, */
		},
		{
			title: "Tipo",
			dataIndex: "movtype",
			key: "movtype",
			width: "9%",
		},
		{
			title: "Documento / Abono",
			dataIndex: "invoice_cdi",
			key: "invoice_cdi",
			width: "25%",
		},
		{
			title: "Concepto / Observaciones",
			dataIndex: "concept",
			key: "concept",
			width: "25%",
		},
		{
			title: "Monto",
			dataIndex: "invoice_total",
			key: "invoice_total",
			width: "8%",
		},
		{
			title: "Cargo",
			dataIndex: "invoice_total",
			key: "invoice_total",
			width: "8%",
		},
		{
			title: "Abono",
			dataIndex: "payment",
			key: "payment",
			width: "8%",
		},
		{
			title: "Saldo",
			dataIndex: "saldo_actual",
			key: "saldo_actual",
			width: "8%",
		},
	];

	//Modal functions
	const handleAdmonishModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setAdmonishModalActive(statusModal);
		}, 50);
	};

	const handleEliminateModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setEliminateModalActive(statusModal);
		}, 50);
	};

	const handleDeactivateModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setUserActivationModalActive(statusModal);
		}, 50);
	};

	//Form functions
	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const onFinishAdmonishForm = (e) => {
		const admonishDescription = e.admonishDescription;
		console.log("form", admonishDescription);
	};

	const onFinishEliminateForm = (e) => {
		const eliminateOptions = {
			description: e.eliminateDescription,
			option: e.eliminateOption,
		};
		console.log("form", e);
	};

	const onFinishActivateForm = (e) => {
		const activateDescription = e.activateDescription;
		console.log("form", activateDescription);
	};

	return (
		<>
			{console.log("dato usuario", user)}
			<div>
				<div className="wrapper-page">
					<h2 className="title-page">
						{user.name} {user.lastname}
						<img
							className="iconPerfil"
							src="/assets/images/shape08.png"
							alt="Topia"
							style={{ marginLeft: "15px", cursor: "pointer" }}
							onClick={() => handleProfileModal(true)}
						/>
					</h2>

					<Row className="profile-user-data">
						<Col span={8}>
							<div className="profile-user-data-container">
								<div>
									<span className="title" style={{ fontSize: "14px" }}>
										<img
											src="/assets/images/shape17.png"
											alt="Topia"
											style={{ marginRight: "5px" }}
										/>
										Tipo de socio:{" "}
										{user.residentials
											? user.residentials[0].type === "P"
												? "Propietario"
												: "N/A"
											: "N/A"}
									</span>
								</div>
								<div style={{ paddingTop: "25px" }}>
									<span className="title" style={{ fontSize: "14px" }}>
										<img
											src="/assets/icons/calendario.svg"
											alt="Topia"
											style={{ marginRight: "5px" }}
										/>
										Socio desde: {moment(user.updated_at).format("YY/MM/D")}
									</span>
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="profile-user-data-container">
								<div>
									<span className="title" style={{ fontSize: "14px" }}>
										<img
											src="/assets/images/telefonoNegro.png"
											alt="Topia"
											style={{
												marginLeft: "2px",
												marginRight: "7px",
												color: "black",
											}}
										/>
										Teléfono: {user.phone ? user.phone : "N/A"}
									</span>
								</div>
								<div style={{ paddingTop: "25px" }}>
									<span className="title" style={{ fontSize: "14px" }}>
										<img
											src="/assets/images/shape19.png"
											alt="Topia"
											style={{ marginRight: "5px" }}
										/>
										Socio desde: {moment(user.updated_at).format("YY/MM/D")}
									</span>
								</div>
							</div>
						</Col>
						<Col span={8}>
							<div className="profile-user-data-container">
								<div>
									<span
										className="user-name"
										style={{ borderBottom: "dashed", borderWidth: "1px" }}
									>
										SALDO EN MORA:
									</span>
								</div>
								<div style={{ paddingTop: "15px" }}>
									<span className="title" style={{ fontSize: "14px" }}>
										Pagos en mora: ${user.mora}
									</span>
									<img
										src="/assets/icons/reporte.svg"
										alt="Topia"
										style={{ marginLeft: "5px", cursor: "pointer" }}
										onClick={() => handleAccountStatementModal(true)}
									/>
								</div>
								<div>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginTop: "20px",
										}}
										shape="round"
									>
										<span
											className="title"
											style={{ marginLeft: "25px", marginRight: "25px" }}
										>
											{" "}
											ENVIAR RECORDATORIO
										</span>
									</Button>
								</div>
							</div>
						</Col>
					</Row>

					{/* MODAL PARA PERFIL SOCIO */}
					<Modal
						className="side-modal"
						title="Datos del socio"
						style={{ top: 0, right: 0 }}
						visible={profileModalActive}
						onCancel={() => handleProfileModal(false)}
						footer={
							<Row>
								<Col span={6}></Col>
								<Col span={6}></Col>
								<Col span={6}></Col>
								<Col span={6}>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
										}}
										onClick={() => {
											handleEliminateModal(true);
										}}
									>
										<span className="title">Eliminar usuario</span>
									</Button>
								</Col>
							</Row>
						}
						width={600}
					>
						{console.log("dato modal", userProfileActive)}
						{userProfileActive ? (
							<>
								<div
									className="section-modal-active"
									style={{ marginBottom: "0px" }}
								>
									<p>Activo</p>
									<Switch
										defaultChecked
										style={{ backgroundColor: "#16A07F" }}
										onChange={(checked) => {
											checked
												? //CHECAR ESTADO, usar handle con if
												  console.log("reactivar usuario", checked)
												: handleDeactivateModal(true);
										}}
									/>
								</div>
								<Row className="section-modal" style={{ paddingTop: "0px" }}>
									<Col span={24} style={{ paddingLeft: "20px" }}>
										<div className="user-data">
											{userProfileActive.image ? (
												<img
													style={{
														backgroundImage:
															`url(` + userProfileActive.image + `)`,
													}}
													className="user-image avatarUser"
													src={userProfileActive.image}
													alt="Topia"
												/>
											) : (
												<img
													className="user-image"
													src="/assets/images/avatar_user.png"
													alt="Topia"
												/>
											)}

											<div
												className="user-info"
												style={{ paddingLeft: "20px" }}
											>
												<span className="user-name">
													{userProfileActive.name} {userProfileActive.lastname}
												</span>
												<span
													className="user-address"
													style={{ paddingTop: "5px", paddingBottom: "5px" }}
												>
													{" Junta directiva: "}
													{userProfileActive.role_id
														? userProfileActive.role_id
														: "N/A"}
												</span>
												<div style={{ display: "flex" }}>
													<span
														style={{
															color: "#10B48C",
															display: "flex",
															alignItems: "flex-start",
															margin: "0px",
														}}
													>
														<img
															className="iconoPerfil"
															src="/assets/images/phone.png"
															alt="Topia"
														/>

														<span style={{ marginLeft: "5x" }}>
															{userProfileActive.phone
																? userProfileActive.phone
																: ""}
														</span>
														<img
															className="iconoPerfil"
															src="/assets/images/mensaje.png"
															alt="Topia"
														/>

														<div className="contEmails">
															<span className="emails">
																{userProfileActive.email}
															</span>
															<span className="emails">
																{userProfileActive.emailTwo}
															</span>
														</div>
													</span>
												</div>
											</div>
										</div>
									</Col>
									<Col span={24}>
										<div>
											<img
												style={{ marginLeft: "12px" }}
												src="/assets/images/shape26.png"
												alt="Topia"
											/>
											<img src="/assets/images/shape27.png" alt="Topia" />
											<img src="/assets/images/shape27.png" alt="Topia" />
										</div>
									</Col>
									<Col span={24}>
										<div style={{ paddingTop: "15px", paddingLeft: "0px" }}>
											<Button
												style={{
													color: "#10b48c",
													borderColor: "#10b48c",
													background: "white",
												}}
												shape="round"
												size="small"
												onClick={() => {
													handleAdmonishModal(true);
												}}
											>
												<span className="title"> Amonestar</span>
											</Button>
										</div>
									</Col>
								</Row>

								<Row className="section-modal">
									<Col span={24} style={{ paddingLeft: "20px" }}>
										<div className="alert-modal-header">
											<Row>
												<Col span={8}>
													<span className="title" style={{ fontSize: "10px" }}>
														<img
															style={{ marginRight: "10px", display: "inline" }}
															src="/assets/images/shape17.png"
															alt="Topia"
														/>
														Tipo de residente
													</span>
													<div className="alert-modal-text">
														<p>
															{userProfileActive.ownerHome
																? "Propietario"
																: "Familiar o amigo"}
														</p>
													</div>
												</Col>

												<Col span={16}>
													<span className="title" style={{ fontSize: "10px" }}>
														<img
															style={{ marginRight: "10px", display: "inline" }}
															src="/assets/images/shape18.png"
															alt="Topia"
														/>
														Dirección de residencia
													</span>
													{/* <p>{userProfileActive.listOfResidentialsData[0]?.address}</p> */}
												</Col>
												{userProfileActive.vehicles_list ? (
													userProfileActive.vehicles_list_data.map(
														(item, key) => {
															return (
																<Col span={6} key={key}>
																	<span
																		className="title"
																		style={{ fontSize: "10px" }}
																	>
																		<img
																			style={{
																				marginRight: "10px",
																				display: "inline",
																			}}
																			src="/assets/images/shape19.png"
																			alt="Topia"
																		/>
																		Auto {key + 1}
																	</span>
																	<p>
																		{item.model} ({item.color}) <br />
																		{item.vehicle}
																	</p>
																</Col>
															);
														}
													)
												) : (
													<Col span={6}>
														<span
															className="title"
															style={{ fontSize: "10px" }}
														>
															<img
																style={{
																	marginRight: "10px",
																	display: "inline",
																}}
																src="/assets/images/shape19.png"
																alt="Topia"
															/>
															Auto:
														</span>
														<div
															className="alert-modal-text"
															style={{ marginLeft: "5px" }}
														>
															<p>N/A</p>
														</div>
													</Col>
												)}
												<Col span={18} style={{ paddingLeft: "100px" }}>
													<Button
														style={{
															color: "#10b48c",
															borderColor: "#10b48c",
															background: "white",
														}}
														shape="round"
														icon={<IconButtonReport />}
													>
														<span
															className="title"
															style={{ marginLeft: "10px" }}
														>
															{" "}
															REPORTE DE ACTIVIDAD
														</span>
													</Button>
												</Col>
											</Row>

											{/* 									<Row>
										{userProfileActive.vehicles_list_data.map((item, key) => {
											return (
												<Col span={12} key={key}>
													<span className="title" style={{ fontSize: "10px" }}>
														<img
															style={{ marginRight: "10px", display: "inline" }}
															src="/assets/images/shape19.png"
															alt="Topia"
														/>
														Auto {key + 1}
													</span>
													<p>
														{item.model} ({item.color}) <br />
														{item.vehicle}
													</p>
												</Col>
											);
										})}
									</Row> */}
										</div>
									</Col>
								</Row>
								<Row className="section-modal">
									<Col span={24}>
										<div className="alert-modal-header">
											<p
												className="user-name"
												style={{ textTransform: "uppercase" }}
											>
												Familiares con acceso
											</p>
										</div>
									</Col>

									{userProfileActive.relativesWithAccess ? (
										<div style={{ marginLeft: "20px" }}>
											<Row>
												{userProfileActive.relativesWithAccess.map(
													(relative, key) => {
														return (
															<Col span={12} key={key}>
																<span
																	style={{ color: "#10B48C", fontSize: "12px" }}
																>
																	{relative.name} ({relative.relationship})
																</span>
															</Col>
														);
													}
												)}
											</Row>
										</div>
									) : (
										<>
											<Col span={24} style={{ textAlign: "center" }}>
												<b>Socios dependientes no encontrados.</b>
											</Col>
										</>
									)}
								</Row>

								<Row className="section-modal">
									<Col span={12}>
										<div className="alert-modal-header">
											<p
												className="user-name"
												style={{ textTransform: "uppercase" }}
											>
												Pagos
											</p>
										</div>

										{userProfileActive.billingData?.length > 0 ? (
											<>
												<Row>
													<Col span={12}>
														<div
															className="alert-modal-text"
															style={{
																paddingTop: "1rem",
															}}
														>
															<p>
																{userProfileActive.billingData[0]?.invoice_date}
															</p>
														</div>
													</Col>
													<Col span={12}>
														<div
															className="alert-modal-text"
															style={{
																paddingTop: "1rem",
															}}
														>
															<p>
																{
																	userProfileActive.billingData[0]
																		?.invoice_total
																}
															</p>
														</div>
													</Col>
													<Col span={12}>
														<div className="alert-modal-text">
															<p>
																{userProfileActive.billingData[1]?.invoice_date}
															</p>
														</div>
													</Col>
													<Col span={12}>
														<div className="alert-modal-text">
															<p>
																{
																	userProfileActive.billingData[1]
																		?.invoice_total
																}
															</p>
														</div>
													</Col>
													<Col span={12}>
														<div className="alert-modal-text">
															<p>
																{userProfileActive.billingData[2]?.invoice_date}
															</p>
														</div>
													</Col>
													<Col span={12}>
														<div className="alert-modal-text">
															<p>
																{
																	userProfileActive.billingData[2]
																		?.invoice_total
																}
															</p>
														</div>
													</Col>
												</Row>
											</>
										) : (
											<>
												<Col
													span={24}
													style={{
														textAlign: "center",
														paddingTop: "1rem",
													}}
												>
													<p>Datos de pagos no encontrados</p>
												</Col>
											</>
										)}
									</Col>
									<Col span={12}>
										<div className="alert-modal-header">
											<p
												className="user-name"
												style={{
													textTransform: "uppercase",
													borderBottom: "dashed",
													borderWidth: "1px",
												}}
											>
												Saldo en mora:
											</p>
										</div>
										<div
											className="alert-modal-text"
											style={{
												paddingTop: "1rem",
											}}
										>
											<p>
												Mora total: ${userProfileActive.mora}
												<img
													src="/assets/icons/reporte.svg"
													alt="Topia"
													style={{ marginLeft: "5px" }} //Mofificar cuando se agregue el estado de cuenta
												/>
											</p>
											<Button
												style={{
													color: "#10b48c",
													borderColor: "#10b48c",
													background: "white",
													marginLeft: "3rem",
												}}
												shape="round"
											>
												<span className="title">Enviar recordatorio</span>
											</Button>
										</div>
									</Col>
								</Row>
							</>
						) : (
							<>
								<Row className="section-modal">
									<Col
										span={24}
										style={{ textTransform: "uppercase", textAlign: "center" }}
									>
										<b>Datos de perfil no encontrados</b>
									</Col>
								</Row>
							</>
						)}
					</Modal>
					{/* MODAL PARA ESTADO CUENTA SOCIO */}
					<Modal
						className="side-modal alert-modal modalEncuesta "
						bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 100px)" }}
						title={
							accountActive ? (
								<>
									{console.log("dato modal", accountActive)}
									<Row
										className="encabezado"
										style={{ textAlign: "left", paddingLeft: "25px" }}
									>
										<Col span={13}>
											<div>NOMBRE DEL SOCIO</div>
											<h2>
												{accountActive.name} {accountActive.lastname}
											</h2>
											<Row style={{ marginTop: "2rem" }}>
												<Col span={8}>
													<h6 style={{ fontWeight: "bold" }}>
														Desde:{" "}
														{moment(accountActive.created_at).format("Y-MM-DD")}
													</h6>
												</Col>
												<Col span={8}>
													<h6 style={{ fontWeight: "bold" }}>
														Hasta:{" "}
														{moment(accountActive.created_at).format("Y-MM-DD")}
													</h6>
												</Col>
												<Col span={8}></Col>
											</Row>
										</Col>
										<Col
											span={3}
											style={{ display: "flex", marginTop: "1rem" }}
										>
											<div className="bloqueEncuesta">
												CÓDIGO:
												<div className="numeroEncuesta">
													{accountActive.origin_fam}
												</div>
											</div>
										</Col>
										<Col
											span={3}
											style={{ display: "flex", marginTop: "1rem" }}
										>
											<div className="bloqueEncuesta">
												SALDO:
												<div className="numeroEncuesta">
													${" "}
													{accountActive.saldoTotal == "0"
														? "0.00"
														: accountActive.saldoTotal}
												</div>
											</div>
										</Col>
										<Col
											span={5}
											style={{ display: "flex", marginTop: "1rem" }}
										>
											<div className="bloqueEncuesta">
												<Button
													style={{
														color: "#10b48c",
														borderColor: "#10b48c",
														background: "white",
														paddingBottom: "15px",
													}}
													shape="round"
													icon={<IconButtonReport />}
													size="large"
												>
													<span
														className="title"
														style={{ marginLeft: "10px" }}
													>
														Exportar estado de cuenta
													</span>
												</Button>
											</div>
										</Col>
									</Row>
								</>
							) : (
								<>
									<Row className="section-modal">
										<Col
											span={24}
											style={{
												textTransform: "uppercase",
												textAlign: "center",
											}}
										>
											<b>Datos de perfil no encontrados</b>
										</Col>
									</Row>
								</>
							)
						}
						style={{ top: 0, right: 0, width: "100%" }}
						visible={accountStatementModalActive}
						onCancel={() => setAccountStatementeModalActive(false)}
						footer={null}
						width={"95%"}
					>
						{accountActive ? (
							<div className="scroll">
								<Row>
									<Col span={24}>
										<Table
											className="primary-table"
											pagination={{ defaultPageSize: 10, simple: true }}
											columns={accountColumns}
											loading="true"
											rowKey="id"
											dataSource={accountActive.billingData}
										/>
									</Col>
								</Row>
							</div>
						) : (
							<>
								<Row className="section-modal">
									<Col
										span={24}
										style={{ textTransform: "uppercase", textAlign: "center" }}
									>
										<b>Datos de perfil no encontrados</b>
									</Col>
								</Row>
							</>
						)}
					</Modal>

					<Row>
						<Col span={24}>
							{/* <UserProfilePageData id={id} data={userHistoricalData} /> */}
							<UserProfileContainerTabs
								buscarFechas={buscarFechas}
								loadingBt={loadingBt}
								id={id}
								data={userHistoricalData}
								permissionData={permissionData}
							/>
						</Col>
					</Row>
				</div>
			</div>

			{/* MODAL PARA AMONESTAR */}

			<Modal
				className="primary-modal"
				title="AMONESTACIÓN"
				visible={admonishModalActive}
				onCancel={() => handleAdmonishModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userProfileActive ? (
						<Form name="admonish_user_form" onFinish={onFinishAdmonishForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<span className="title">MOTIVO DE LA AMONESTACIÓN:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="admonishDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS AMONESTAR A {userProfileActive.name}{" "}
											{userProfileActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}
											className="btn-primary"
											type="primary"
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => {}}
										>
											APLICAR AMONESTACIÓN
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>

			{/* MODAL PARA ELIMINAR */}

			<Modal
				className="primary-modal"
				title="ELIMINAR USUARIO"
				visible={eliminateModalActive}
				onCancel={() => handleEliminateModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userProfileActive ? (
						<Form name="eliminate_user_form" onFinish={onFinishEliminateForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<FormItem name="eliminateOption" {...config}>
											<Radio.Group>
												<Space direction="vertical">
													<Radio style={{ fontSize: "14px" }} value={1}>
														Eliminar cuenta principal y cuentas de familiares
													</Radio>
													<Radio style={{ fontSize: "14px" }} value={2}>
														Eliminar únicamente el usuario y reasignar cuenta
														principal
													</Radio>
												</Space>
											</Radio.Group>
										</FormItem>
									</div>
									<div className="alert-modal-header">
										<span className="title">MOTIVO:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="eliminateDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
										<FormItem name="id" hidden={true}>
											{userProfileActive.id}
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS ELIMINAR LA CUENTA DE{" "}
											{userProfileActive.name} {userProfileActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}

											type="primary"
											danger
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => {}}
										>
											ELIMINAR
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>

			{/* MODAL PARA DESACTIVAR/ACTIVAR */}

			<Modal
				className="primary-modal"
				title="ACTIVAR/DESACTIVAR USUARIO TEMPORALMENTE"
				visible={userActivationModalActive}
				onCancel={() => handleDeactivateModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userProfileActive ? (
						<Form name="admonish_user_form" onFinish={onFinishActivateForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<span className="title">MOTIVO DE LA DESACTIVACIÓN:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="admonishDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS DESACTIVAR A {userProfileActive.name}{" "}
											{userProfileActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}
											className="btn-primary"
											type="primary"
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => {}}
										>
											DESACTIVAR
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>
		</>
	);
};

export default UserProfilePage;
