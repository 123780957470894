import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useCheckAuth } from '../hooks';
import { DashboardRouter } from '../topia/routes/DashboardRouter';
import { AuthRoutes } from '../auth/routes/AuthRoutes';
import { CheckingAuth } from '../components/CheckingAuth';



export const AppRouter = () => {

  
  const status = useCheckAuth();

  if ( status === 'checking' ) {
    return <CheckingAuth />
  }


  return (
    <BrowserRouter>
      <Routes>

        {
          (status === 'authenticated')
           ? <Route path="/*" element={ <DashboardRouter /> } />
           : <Route path="/auth/*" element={ <AuthRoutes /> } />
        }

        <Route path='/*' element={ <Navigate to='/auth/login' />  } />
      </Routes>
    </BrowserRouter>
  )
}
