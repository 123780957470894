import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export const alertSlice = createSlice({
    name: 'alerts',
    initialState: {
        alerts: {
            data: [],
            active: null
        },
        errorMessage: null
    },
    reducers: {
        setAlertsData: ( state, { payload } ) => {
            state.alerts.data = payload;
        },
        setActiveAlert: (state, { payload })=>{
            state.alerts.active = payload
        }
    }
});


export const { 
    setAlertsData,
    setActiveAlert,
} = alertSlice.actions;