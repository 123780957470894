import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { submenuHelper } from "../../helpers/submenuItemsHelper";
import { setSubmenu } from "../../store/submenu/submenuSlice";
export const Sidebar = ({ rol }) => {
	const dispatch = useDispatch();

	const onSubmenu = (submenu) => {
		if (submenuHelper[submenu]) {
			const menu = submenuHelper[submenu];
			dispatch(setSubmenu(menu));
		}
	};

	return (
		<div>
			<Link to="/">
				<img className="logo" src="/assets/images/logo-sidebar.png" alt="" />
			</Link>
			<ul>
				{/* <li className="item">
					<NavLink to="/destacados">
						<img src="/assets/images/home-icon.png" alt="Topia - home" />
						<span>Inicio</span>
					</NavLink>
				</li> */}
				{/* <li className="item">
					<NavLink to="/accesos">
						<img src="/assets/images/access-icon.png" alt="Topia - access" />
						<span>Accesos</span>
					</NavLink>
				</li>
				<li className="item">
					<NavLink
						to="#"
						className={({ isActive }) => (isActive ? "active-green" : "none")}
						onClick={() => {
							onSubmenu("messages");
						}}
					>
						<img
							src="/assets/images/messages-icon.png"
							alt="Topia - Mensajería"
						/>
						<span>Mensajería</span>
					</NavLink>
				</li>
				<li className="item">
					<NavLink
						to="#"
						className={({ isActive }) => (isActive ? "active-green" : "none")}
						onClick={() => {
							onSubmenu("users");
						}}
					>
						<img src="/assets/images/users-icon.png" alt="Topia - Usuarios" />
						<span>Usuarios</span>
					</NavLink>
				</li> */}
				<li className="item">
					<NavLink to="/noticias">
						<img src="/assets/images/mensaje_oscuro.png" alt="Topia - config" />
						<span>Mensajería</span>
					</NavLink>
				</li>
				{/* <li className="item">
					<NavLink to="/caja">
						<img src="/assets/images/reporteIcon.png" alt="Topia - report" />
						<span>Ingresos</span>
					</NavLink>
				</li> */}

				<li className="item">
					<NavLink
						to="#"
						className={({ isActive }) => (isActive ? "active-green" : "active")}
						onClick={() => {
							onSubmenu("payments");
						}}
					>
						<img src="/assets/images/reporteIcon.png" alt="Topia - report" />
						<span>Ingresos</span>
					</NavLink>
				</li>

				{/*

                       
                        
                        <li className='item'>
                            <NavLink to="#" onClick={ () => {onSubmenu("messages")}  }>
                                <img src="/assets/images/messages-icon.png" alt="Topia - messages" />
                                <span>Mensajería</span>
                            </NavLink>

                        </li>
                        <li className='item'>
                            <NavLink to="#" onClick={ () => {onSubmenu("payments")}  }>
                                <img src="/assets/images/payments-icon.png" alt="Topia - payments" />
                                <span>Cobros y pagos</span>
                            </NavLink>

                        </li>
                        
                         <li className='item'>
                    <NavLink to="#" onClick={ () => {onSubmenu(rol == "entity_type/qrMXMVseRyumYmTvctRj" ? "settingsOficina" : "settings")}  } >
                            <img src="/assets/images/config-icon.png" alt="Topia - config" />
                            <span>Ajustes</span>
                        </NavLink>
                    </li>
                    
                        
                    */}
			</ul>
		</div>
	);
};
