import React from "react";
import { useEffect, useState } from "react";
import { Col, Row, Button, Layout, DatePicker, Tabs, Select } from "antd";
import moment from "moment";
import { Content, Footer } from "antd/lib/layout/layout";
import { PaymentsHistoryTable } from "./components/PaymentsHistoryTable";
const { RangePicker } = DatePicker;

const PaymentsHistoryPage = () => {
	const [loadingBt, setLoadBt] = useState(false);
	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		setLoadBt(true);
	}, []);

	//BUSCA FECHA INICIO
	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);
	};
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");

	const onChangeFecha = (date, dateString) => {
		console.log(date, dateString);
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		buscar();
	};

	const buscar = async () => {
		if (startDate == "") {
			var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
			buscarFechas(fechaIn, fechaFi);
		} else {
			var fechaI = new Date(moment(startDate, "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(endDate, "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
			buscarFechas(fechaIn, fechaFi);
		}
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const { Option } = Select;

	const handleAppChange = () => {};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={12}>
								<h2 className="title-page">
									Historial de pagos/
									<span className="residential">Residencial los sueños</span>
								</h2>
							</Col>
							<Col span={12} align="right">
								<RangePicker
									defaultValue={[
										moment(new Date(), dateFormat),
										moment(new Date(), dateFormat),
									]}
									format={customFormat}
									style={{
										width: "270px",
									}}
									onCalendarChange={onChangeFecha}
								/>
							</Col>
							<br />
							<br />
							<br />
							<Col span={24}>
								<Select
									placeholder="APP"
									bordered={false}
									className="select_style"
									style={{
										width: "300px",
									}}
									filterOption={(input, option) => {
										return (
											option.label.toLowerCase().indexOf(input.toLowerCase()) >=
											0
										);
									}}
									onChange={handleAppChange}
								></Select>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<PaymentsHistoryTable />
							</Col>
						</Row>
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default PaymentsHistoryPage;
