import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const getColumnSearchProps = (dataIndex, searchInput) => ({
	filterDropdown: ({
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
	}) => (
		<div style={{ padding: 8 }}>
			<Input
				ref={searchInput}
				value={selectedKeys[0]}
				onChange={(e) =>
					setSelectedKeys(e.target.value ? [e.target.value] : [])
				}
				onPressEnter={() => confirm()}
				style={{ marginBottom: 8, display: "block" }}
			/>
			<Space>
				<Button
					type="primary"
					onClick={() => confirm()}
					icon={<SearchOutlined />}
					size="small"
					style={{ width: 90 }}
				>
					Buscar
				</Button>
				<Button
					onClick={() => {
						clearFilters();
						confirm();
					}}
					size="small"
					style={{ width: 90 }}
				>
					Limpiar
				</Button>
			</Space>
		</div>
	),
	filterIcon: (filtered) => (
		<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
	),
	onFilter: (value, record) =>
		record[dataIndex]
			? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
			: "",
	onFilterDropdownVisibleChange: (visible) => {
		if (visible) {
			setTimeout(() => searchInput?.current.select(), 100);
		}
	},
});
