import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';

import { FirebaseAuth } from '../firebase/config';
import { login, logout } from '../store/auth';
import { getQueryDocByCollection } from '../helpers/globalHelper';



export const useCheckAuth = () => {
  
    const { status } = useSelector( state => state.auth );
    const dispatch = useDispatch();

    useEffect(() => {
        
        onAuthStateChanged( FirebaseAuth, async( user ) => {
            if ( !user ) return dispatch( logout() );

                    
            const committeeData = await getQueryDocByCollection('committee', "user_id", "==", user.uid);

            // Verify committee
            if ( committeeData.length < 1 ) return dispatch( logout() );

            // Verify residential in committee
            if(!committeeData[0].residential_id) return dispatch( logout() );

            //verificar el rol de la residencial
            const residenciaData = await getQueryDocByCollection('residentials', "idResidential", "==", committeeData[0].residential_id);
            var rolResidential = residenciaData[0].entity_type.path

            const committee = {
                lastname: committeeData[0].lastname,
                name: committeeData[0].name,
                superadmin: committeeData[0].superadmin,
                residential_id: committeeData[0].residential_id,
                user_id: committeeData[0].user_id,
                residentialData: residenciaData[0],
                rolResidential: rolResidential
            }
            const { uid, email, displayName, photoURL } = user;
            dispatch( login({ uid, email, displayName, photoURL, committee }) );
        })
    }, []);

    return status;
}