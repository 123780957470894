export const submenuHelper = {
	messages: {
		title: "Mensajería",
		items: [
			{
				name: "Alertas recibidas",
				url: "/alertas-recibidas",
			},
			{
				name: "Solicitudes de reserva",
				url: "/solicitudes-de-reserva",
			},
			{
				name: "Paquetes en recepción",
				url: "/paquetes-en-recepcion",
			},
			{
				name: "Objetos perdidos",
				url: "/objetos-perdidos",
			},
			{
				name: "Publicación de noticias",
				url: "/noticias",
			},
			{
				name: "Envío de convocatorias",
				url: "/convocatorias",
			},
			{
				name: "Encuestas",
				url: "/encuestas",
			},
		],
	},
	users: {
		title: "Usuarios",
		items: [
			{
				name: "Perfiles de usuarios",
				url: "/usuarios",
			},
			{
				name: "Usuarios desactivados",
				url: "/usuarios-desactivados",
			},
		],
	},
	payments: {
		title: "Ingresos",
		items: [
			{
				name: "Pagos recibidos",
				url: "/caja",
			},
			{
				name: "Historial (maquetado)",
				url: "/historial",
			},
			{
				name: "Comisiones recibidas",
				url: "/comisiones",
			},
			{
				name: "Liquidaciones",
				url: "/liquidaciones",
			},
		],
	},
	settings: {
		title: "Ajustes",
		items: [
			{
				name: "Amenidades",
				url: "#",
			},
			{
				name: "Junta Directiva",
				url: "#",
			},
			{
				name: "Agentes de seguridad",
				url: "#",
			},
			{
				name: "Turnos de vigilancia",
				url: "#",
			},
			{
				name: "Configuración inicial",
				url: "/configuracion-inicial",
			},
		],
	},
	settingsOficina: {
		title: "Ajustes",
		items: [
			{
				name: "Zonas",
				url: "/zonas",
			},
			{
				name: "Junta Directiva",
				url: "#",
			},
			{
				name: "Configuración inicial",
				url: "#",
			},
		],
	},
};
