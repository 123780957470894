import { useEffect, useState } from "react";
import { Tabs, DatePicker, Button } from "antd";
import { AccessResidentsTab } from "./AccessResidentsTab";
import { AccessVisitorsTab } from "./AccessVisitorsTab";
import { AccessScheduleVisitorsTab } from "./AccessScheduleVisitorsTab";
import "../style.css";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";

const { RangePicker } = DatePicker;

export const AccessTabs = (props) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const onChangeFecha = (date, dateString) => {
		console.log(date, dateString);
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		buscar();
	};

	const { buscarFechas, loadingBt } = props;

	const buscar = async () => {
		if (startDate == "") {
			var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));
			buscarFechas(fechaIn, fechaFi);
		} else {
			var fechaI = new Date(moment(startDate, "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(endDate, "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(24, 0, 0, 0));
			buscarFechas(fechaIn, fechaFi);
		}
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const { TabPane } = Tabs;
	return (
		<>
			<Tabs className="primary-tabs" defaultActiveKey="1">
				<TabPane tab="RESIDENTES" key="1">
					<AccessResidentsTab></AccessResidentsTab>
				</TabPane>

				<TabPane tab="VISITANTES" key="2">
					<AccessVisitorsTab></AccessVisitorsTab>
				</TabPane>

				<TabPane tab="VISITANTES PROGRAMADOS" key="3">
					<AccessScheduleVisitorsTab></AccessScheduleVisitorsTab>
				</TabPane>
			</Tabs>
			<div className="contBusqueda">
				<RangePicker
					defaultValue={[
						moment(new Date(), dateFormat),
						moment(new Date(), dateFormat),
					]}
					format={customFormat}
					locale={locale}
					style={{
						width: "270px",
					}}
					onCalendarChange={onChangeFecha}
				/>

				{/* <Button
					disabled={loadingBt}
					onClick={() => {
						buscar();
					}}
					size="large"
					type="primary"
					className={"btn-primary btBuscar"}
				>
					BUSCAR
				</Button> */}
			</div>
		</>
	);
};
