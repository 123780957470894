import React from "react";
import { useEffect, useState } from "react";
import { Col, Row, Button, Layout, DatePicker, Tabs, Select } from "antd";
import moment from "moment";
import { Content, Footer } from "antd/lib/layout/layout";
import { CommissionsTable } from "./components/CommissionsTable";
const { RangePicker } = DatePicker;

const CommissionsPage = () => {
	const [loadingBt, setLoadBt] = useState(false);
	const [button1, setButton1] = useState(true);
	const [button2, setButton2] = useState(false);
	const [button3, setButton3] = useState(false);

	useEffect(() => {
		let today = new Date();
		var fechaInicio = new Date(today.setHours(0, 0, 0, 0));
		var fechaFinal = new Date(today.setHours(23, 59, 59, 0));

		setLoadBt(true);
	}, []);

	//BUSCA FECHA INICIO
	const buscarFechas = async (fechaInicio, fechaFin) => {
		setLoadBt(true);
	};
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");

	const onChangeFecha = (date, dateString) => {
		console.log(date, dateString);
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		buscar();
	};

	const buscar = async () => {
		if (startDate == "") {
			var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
			buscarFechas(fechaIn, fechaFi);
		} else {
			var fechaI = new Date(moment(startDate, "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(endDate, "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
			buscarFechas(fechaIn, fechaFi);
		}
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const { Option } = Select;

	const handleCountryChange = () => {};

	const handleAccountChange = () => {};

	const handleSelectedButton = (button) => {
		if (button === 1) {
			setButton1(true);
			setButton2(false);
			setButton3(false);
		}
		if (button === 2) {
			setButton1(false);
			setButton2(true);
			setButton3(false);
		}
		if (button === 3) {
			setButton1(false);
			setButton2(false);
			setButton3(true);
		}
		if (button > 3 || button < 1) {
			setButton1(false);
			setButton2(false);
			setButton3(false);
		}
	};

	return (
		<>
			<Layout>
				<Content>
					<div className="wrapper-page">
						<Row>
							<Col span={24}>
								<h2 className="title-page">comisiones recibidas</h2>
							</Col>

							<br />
							<br />
							<br />
							<Col span={5}>
								<Select
									placeholder="País"
									bordered={false}
									className="select_style"
									style={{
										width: "90%",
									}}
									onChange={handleCountryChange}
									defaultActiveFirstOption={true}
									options={[
										{
											value: "sv",
											label: "El Salvador",
										},
										{
											value: "hn",
											label: "Honduras",
										},
									]}
								/>
							</Col>
							<Col span={5}>
								<Select
									placeholder="Cuenta"
									bordered={false}
									className="select_style"
									style={{
										width: "90%",
									}}
									filterOption={(input, option) => {
										return (
											option.label.toLowerCase().indexOf(input.toLowerCase()) >=
											0
										);
									}}
									onChange={handleAccountChange}
								></Select>
							</Col>
							<Col span={5}>
								<Select
									placeholder="Cuentas"
									bordered={false}
									className="select_style"
									style={{
										width: "90%",
									}}
									filterOption={(input, option) => {
										return (
											option.label.toLowerCase().indexOf(input.toLowerCase()) >=
											0
										);
									}}
								></Select>
							</Col>
							<Col span={9} align="left">
								<RangePicker
									defaultValue={[
										moment(new Date(), dateFormat),
										moment(new Date(), dateFormat),
									]}
									format={customFormat}
									style={{
										width: "270px",
									}}
									onCalendarChange={onChangeFecha}
								/>
							</Col>

							<Col span={4} align="middle" style={{ marginTop: "2rem" }}>
								<div
									className="button-container"
									onClick={() => {
										handleSelectedButton(1);
									}}
									style={{ cursor: "pointer" }}
								>
									<span
										className="button-label"
										style={{
											color: "#707070",
											fontSize: "12px",
											fontWeight: "400",
										}}
									>
										Transacciones individuales
									</span>
									<br />
									<div
										style={{
											height: "5.93px",
											width: "190px",
											border: "1px solid #bdc4c5",
											borderRadius: "5px",
											backgroundColor: button1 ? "#10b48c" : "transparent",
										}}
									></div>
								</div>
							</Col>
							<Col span={4} align="middle" style={{ marginTop: "2rem" }}>
								<div
									className="button-container"
									onClick={() => {
										handleSelectedButton(2);
									}}
									style={{ cursor: "pointer" }}
								>
									<span
										className="button-label"
										style={{
											color: "#707070",
											fontSize: "12px",
											fontWeight: "400",
										}}
									>
										Agrupado por semana
									</span>
									<br />
									<div
										style={{
											height: "5.93px",
											width: "190px",
											border: "1px solid #bdc4c5",
											borderRadius: "5px",
											backgroundColor: button2 ? "#10b48c" : "transparent",
										}}
									></div>
								</div>
							</Col>
							<Col span={4} align="middle" style={{ marginTop: "2rem" }}>
								<div
									className="button-container"
									onClick={() => {
										handleSelectedButton(3);
									}}
									style={{ cursor: "pointer" }}
								>
									<span
										className="button-label"
										style={{
											color: "#707070",
											fontSize: "12px",
											fontWeight: "400",
										}}
									>
										Agrupado por mes
									</span>
									<br />
									<div
										style={{
											height: "5.93px",
											width: "190px",
											border: "1px solid #bdc4c5",
											borderRadius: "5px",
											backgroundColor: button3 ? "#10b48c" : "transparent",
										}}
									></div>
								</div>
							</Col>
							<Col span={12}></Col>
							<br />
							<br />
							<Col span={24} style={{ marginTop: "2rem" }}>
								<CommissionsTable />
							</Col>
						</Row>
					</div>
				</Content>
			</Layout>
		</>
	);
};

export default CommissionsPage;
