import React from "react";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import FormItem from "antd/lib/form/FormItem";
import { getColumnSearchProps } from "../../../../utils/tables";
import { SearchOutlined } from "@ant-design/icons";
import "../styles.css";
import {
	onCreateOrUpdate,
	onUpdateDocWithRefInfo,
} from "../../../../helpers/globalHelper";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

export const PendingSettlementTab = (props) => {
	const [settleAccountModalActive, setSettleAccountModalActive] =
		useState(false);
	const [loadButton, setLoadButton] = useState(false);
	const [selected, setSelected] = useState([]);
	const [selectedBank, setSelectedBank] = useState(null);
	const [selectedAgricola, setSelectedAgricola] = useState(false);
	const [residentials, setResidentials] = useState([]);
	const [activeRecord, setActiveRecord] = useState([]);
	const [startDate, setStartDate] = useState(
		moment(new Date()).format("DD/MM/YYYY")
	);
	const [endDate, setEndDate] = useState(
		moment(new Date()).format("DD/MM/YYYY")
	);
	const [activeResidential, setActiveResidential] = useState([]);
	const [pendingTransactions, setPendingTransactions] = useState([]);

	const dateFormat = "DD/MM/YYYY";
	const formRef = useRef();
	const customFormat = (value) => `${value.format(dateFormat)}`;

	const {
		searchPendingByDate,
		loadingBt,
		data,
		showFooter,
		setShowFooter,
		selectedResidentialsFooter,
		searchAccreditedByDate,
	} = props;

	const navigate = useNavigate();

	useEffect(() => {
		/* const addIcons = data.map((item,index) => {
			item.settle = <>

			</>
		}) */
		setResidentials(data);
	}, [data]);

	useEffect(() => {
		if (selected.length > 0) {
			setShowFooter(true);
		} else {
			setShowFooter(false);
		}
	}, [selected]);

	const onChangeFecha = (date, dateString) => {
		console.log(date, dateString);

		setStartDate(dateString[0]);
		setEndDate(dateString[1]);

		searchPending(dateString[0], dateString[1]);
	};

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log("selectedRows: ", selectedRows);
			selectedResidentialsFooter(selectedRows);
			const newSelectedBank =
				selectedRows.length > 0
					? selectedRows[0].financial_details.bankCode
					: null;
			setSelectedBank(newSelectedBank);
			setSelected(selectedRows);
		},
		getCheckboxProps: (record) => ({
			disabled:
				(selectedBank === "13" && record.financial_details.bankCode !== "13") ||
				(selectedBank !== "13" &&
					selectedBank !== null &&
					record.financial_details.bankCode === "13"),
		}),

		columnTitle: () => {},
	};

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const searchPending = async (start, end) => {
		var fechaI = new Date(moment(start, "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(end, "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
		searchPendingByDate(fechaIn, fechaFi);
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};
	const searchAccredited = async (start, end) => {
		var fechaI = new Date(moment(start, "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(end, "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
		searchAccreditedByDate(fechaIn, fechaFi);
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const handleSettleAcountModal = (statusModal, record = null) => {
		if (record != null) {
			setActiveResidential(record);
		}
		setTimeout(() => {
			setSettleAccountModalActive(statusModal);
		}, 50);
	};

	const onFinishForm = async (e) => {
		setLoadButton(true);
		console.log("start", startDate, "end", endDate);
		//Crear documento de deposito
		const settlement = {
			residentialId: activeResidential.id,
			impuesto: countAllTaxes(activeResidential.payments).toString(),
			comision: countAllComissions(activeResidential.payments).toString(),
			feeTopia: countAllTopiaFee(activeResidential.payments).toString(),
			total: countAllCharges(activeResidential.payments).toString(),
			liquidado: countAllLiquidations(activeResidential.payments)
				.toFixed(2)
				.toString(),
			fechaInicio: moment(startDate, "DD/MM/YYYY").toDate(),
			fechaFin: moment(endDate, "DD/MM/YYYY").toDate(),
			cuentaBancaria: activeResidential.financial_details.account,
			banco: activeResidential.financial_details.bank,
			refDeposito: e.number,
			fechaRegistro: moment(new Date(), "DD/MM/YYYY").toDate(),
		};
		console.log("deposit", settlement);
		const collectionRef = "payments_deposit";
		const createAgent = await onCreateOrUpdate(collectionRef, settlement);

		//editar pagos
		if (activeResidential.payments.length > 0) {
			activeResidential.payments.map(async (item, index) => {
				const id = item.id;
				const modifiedData = {
					payment_settle: true,
				};
				const collectionRef = `/payments/${id}`;
				const editPayment = await onUpdateDocWithRefInfo(
					collectionRef,
					modifiedData
				);
			});
		}

		setLoadButton(false);
		handleSettleAcountModal(false);
		searchPending(startDate, endDate);
		/* searchAccredited(startDate, endDate); */
		setActiveResidential([]);
		formRef.current.resetFields();
	};

	const countAllCharges = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			if (item.amount) {
				total += +parseFloat(item.amount).toFixed(2);
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllTaxes = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			if (item.payment_details) {
				total += +parseFloat(item.payment_details.taxFee).toFixed(2);
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllComissions = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			if (item.payment_details) {
				total += +parseFloat(item.payment_details.accFee).toFixed(2);
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllTopiaFee = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			if (item.payment_details) {
				total += +parseFloat(item.payment_details.topiaFee).toFixed(2);
			}
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllLiquidations = (transData) => {
		let total = 0;
		let totalDiscount = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.amount).toFixed(2);
			if (item.payment_details) {
				totalDiscount += +(
					item.payment_details.accFee +
					item.payment_details.taxFee +
					item.payment_details.topiaFee
				);
			}
		});
		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};

	const countSingleLiquidation = (record) => {
		let total = 0;
		let totalDiscount = 0;
		total = record.amount;
		totalDiscount += +(
			record.payment_details.accFee +
			record.payment_details.taxFee +
			record.payment_details.topiaFee
		);
		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};
	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	const onChangeSelector = (value, data) => {
		if (value === "all") {
			setResidentials(data);
		} else if (value === "clubs") {
			const newData = data.reduce(function (filtered, option) {
				if (option.id === "hspYWQ7JliJRQbeWx10N") {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setResidentials(newData);
		} else if (value === "priv_offices") {
			const newData = data.reduce(function (filtered, option) {
				if (
					option.id === "nqLvvhjKOJ8P6C08iGRk" ||
					option.id === "OwEoolPfoMXQCb6G0LLN"
				) {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setResidentials(newData);
		} else if (value === "residentials") {
			const newData = data.reduce(function (filtered, option) {
				if (
					option.id === "NzDZxRcxqRV2dHtDETj9" ||
					option.id === "bEytjH9pEGS4nsPKf05E" ||
					option.id === "ow5D60c2R0tMWX2m0kx4" ||
					option.id === "vLNhENQZIuDs6h58RAZK" ||
					option.id === "Yd5MV7jkA0MjQeCmzKrZ"
				) {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setResidentials(newData);
		} else if (value === "gym") {
			const newData = data.reduce(function (filtered, option) {
				if (option.id === "cfQ3edDuBHHB09DAeumQ") {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setResidentials(newData);
		}
	};

	const pendingColumns = [
		{
			title: "NOMBRE",
			dataIndex: "name",
			key: "name",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("name"),
			width: "20%",
		},
		{
			title: "LIQUIDAR",
			dataIndex: "settle",
			key: "settle",
			render: (id, record) => (
				<img
					className="iconoPerfil"
					src="/assets/icons/money_phone.svg"
					style={{ cursor: "pointer", height: "28px", width: "28px" }}
					alt="Topia"
					onClick={() => handleSettleAcountModal(true, record)}
				/>
			),
			align: "center",
			width: "8%",
		},
		{
			title: "IMPUESTOS",
			dataIndex: "taxes",
			key: "taxes",
			sorter: (a, b) => countAllTaxes(a.payments) - countAllTaxes(b.payments),
			render: (text, record) => (
				<>{currencyFormat(countAllTaxes(record.payments).toString())}</>
			),
			width: "8%",
		},
		{
			title: "COMISIÓN",
			dataIndex: "commission",
			key: "commission",
			sorter: (a, b) =>
				countAllComissions(a.payments) - countAllComissions(b.payments),
			render: (text, record) => (
				<>{currencyFormat(countAllComissions(record.payments).toString())}</>
			),
			width: "8%",
		},
		{
			title: "FEE TOPIA",
			dataIndex: "fee",
			key: "fee",
			sorter: (a, b) =>
				countAllTopiaFee(a.payments) - countAllTopiaFee(b.payments),
			render: (text, record) => (
				<>{currencyFormat(countAllTopiaFee(record.payments).toString())}</>
			),
			width: "8%",
		},
		{
			title: "TOTAL",
			dataIndex: "total",
			key: "total",
			onCell: (record) => ({ className: "darkText" }),
			sorter: (a, b) =>
				countAllCharges(a.payments) - countAllCharges(b.payments),
			render: (text, record) => (
				<>{currencyFormat(countAllCharges(record.payments).toString())}</>
			),
			width: "8%",
		},
		{
			title: "REINTEGRAR",
			dataIndex: "reiny",
			key: "reiny",
			onCell: (record) => ({ className: "greenText" }),
			sorter: (a, b) =>
				countAllLiquidations(a.payments).toFixed(2) -
				countAllLiquidations(b.payments).toFixed(2),
			render: (text, record) => (
				<>
					{currencyFormat(
						countAllLiquidations(record.payments).toFixed(2).toString()
					)}
				</>
			),
			width: "8%",
		},
		{
			title: "N° DE CUENTA",
			dataIndex: "financial_details.account",
			key: "financial_details.account",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
			render: (text, record) => (
				<>
					{record.financial_details ? record.financial_details.account : "N/A"}
				</>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<div style={{ padding: 8 }}>
							<Input
								autoFocus
								placeholder=""
								value={selectedKeys[0]}
								onChange={(e) => {
									setSelectedKeys(e.target.value ? [e.target.value] : []);
									confirm({ closeDropdown: false });
								}}
								onPressEnter={() => {
									confirm();
								}}
								onBlur={() => {
									confirm();
								}}
								style={{ marginBottom: 8, display: "block" }}
							></Input>
							<Space>
								<Button
									icon={<SearchOutlined />}
									onClick={() => {
										confirm();
									}}
									type="primary"
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => {
										clearFilters();
									}}
									size="small"
									style={{ width: 90 }}
								>
									Limpiar
								</Button>
							</Space>
						</div>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.financial_details?.account
					.toLowerCase()
					.includes(value.toLowerCase());
			},
			width: "12%",
		},
		{
			title: "BANCO",
			dataIndex: "financial_details.bank",
			key: "financial_details.bank",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
			render: (text, record) => (
				<>{record.financial_details ? record.financial_details.bank : "N/A"}</>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => {
				return (
					<>
						<div style={{ padding: 8 }}>
							<Input
								autoFocus
								placeholder=""
								value={selectedKeys[0]}
								onChange={(e) => {
									setSelectedKeys(e.target.value ? [e.target.value] : []);
									confirm({ closeDropdown: false });
								}}
								onPressEnter={() => {
									confirm();
								}}
								onBlur={() => {
									confirm();
								}}
								style={{ marginBottom: 8, display: "block" }}
							></Input>
							<Space>
								<Button
									icon={<SearchOutlined />}
									onClick={() => {
										confirm();
									}}
									type="primary"
									size="small"
									style={{ width: 90 }}
								>
									Buscar
								</Button>
								<Button
									onClick={() => {
										clearFilters();
									}}
									size="small"
									style={{ width: 90 }}
								>
									Limpiar
								</Button>
							</Space>
						</div>
					</>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			onFilter: (value, record) => {
				return record.financial_details?.bank
					.toLowerCase()
					.includes(value.toLowerCase());
			},
			width: "12%",
		},
		{
			title: "DETALLE",
			dataIndex: "details",
			key: "details",
			render: (id, record) => (
				<img
					className="iconoPerfil"
					src="/assets/icons/details.svg"
					style={{ cursor: "pointer", height: "26px", width: "26px" }}
					alt="Topia"
					onClick={() => {
						record.link &&
							navigate(`${record.link}`, {
								replace: false,
							});
					}}
				/>
			),

			width: "8%",
		},
	];

	return (
		<>
			{console.log("pending", residentials)}
			<Row>
				<Col span={24} style={{ marginLeft: "2rem" }}>
					<Space>
						<Select
							defaultValue={{
								value: "all",
								label: "Todos",
							}}
							placeholder="Tipo de cuenta"
							className="select_style"
							style={{ width: "261px" }}
							options={[
								{
									value: "all",
									label: "Todos",
								},
								{
									value: "clubs",
									label: "Clubes",
								},
								{
									value: "priv_offices",
									label: "Oficinas privadas",
								},
								{
									value: "residentials",
									label: "Residenciales",
								},
								{
									value: "gym",
									label: "Gimnasios",
								},
							]}
							onChange={(e) => {
								onChangeSelector(e, data);
							}}
						></Select>
						<RangePicker
							defaultValue={[
								moment(new Date(), dateFormat),
								moment(new Date(), dateFormat),
							]}
							format={customFormat}
							style={{
								width: "270px",
							}}
							onCalendarChange={onChangeFecha}
						/>
					</Space>
				</Col>
				<Col span={24} style={{ marginTop: "3rem" }}>
					<Table
						rowSelection={{
							type: "checkbox",
							...rowSelection,
						}}
						dataSource={residentials}
						className="primary-table"
						columns={pendingColumns}
						pagination={{ defaultPageSize: 10, simple: true }}
						loading="true"
						rowKey="id"
						scroll={{ x: "auto", y: "calc(100vh - 19em)" }}
					/>
				</Col>
			</Row>

			<Modal
				className="primary-modal"
				visible={settleAccountModalActive}
				onCancel={() => handleSettleAcountModal(false)}
				footer={null}
				width={550}
			>
				{activeResidential ? (
					<>
						<Row
							className="section-modal"
							style={{
								textAlign: "center",
								borderBottom: "1 px solid #CFCFCF",
							}}
						>
							{" "}
							{console.log("active", activeResidential)}
							<Col span={24}>
								<h2 style={{ color: "#232C4A" }}>LIQUIDAR CUENTA</h2>
							</Col>
						</Row>
						<Row className="section-modal" style={{ marginTop: "1rem" }}>
							<Col span={4}></Col>
							<Col span={16}>
								<Form
									ref={formRef}
									name="settle_account_form"
									onFinish={onFinishForm}
								>
									<div className="section-modal" style={{ paddingLeft: "5px" }}>
										<p>NÚMERO DE REFERENCIA BANCARIA</p>
									</div>
									<Form.Item name="number" {...config}>
										<Input
											maxLength={50}
											placeholder="Ingresar número de referencia bancaria"
										/>
									</Form.Item>

									<Row>
										<Col
											span={24}
											align="middle"
											style={{ marginTop: "1rem", marginBottom: "2rem" }}
										>
											<Button
												htmlType="submit"
												style={{
													borderColor: "#10b48c",
													background: "#10b48c",
													paddingBottom: "15px",
													width: "250px",
												}}
												shape="round"
												size="large"
												loading={loadButton}
											>
												<span className="title" style={{ color: "#fff" }}>
													ACREDITAR
												</span>
											</Button>
										</Col>
									</Row>
								</Form>
							</Col>
							<Col span={4}></Col>
						</Row>
					</>
				) : null}
			</Modal>
		</>
	);
};
