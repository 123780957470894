import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import moment from 'moment';

export const adminCobrosSlice = createSlice({
    name: 'adminCobros',
    initialState: {
        adminCobros: {
            data: [],
            active: null
        },
        errorMessage: null
    },
    reducers: {
        setAdminCobro: ( state, { payload } ) => {
            state.adminCobros.data = payload;
        },
        setAdminUserResidentes: ( state, { payload } ) => {
            state.adminCobros.userResidentes = payload;
        },
        createAdminCobro: ( state, { payload } ) => {
            console.log("payload",payload)
            var adminCobrosList = state.adminCobros.data

            payload.created_at = payload.created_at == null ? "" : moment(payload.created_at).format("DD-MM-Y")
            payload.update_at = payload.update_at == null ? "" : moment(payload.update_at).format("DD-MM-Y")
            payload.dateEmision = payload.dateEmision == null ? "" : moment(payload.dateEmision).format("DD-MM-Y")
            payload.dateVencimiento = payload.dateVencimiento == null ? "" : moment(payload.dateVencimiento).format("DD-MM-Y")
          

            
            var indexEncuesta = adminCobrosList.findIndex((item) => item.id == payload.id)
            console.log("itemEncuesta",indexEncuesta)
            if(indexEncuesta != -1){
                adminCobrosList[indexEncuesta] = payload
                state.surveys.data = adminCobrosList
                console.log("esto es una actualizacion",adminCobrosList[indexEncuesta])
            }else{
                state.adminCobros.data.unshift(payload);
            }

            

            //state.surveys.data.unshift(payload);
            toast.success("Cobro guardada");
        },
        setActiveAdminCobro: (state, { payload })=>{
            state.adminCobros.active = payload
        }
    }
});


export const { 
    createAdminCobro,
    setAdminCobro,
    setAdminUserResidentes,
    setActiveAdminCobro,
} = adminCobrosSlice.actions;