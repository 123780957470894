import React from "react";
import {
	Col,
	Modal,
	Row,
	Table,
	Button,
	Switch,
	Form,
	Input,
	Radio,
	Space,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	setUserActive,
	setUserActiveVehicles,
} from "../../../../store/users/usersSlice";
import {
	getUsersAprobar,
	subirArchivo,
	guardarCasa,
	crearZonaTuscania,
	guardarUser,
	relacionarResidencial,
} from "../../../../store/users/thunks";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "../../../../utils/tables";
import { getQueryDocByCollection } from "../../../../helpers/globalHelper";
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;

export const UsersTab = () => {
	const [usersList, setUsersList] = useState([]);
	const dispatch = useDispatch();
	const { users } = useSelector((state) => state.users);
	const [userModalActive, setUserModalActive] = useState(false);
	const [dataExcelCuotas, setDataExcelCuotas] = useState(null);
	const [dataExcelContactos, setDataExcelContactos] = useState(null);
	const [modalExcelCuotas, setModalExcelCuotas] = useState(null);
	const [modalExcelContactos, setModalExcelContactos] = useState(null);
	const [sortedInfo, setSortedInfo] = useState({});
	const [filteredInfo, setFilteredInfo] = useState({});

	const [admonishModalActive, setAdmonishModalActive] = useState(false);
	const [eliminateModalActive, setEliminateModalActive] = useState(false);
	const [userActivationModalActive, setUserActivationModalActive] =
		useState(false);

	const IconButtonReport = () => <img src="/assets/images/shape25.png" />;

	const handleChange = (pagination, filters, sorter) => {
		console.log("Various parameters", pagination, filters, sorter);
		setFilteredInfo(filters);
		setSortedInfo(sorter);
	};

	const [userActive, seUserActive] = useState(null);

	useEffect(() => {
		dispatch(getUsersAprobar());
	}, [dispatch]);

	useEffect(() => {
		seUserActive(users.active);
		if (!users.active) {
			handleStatusResidentsModal(false);
		}
	}, [users.active]);

	useEffect(() => {
		let _data = users.data.map((item, index) => {
			let data = { ...item };
			data.profile = (
				<img
					className="iconPerfil"
					src="/assets/images/shape08.png"
					style={{ cursor: "pointer" }}
					alt="Topia"
					onClick={() => handleStatusResidentsModal(true, index)}
				/>
			);
			data.activity = (
				<Link to={`/reporte-actividad/${data.id}`}>
					<img
						src="/assets/images/shape15.png"
						style={{ cursor: "pointer" }}
						alt="Topia"
					/>
				</Link>
			);

			return data;
		});

		setUsersList(_data);
	}, [users.data]);

	const handleStatusResidentsModal = async (statusModal, index = null) => {
		if (index != null) {
			let active_user = users.data[index];
			if (active_user.ownerHome) {
				// Agregamosal detalle los dependientes de los socios
				let relativesWithAccess = await getQueryDocByCollection(
					"/family_access",
					"idProfileMain",
					"==",
					active_user.id
				);
				//console.log(relativesWithAccess);
				active_user = {
					...active_user,
					relativesWithAccess: relativesWithAccess,
				};
			}
			dispatch(setUserActive(users.data[index]));
		} else {
			dispatch(setUserActive(null));
		}

		setTimeout(() => {
			setUserModalActive(statusModal);
		}, 50);
	};

	//Modal functions
	const handleAdmonishModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setAdmonishModalActive(statusModal);
		}, 50);
	};

	const handleEliminateModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setEliminateModalActive(statusModal);
		}, 50);
	};

	const handleActivateModal = async (statusModal, index = null) => {
		setTimeout(() => {
			setUserActivationModalActive(statusModal);
		}, 50);
	};

	//Form functions
	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	const onFinishAdmonishForm = (e) => {
		const admonishDescription = e.admonishDescription;
		console.log("form", admonishDescription);
	};

	const onFinishEliminateForm = (e) => {
		const eliminateOptions = {
			description: e.eliminateDescription,
			option: e.eliminateOption,
		};
		console.log("form", e);
	};

	const onFinishActivateForm = (e) => {
		const activateDescription = e.activateDescription;
		console.log("form", activateDescription);
	};

	const navigate = useNavigate();

	const asignarResidenciales = async () => {
		//obtener residenciales
		//console.log("entro!!!!",usersList.length)
		/*for( var z = 0; z < usersList.length; z++){
            //console.log(usersList[z].residentials)
            var residentials =  usersList[z].residentials
            for( var j = 0; j < residentials.length; j++){
              //if(z == 0){
                console.log("aqui",residentials[j].house.path)
                dispatch( relacionarResidencial(residentials[j].house.path,usersList[z].id) )
              //}
             

            }
            
        }*/
	};

	const onChange = (e) => {
		const [file] = e.target.files;
		const fileSubir = e.target.files[1];

		/*var dataZona = {
        active: true,
        created_at:moment(new Date(), 'DD/MM/YYYY').toDate(),
        end_house: 3,
        name:"Valle Tuscania Poligono I",
        number_residential: 3,
        start_house: 1,
        updated_at: moment(new Date(), 'DD/MM/YYYY').toDate()
      }

      dispatch( crearZonaTuscania(dataZona) )*/

		//dispatch( subirArchivo(fileSubir) );

		const reader = new FileReader();

		reader.onload = (evt) => {
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: "binary" });

			//segunda hoja
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

			//registro de casa
			/*var dataCasa = []
        var c = 1;
        var casaAnterior = ""
        while (c < data.length) {
          
          var item = data[c]
          if(item != ""){
            if(casaAnterior != item[2]){
              var dataCasa = {
                active: true,
                name: item[2]+"",
                id_profile_titular: "",
                titular_name: "",
                use_titular: "",
                address: item[13] == undefined ? "" : item[13],
                areaM2: item[15] == undefined ? 0 : item[15],
                areaV2: item[16] == undefined ? 0 : item[16],
                category: item[17] == undefined ? 0 : item[17],
                maintenanceFee: item[18] == undefined ? 0 : item[18],
                constructionFee: item[19] == undefined ? 0 : item[19],
                residenceFee: item[20] == undefined ? 0 : item[20],
                total: item[22] == undefined ? 0 : item[22],
                alcaldia: item[21] == undefined ? 0 : item[21],
                observations: item[23] == undefined ? "" : item[23],
              }

              //console.log("dataCasa",dataCasa)

              dispatch( guardarCasa(dataCasa) )
            }else{
              console.log("nada")
            }

            casaAnterior = item[2]
            
          }
          
          ++c;
        }*/

			//registro de casa hoja 2
			/*var dataCasa = []
        var c = 1;
        var casaAnterior = ""
        var contadorPoligonos = 1

        while (c < data.length) {
          
          var item = data[c]
          if(item != ""){
            if(casaAnterior != item[1]){

              if(item[0] == "poligono"){
                contadorPoligonos++
              }

              if(contadorPoligonos == 10){
                var dataCasa = {
                  active: true,
                  name: item[1]+"",
                  id_profile_titular: "",
                  titular_name: "",
                  use_titular: "",
                  address: item[13] == undefined ? "" : item[13],
                  areaM2: item[15] == undefined ? 0 : item[15],
                  areaV2: item[16] == undefined ? 0 : item[16],
                  category: item[17] == undefined ? 0 : item[17],
                  maintenanceFee: item[18] == undefined ? 0 : item[18],
                  constructionFee: item[19] == undefined ? 0 : item[19],
                  residenceFee: item[20] == undefined ? 0 : item[20],
                  total: item[22] == undefined ? 0 : item[22],
                  alcaldia: item[21] == undefined ? 0 : item[21],
                  observations: item[23] == undefined ? "" : item[23],
                }
  
                //console.log("dataCasa",dataCasa)
                dispatch( guardarCasa(dataCasa) )
              }

            }else{
              console.log("nada")
            }

            casaAnterior = item[1]
            
          }
          
          ++c;
        }*/

			//console.log("dataCasa",dataCasa)

			//registro User
			var dataUser = [];
			var j = 1;
			var contadorPoligonos = 1;
			while (j < data.length) {
				//console.log(data[i] == "");
				var item = data[j];
				if (item != "") {
					/*if(item[0] != undefined){
              //villa
              var userSave ={
                active: true,
                area: 503,
                code: "SV",
                created_at:moment(new Date(), 'DD/MM/YYYY').toDate(),
                updated_at:moment(new Date(), 'DD/MM/YYYY').toDate(),
                email: item[10] == undefined ? "" : item[10],
                emailTwo: item[11] == undefined ? "" : item[11],
                image: "",
                imageDUI: "",
                mora: 0,
                name: item[2],
                lastname: item[3] == undefined ? "" : item[3],
                phone: item[6] == undefined ? "" : item[6]+"",
                phoneTwo: item[7] == undefined ? "" : item[7]+"",
                phoneThree: item[8] == undefined ? "" : item[8]+"",
                phoneFour: item[9] == undefined ? "" : item[9]+"",
                residentials: [{homePrincipal:true,house:"/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/3B0vUfiKpRYJmT8HYwWZ/houses/"+item[23],idResidential:"Yd5MV7jkA0MjQeCmzKrZ",type:item[4] == undefined ? "" : item[4]}],
                residentialsIds: ["Yd5MV7jkA0MjQeCmzKrZ"],
                stateDUI: 0,
                tokenUserTas: "",
                verified: true,
  
              }

              dispatch( guardarUser(userSave,item[0]) )
            }*/

					//valle

					if (item[0] != undefined) {
						if (item[0] == "poligono") {
							contadorPoligonos++;
						}

						if (contadorPoligonos == 10) {
							var userSave = {
								active: true,
								area: 503,
								code: "SV",
								created_at: moment(new Date(), "DD/MM/YYYY").toDate(),
								updated_at: moment(new Date(), "DD/MM/YYYY").toDate(),
								email: item[11] == undefined ? "" : item[11],
								emailTwo: item[12] == undefined ? "" : item[12],
								image: "",
								imageDUI: "",
								mora: 0,
								name: item[3],
								lastname: item[4] == undefined ? "" : item[4],
								phone: item[7] == undefined ? "" : item[7] + "",
								phoneTwo: item[8] == undefined ? "" : item[8],
								phoneThree: item[9] == undefined ? "" : item[9],
								phoneFour: item[10] == undefined ? "" : item[10],
								residentials: [
									{
										homePrincipal: true,
										house:
											"/residentials/Yd5MV7jkA0MjQeCmzKrZ/nomenclature/mn4v2eDGxAqfRmr9SqwE/houses/" +
											item[24],
										idResidential: "Yd5MV7jkA0MjQeCmzKrZ",
										type: item[5] == undefined ? "" : item[5],
									},
								],
								residentialsIds: ["Yd5MV7jkA0MjQeCmzKrZ"],
								stateDUI: 0,
								tokenUserTas: "",
								verified: true,
							};

							//console.log("userSave",userSave)
							dispatch(guardarUser(userSave, item[0]));
						}
					}

					//console.log("userSave",userSave)
					//if(j == 1){
					//dispatch( guardarUser(userSave,item[0]) )
					//}
				}

				++j;
			}

			/*var dataContactos = []
        var i = 1;
        while (i < data.length) {
          //console.log(data[i] == "");
          var item = data[i]
          if(item != ""){
            dataContactos.push({
              idUser: item[0],
              lote: item[1],
              poligono: item[2],
              propietario: item[3],
              comentario: item[4],
              telefono: item[5],
              celular: item[6],
              email: item[7],
              address: item[8],
            })
          }
          
          ++i;
        }*/

			//console.log("dataContactos",dataContactos)

			//primera hoja
			/*const wsnamePrimero = wb.SheetNames[0];
      const wsPrimero = wb.Sheets[wsnamePrimero];
      const dataPrimero = XLSX.utils.sheet_to_json(wsPrimero, { header: 1 });
      var dataCuotas = []
      var j = 2;
      while (j < dataPrimero.length) {
        console.log(dataPrimero[j] == "");
        var item = dataPrimero[j]
        if(item != ""){
          dataCuotas.push({
            comentarios: item[0],
            nombreCliente: item[2],
            dui: item[3],
            nit: item[4],
            firmaCompraventa: item[5],
            terreno: item[6],
            poligono: item[7],
            aream: item[8],
            areav: item[9],
            categoria: item[10],
            cuotaMantenimiento: item[11],
            cuotaConstruccion: item[12],
            cuotaResidencia: item[13],
            tasaRecoleccion: item[14],
            total: item[15]
          })
        }
        
        ++j;
      }*/

			//setDataExcelContactos(dataContactos)
			//setDataExcelCuotas(dataCuotas)
			//setModalExcelContactos(true)
			e.target.value = null;

			//dataExcelContactos
		};
		reader.readAsBinaryString(file);
	};

	const columnsUsuarios = [
		{
			title: "Id User",
			dataIndex: "idUser",
			key: "idUser",
		},
		{
			title: "No. Lote",
			dataIndex: "lote",
			key: "lote",
		},
		{
			title: "Nombre",
			dataIndex: "propietario",
			key: "propietario",
		},
		{
			title: "Apellido",
			dataIndex: "apellido",
			key: "apellido",
		},
		{
			title: "Tipo",
			dataIndex: "tipo",
			key: "tipo",
		},
		{
			title: "Calificacion",
			dataIndex: "calificacion",
			key: "calificacion",
		},
		{
			title: "Celular 1",
			dataIndex: "celularUno",
			key: "celularUno",
		},
		{
			title: "Celular 2",
			dataIndex: "celularDos",
			key: "celularDos",
		},
		{
			title: "Telefono 1",
			dataIndex: "telefonoUno",
			key: "telefonoUno",
		},
		{
			title: "Telefono 2",
			dataIndex: "telefonoDos",
			key: "telefonoDos",
		},
		{
			title: "E-mail",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "E-mail 1",
			dataIndex: "emailDos",
			key: "emailDos",
		},
		{
			title: "Dirección",
			dataIndex: "address",
			key: "address",
		},
	];

	const columnsCuotas = [
		{
			title: "Comentarios",
			dataIndex: "comentarios",
			key: "comentarios",
		},
		{
			title: "Nombre del Cliente",
			dataIndex: "nombreCliente",
			key: "nombreCliente",
		},
		{
			title: "DUI",
			dataIndex: "dui",
			key: "dui",
		},
		{
			title: "NIT",
			dataIndex: "nit",
			key: "nit",
		},
		{
			title: "Fecha firma de compraventa",
			dataIndex: "firmaCompraventa",
			key: "firmaCompraventa",
		},
		{
			title: "No Terreno",
			dataIndex: "terreno",
			key: "terreno",
		},
		{
			title: "Poligono",
			dataIndex: "poligono",
			key: "poligono",
		},
		{
			title: "Area (M2)",
			dataIndex: "aream",
			key: "aream",
		},
		{
			title: "Area (V2)",
			dataIndex: "areav",
			key: "areav",
		},
		{
			title: "Categoría",
			dataIndex: "categoria",
			key: "categoria",
		},
		{
			title: "Cuota de mantenimiento solo terreno",
			dataIndex: "cuotaMantenimiento",
			key: "cuotaMantenimiento",
		},
		{
			title: "Cuota de mantenimiento construccion",
			dataIndex: "cuotaConstruccion",
			key: "cuotaConstruccion",
		},
		{
			title: "Cuota de mantenimiento con residencia",
			dataIndex: "cuotaResidencia",
			key: "cuotaResidencia",
		},
		{
			title: "Tasa recolección alcaldia Zaragoza",
			dataIndex: "tasaRecoleccion",
			key: "tasaRecoleccion",
		},
		{
			title: "Total cobrar",
			dataIndex: "total",
			key: "total",
		},
	];

	const getUserName = () => {
		const localities = [];
		usersList.map((d) => {
			localities.push({
				text: d.name,
				value: d.name,
			});
		});
		return localities;
	};

	const columns = [
		/* {
			title: "ID Socio",
			dataIndex: "origin_fam",
			key: "origin_fam",
			width: "15%",
			...getColumnSearchProps("origin_fam"),
		}, */
		{
			title: "Nombre",
			dataIndex: "name",
			key: "name",
			width: "50%",
			render: (text, record) => (
				<>
					{record.name} {record.lastname}
				</>
			),
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			...getColumnSearchProps("name"),
			//filters: getUserName(),
			//filteredValue: filteredInfo.name || null,
			//onFilter: (value, record) => record.name.includes(value),
			//sorter: (a, b) => a.name.length - b.name.length,
			//sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
			//ellipsis: true,
		},
		/* {
			title: "Residencia",
			dataIndex: "address",
			key: "address",
			width: "15%",
		}, */
		{
			title: "Teléfono",
			dataIndex: "phone",
			key: "phone",
			width: "20%",
		},
		{
			title: "Perfil",
			dataIndex: "profile",
			key: "profile",
			width: "15%",
		},
		{
			title: "Actividad",
			dataIndex: "activity",
			key: "activity",
			width: "15%",
		},
	];

	return (
		<>
			<div className="contSubir">
				<span>Subir Información</span>
				<input
					type="file"
					accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					onChange={onChange}
				/>
			</div>
			<div>
				<Table
					pagination={{ pageSize: 100, simple: true }}
					scroll={{ y: "calc(100vh - 19em)" }}
					className="primary-table"
					onChange={handleChange}
					columns={columns}
					dataSource={usersList}
				/>
			</div>

			<Modal
				className="side-modal"
				title="Datos de usuario"
				style={{ top: 0, right: 0 }}
				visible={userModalActive}
				onCancel={() => handleStatusResidentsModal(false)}
				bodyStyle={{ overflowY: "auto", maxHeight: "calc(150vh - 120px)" }}
				footer={
					<Row>
						<Col span={6}></Col>
						<Col span={6}></Col>
						<Col span={6}></Col>
						<Col span={6}>
							<Button
								style={{
									color: "#10b48c",
									borderColor: "#10b48c",
									background: "white",
								}}
								onClick={() => {
									handleEliminateModal(true);
								}}
							>
								<span className="title">Eliminar usuario</span>
							</Button>
						</Col>
					</Row>
				}
				width={600}
			>
				{console.log("usuario modal", userActive)}
				{userActive ? (
					<>
						<div
							className="section-modal-active"
							style={{ marginBottom: "0px" }}
						>
							<p>Activo</p>
							<Switch
								defaultChecked
								style={{ backgroundColor: "#16A07F" }}
								onChange={(checked) => {
									checked
										? //CHECAR ESTADO, usar handle con if
										  console.log("reactivar usuario", checked)
										: handleActivateModal(true);
								}}
							/>
						</div>
						<Row className="section-modal" style={{ paddingTop: "0px" }}>
							<Col span={24} style={{ paddingLeft: "20px" }}>
								<div className="user-data">
									{userActive.image ? (
										<div
											style={{
												backgroundImage: `url(` + userActive.image + `)`,
											}}
											className="user-image avatarUser"
											src={userActive.image}
											alt="Topia"
										/>
									) : (
										<img
											className="user-image"
											src="/assets/images/avatar_user.png"
											alt="Topia"
										/>
									)}

									<div className="user-info" style={{ paddingLeft: "20px" }}>
										<span className="user-name">
											{userActive.name} {userActive.lastname}
										</span>
										<span
											className="user-address"
											style={{ paddingTop: "5px", paddingBottom: "5px" }}
										>
											{" Junta directiva: "}
											{userActive.role_id ? userActive.role_id : "N/A"}
										</span>
										{/* <span className="user-address">
											{" "}
											{userActive?.address}{" "}
										</span> */}
										<div style={{ display: "flex" }}>
											<span
												style={{
													color: "#10B48C",
													display: "flex",
													alignItems: "flex-start",
													margin: "0px",
												}}
											>
												<img
													className="iconoPerfil"
													src="/assets/images/phone.png"
													alt="Topia"
												/>

												<span style={{ marginLeft: "5x" }}>
													{userActive.phone ? userActive.phone : ""}
												</span>
											</span>

											<span className="contEmail">
												<img
													className="iconoPerfil"
													src="/assets/images/mensaje.png"
													alt="Topia"
												/>
												<div className="contEmails">
													<span className="emails">{userActive.email}</span>
													<span className="emails">{userActive.emailTwo}</span>
												</div>
											</span>
										</div>
									</div>
								</div>
							</Col>
							<Col span={24}>
								<div>
									<img
										style={{ marginLeft: "12px" }}
										src="/assets/images/shape26.png"
										alt="Topia"
									/>
									<img src="/assets/images/shape27.png" alt="Topia" />
									<img src="/assets/images/shape27.png" alt="Topia" />
								</div>
							</Col>
							<Col span={24}>
								<div style={{ paddingTop: "15px", paddingLeft: "0px" }}>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
										}}
										size="small"
										shape="round"
										onClick={() => {
											handleAdmonishModal(true);
										}}
									>
										<span className="title"> Amonestar</span>
									</Button>
								</div>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={24}>
								<div className="alert-modal-header">
									<Row>
										<Col span={8}>
											<span className="title" style={{ fontSize: "10px" }}>
												<img
													style={{ marginRight: "10px", display: "inline" }}
													src="/assets/images/shape17.png"
													alt="Topia"
												/>
												Tipo de residente
											</span>
											<div className="alert-modal-text">
												<p>
													{userActive.ownerHome
														? "Propietario"
														: "Familiar o amigo"}
												</p>
											</div>
										</Col>

										<Col span={16}>
											<span className="title" style={{ fontSize: "10px" }}>
												<img
													style={{ marginRight: "10px", display: "inline" }}
													src="/assets/images/shape18.png"
													alt="Topia"
												/>
												Dirección de residencia
											</span>
											{/* <p>{userActive.listOfResidentialsData[0]?.address}</p> */}
										</Col>
										{userActive.vehicles_list ? (
											userActive.vehicles_list_data.map((item, key) => {
												return (
													<Col span={6} key={key}>
														<span
															className="title"
															style={{ fontSize: "10px" }}
														>
															<img
																style={{
																	marginRight: "10px",
																	display: "inline",
																}}
																src="/assets/images/shape19.png"
																alt="Topia"
															/>
															Auto {key + 1}
														</span>
														<p>
															{item.model} ({item.color}) <br />
															{item.vehicle}
														</p>
													</Col>
												);
											})
										) : (
											<Col span={6}>
												<span className="title" style={{ fontSize: "10px" }}>
													<img
														style={{ marginRight: "10px", display: "inline" }}
														src="/assets/images/shape19.png"
														alt="Topia"
													/>
													Auto:
												</span>
												<div
													className="alert-modal-text"
													style={{ marginLeft: "5px" }}
												>
													<p>N/A</p>
												</div>
											</Col>
										)}
										<Col span={18} style={{ paddingLeft: "100px" }}>
											<Button
												style={{
													color: "#10b48c",
													borderColor: "#10b48c",
													background: "white",
												}}
												shape="round"
												icon={<IconButtonReport />}
												onClick={() => {
													navigate(`/reporte-actividad/${userActive.id}`, {
														replace: false,
													});
												}}
											>
												<span className="title" style={{ marginLeft: "10px" }}>
													{" "}
													REPORTE DE ACTIVIDAD
												</span>
											</Button>
										</Col>
									</Row>

									<Row>
										{userActive.vehicles_list_data.map((item, key) => {
											return (
												<Col span={12} key={key}>
													<span className="title" style={{ fontSize: "10px" }}>
														<img
															style={{ marginRight: "10px", display: "inline" }}
															src="/assets/images/shape19.png"
															alt="Topia"
														/>
														Auto {key + 1}
													</span>
													<p>
														{item.model} ({item.color}) <br />
														{item.vehicle}
													</p>
												</Col>
											);
										})}
									</Row>
								</div>
							</Col>
						</Row>

						<Row className="section-modal">
							<Col span={24}>
								<div className="alert-modal-header">
									<p
										className="user-name"
										style={{ textTransform: "uppercase" }}
									>
										Familiares con acceso
									</p>
								</div>
							</Col>

							{userActive.relativesWithAccess ? (
								<div style={{ marginLeft: "20px" }}>
									<Row>
										{userActive.relativesWithAccess.map((relative, key) => {
											return (
												<Col span={12} key={key}>
													<span style={{ color: "#10B48C", fontSize: "12px" }}>
														{relative.name} ({relative.relationship})
													</span>
												</Col>
											);
										})}
									</Row>
								</div>
							) : (
								<>
									<Col span={24} style={{ textAlign: "center" }}>
										<b>Socios dependientes no encontrados.</b>
									</Col>
								</>
							)}
						</Row>

						{/* {userActive.relativesWithAccess.length > 0 && (
							<Row className="section-modal">
								<Col span={24}>
									<h3
										style={{
											textTransform: "uppercase",
											color: "#232C4A",
											fontSize: "14px",
										}}
									>
										Familiares con acceso
									</h3>

									<div style={{ marginLeft: "20px" }}>
										<Row>
											{userActive.relativesWithAccess.map((relative, key) => {
												return (
													<Col span={12} key={key}>
														<span
															style={{ color: "#10B48C", fontSize: "12px" }}
														>
															{relative.name} ({relative.relationship})
														</span>
													</Col>
												);
											})}
										</Row>
									</div>
								</Col>
							</Row>
						)} */}

						<Row className="section-modal">
							<Col span={12}>
								<div className="alert-modal-header">
									<p
										className="user-name"
										style={{ textTransform: "uppercase" }}
									>
										Pagos
									</p>
								</div>
							</Col>
							<Col span={12}>
								<div className="alert-modal-header">
									<p
										className="user-name"
										style={{
											textTransform: "uppercase",
											borderBottom: "dashed",
											borderWidth: "1px",
										}}
									>
										Saldo en mora:
									</p>
								</div>
								<div
									className="alert-modal-text"
									style={{
										paddingTop: "1rem",
									}}
								>
									<p>
										Mora total: ${userActive.mora}
										<img
											src="/assets/icons/reporte.svg"
											alt="Topia"
											style={{ marginLeft: "5px" }} //Mofificar cuando se agregue el estado de cuenta
										/>
									</p>
									<Button
										style={{
											color: "#10b48c",
											borderColor: "#10b48c",
											background: "white",
											marginLeft: "3rem",
										}}
										shape="round"
									>
										<span className="title">Enviar recordatorio</span>
									</Button>
								</div>
							</Col>
						</Row>
					</>
				) : (
					<>
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					</>
				)}
			</Modal>

			{/* MODAL PARA AMONESTAR */}

			<Modal
				className="primary-modal"
				title="AMONESTACIÓN"
				visible={admonishModalActive}
				onCancel={() => handleAdmonishModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userActive ? (
						<Form name="admonish_user_form" onFinish={onFinishAdmonishForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<span className="title">MOTIVO DE LA AMONESTACIÓN:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="admonishDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS AMONESTAR A {userActive.name}{" "}
											{userActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}
											className="btn-primary"
											type="primary"
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => {}}
										>
											APLICAR AMONESTACIÓN
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>

			{/* MODAL PARA ELIMINAR */}

			<Modal
				className="primary-modal"
				title="ELIMINAR USUARIO"
				visible={eliminateModalActive}
				onCancel={() => handleEliminateModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userActive ? (
						<Form name="eliminate_user_form" onFinish={onFinishEliminateForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<FormItem name="eliminateOption" {...config}>
											<Radio.Group>
												<Space direction="vertical">
													<Radio style={{ fontSize: "14px" }} value={1}>
														Eliminar cuenta principal y cuentas de familiares
													</Radio>
													<Radio style={{ fontSize: "14px" }} value={2}>
														Eliminar únicamente el usuario y reasignar cuenta
														principal
													</Radio>
												</Space>
											</Radio.Group>
										</FormItem>
									</div>
									<div className="alert-modal-header">
										<span className="title">MOTIVO:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="eliminateDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
										<FormItem name="id" hidden={true}>
											{userActive.id}
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS ELIMINAR LA CUENTA DE {userActive.name}{" "}
											{userActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}

											type="primary"
											danger
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => {}}
										>
											ELIMINAR
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>

			{/* MODAL PARA DESACTIVAR/ACTIVAR */}

			<Modal
				className="primary-modal"
				title="ACTIVAR/DESACTIVAR USUARIO TEMPORALMENTE"
				visible={userActivationModalActive}
				onCancel={() => handleActivateModal(false)}
				width={600}
				footer={null}
			>
				<>
					{userActive ? (
						<Form name="admonish_user_form" onFinish={onFinishActivateForm}>
							<Row className="section-modal" style={{ fontSize: "14px" }}>
								<Col span={24}>
									<div className="alert-modal-header">
										<span className="title">MOTIVO DE LA DESACTIVACIÓN:</span>
									</div>
									<div
										className="alert-modal-header"
										style={{ marginTop: "5px" }}
									>
										<FormItem name="admonishDescription" {...config}>
											<TextArea rows={4} />
										</FormItem>
									</div>
									<div
										className="alert-modal-header"
										style={{
											textAlign: "center",
											textTransform: "uppercase",
											marginTop: "50px",
										}}
									>
										<span className="title">
											¿REALMENTE DESEAS DESACTIVAR A {userActive.name}{" "}
											{userActive.lastname}?
										</span>
									</div>
								</Col>
								<Col span={7}></Col>
								<Col span={10} style={{ marginTop: "20px" }}>
									<FormItem>
										<Button
											// disabled={requests.isSaving}
											className="btn-primary"
											type="primary"
											htmlType="submit"
											shape="round"
											style={{ width: "100%", fontSize: "14px" }}
											onClick={() => {}}
										>
											DESACTIVAR
										</Button>
									</FormItem>
								</Col>

								<Col span={7}></Col>
							</Row>
						</Form>
					) : (
						<Row className="section-modal">
							<Col
								span={24}
								style={{ textTransform: "uppercase", textAlign: "center" }}
							>
								<b>Datos de perfil no encontrados</b>
							</Col>
						</Row>
					)}
				</>
			</Modal>

			{dataExcelContactos == null ? null : (
				<Modal
					className="side-modal alert-modal modalEncuesta"
					bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
					visible={modalExcelContactos}
					title={
						<Row
							className="encabezado"
							style={{ textAlign: "left", paddingLeft: "25px" }}
						>
							<Col span={20}>
								<div>USUARIOS A REGISTRAR</div>
							</Col>
						</Row>
					}
					style={{ top: 0, right: 0, width: "100%" }}
					onCancel={() => setModalExcelContactos(false)}
					footer={
						<Row className="widthFooter">
							<Col span={6} style={{ textAlign: "left" }}>
								<Button
									className="btn-cancel"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setModalExcelContactos(false);
									}}
								>
									CANCELAR
								</Button>
							</Col>

							<Col span={12} style={{ textAlign: "center" }}></Col>

							<Col span={6} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setModalExcelContactos(false);
										setModalExcelCuotas(true);
									}}
								>
									SIGUIENTE
								</Button>
							</Col>
						</Row>
					}
					width={"95%"}
				>
					<div style={{ height: "85vh" }}>
						<Table
							className="primary-table"
							columns={columnsUsuarios}
							dataSource={dataExcelContactos}
							pagination={{ defaultPageSize: 10 }}
						/>
					</div>
				</Modal>
			)}

			{dataExcelCuotas == null ? null : (
				<Modal
					className="side-modal alert-modal modalEncuesta"
					bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 120px)" }}
					visible={modalExcelCuotas}
					title={
						<Row
							className="encabezado"
							style={{ textAlign: "left", paddingLeft: "25px" }}
						>
							<Col span={20}>
								<div>CUOTAS</div>
							</Col>
						</Row>
					}
					style={{ top: 0, right: 0, width: "100%" }}
					onCancel={() => setModalExcelCuotas(false)}
					footer={
						<Row className="widthFooter">
							<Col span={6} style={{ textAlign: "left" }}>
								<Button
									className="btn-cancel"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setModalExcelCuotas(false);
										setModalExcelContactos(true);
									}}
								>
									REGRESAR
								</Button>
							</Col>

							<Col span={12} style={{ textAlign: "center" }}></Col>

							<Col span={6} style={{ textAlign: "right" }}>
								<Button
									// disabled={requests.isSaving}
									className="btn-primary"
									type="primary"
									shape="round"
									style={{ width: "90%" }}
									onClick={() => {
										setModalExcelCuotas(false);
										setModalExcelContactos(false);
										setDataExcelContactos(null);
										setDataExcelCuotas(null);
										toast.success("Datos enviados");
									}}
								>
									GUARDAR
								</Button>
							</Col>
						</Row>
					}
					width={"100%"}
				>
					<div style={{ height: "85vh" }}>
						<Table
							className="primary-table"
							columns={columnsCuotas}
							dataSource={dataExcelCuotas}
							pagination={{ defaultPageSize: 10 }}
						/>
					</div>
				</Modal>
			)}
		</>
	);
};
