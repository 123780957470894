import React from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import moment from "moment";
import "../styles.css";
import { useEffect, useState } from "react";
const { RangePicker } = DatePicker;

export const PaymentsHistoryTable = () => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [detailModalActive, setDetailModalActive] = useState(false);
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");

	let locale = {
		emptyText: "No existen datos en el rango seleccionado",
	};

	const historyColumns = [
		{
			title: "FECHA",
			dataIndex: "date_action",
			key: "date_action",
			align: "center",
			/* sorter: (a, b) => a.constructionFee - b.constructionFee,
                render: (text, record) => (
                    <>{moment(record.date_action).format("YY/MM/D")}</>
                ), */
		},
		{
			title: "CONCEPTO",
			dataIndex: "concept",
			key: "concept",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "MONTO",
			dataIndex: "amount",
			key: "amount",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "MÉTODO",
			dataIndex: "method",
			key: "method",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "TICKET",
			dataIndex: "ticket",
			key: "ticket",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
		{
			title: "DETALLE",
			dataIndex: "details",
			key: "details",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},
	];

	const reportColumns = [
		{
			title: "TIPO DE OPERACIÓN",
			dataIndex: "type",
			key: "type",
			width: "50%",
		},
		{
			title: "TRANSACCIÓN",
			dataIndex: "payment",
			key: "payment",
			width: "25%",
		},
		{
			title: "COMISIÓN",
			dataIndex: "comision",
			key: "comision",
			/* summary: (data) => {
                let total = 0;
                data.forEach((item) => {
                  total += item.salary;
                });
                return `Total: ${total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`; // format the total as currency
            }, */
		},
	];

	/* const handleDetailModal = (statusModal) => {
		setTimeout(() => {
			setDetailModalActive(statusModal);
		}, 50);
	}; */

	return (
		<>
			<br />
			<Table
				className="primary-table"
				columns={historyColumns}
				pagination={{ defaultPageSize: 20, simple: true }}
				loading="true"
				//rowKey="id"
				locale={locale}
			/>
		</>
	);
};
