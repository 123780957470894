import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: {
            data: [],
            active: null,
            show: 0,
            errorNumero: 0,
            aprobado: 0
        },
        users_directive: {
            data: [],
            active: null
        },
        errorMessage: null
    },
    reducers: {
        setUsersData: ( state, { payload } ) => {
            state.users.data = payload;
        },
        setErrorNumero: ( state, { payload } ) => {
            state.users.errorNumero =  state.users.errorNumero + 1
        },
        setUsersDataAprobado: ( state, { payload } ) => {
            console.log(payload)

            var userList = state.users.data
            var indexUser = userList.findIndex((item) => item.id == payload.id)
            console.log("itemEncuesta",indexUser)
            if(indexUser != -1){
                var itemUser = userList[indexUser]
                itemUser.name = payload.name
                itemUser.lastname = payload.lastname
                itemUser.phone = payload.phone
                itemUser.phoneTwo = payload.phoneTwo
                itemUser.phoneThree = payload.phoneThree
                itemUser.phoneFour = payload.phoneFour
                itemUser.email = payload.email
                itemUser.emailTwo = payload.emailTwo
                itemUser.sociedadAnonima = payload.sociedadAnonima
                userList[indexUser] = itemUser
                state.users.data = userList
                state.users.active = itemUser
                console.log("esto es una actualizacion",userList[indexUser])
            }else{
                state.users.data.unshift(payload);
            }
            state.users.show =  state.users.show + 1
           
            //state.users.data = payload;
        },
        setUsersDirectiveData: ( state, { payload } ) => {
            state.users_directive.data = payload;
        },
        setUsersAprobado: ( state, { payload } ) => {
            //state.users.aprobado =  state.users.aprobado + 1
            console.log("aprobado",payload)

            var userList = state.users.data
            var userActive = state.users.active
            var indexUser = userList.findIndex((item) => item.id == payload.idProfile)
            
            if(indexUser != -1){
                var itemUser = userList[indexUser]
                itemUser.verified = "Verificado"
                itemUser.observations = payload.observaciones
            }

            userActive.verified = "Verificado"
            userActive.observations = payload.observaciones

            state.users.aprobado =  state.users.aprobado + 1
            state.users.active = userActive

        },
        
        setUserActive: ( state, { payload } ) => {
            state.users.active = payload;
        },
        setUserDirectiveActive: ( state, { payload } ) => {
            state.users_directive.active = payload;
        }
    }
});


export const { 
    setUsersData,
    setUsersDirectiveData,
    setUserActive,
    setUserDirectiveActive,
    setUsersDataAprobado,
    setErrorNumero,
    setUsersAprobado
} = usersSlice.actions;