import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import { Col, Row, Button, DatePicker, Tabs, Select } from "antd";
import { ReportLiquidacionTable } from "./ReportLiquidacionTable";
const { RangePicker } = DatePicker;

export const ReportLiquidacionContainer = (props) => {
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;
	const [endDate, setEndDate] = useState("");
	const [startDate, setStartDate] = useState("");

	const { buscarFechas, loadingBt } = props;

	const onChangeFecha = (date, dateString) => {
		console.log(date, dateString);
		setStartDate(dateString[0]);
		setEndDate(dateString[1]);
		buscar();
	};

	const buscar = async () => {
		if (startDate == "") {
			var fechaI = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(new Date(), "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
			buscarFechas(fechaIn, fechaFi);
		} else {
			var fechaI = new Date(moment(startDate, "DD/MM/YYYY"));
			var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

			var fechaF = new Date(moment(endDate, "DD/MM/YYYY"));
			var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
			buscarFechas(fechaIn, fechaFi);
		}
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const { Option } = Select;

	const handleCountryChange = () => {};

	const handleAccountChange = () => {};

	return (
		<>
			<Row>
				<Col span={5}>
					<Select
						placeholder="País"
						bordered={false}
						className="select_style"
						style={{
							width: "90%",
						}}
						onChange={handleCountryChange}
						defaultActiveFirstOption={true}
						options={[
							{
								value: "sv",
								label: "El Salvador",
							},
							{
								value: "hn",
								label: "Honduras",
							},
						]}
					/>
				</Col>
				<Col span={5}>
					<Select
						placeholder="Cuenta"
						bordered={false}
						className="select_style"
						style={{
							width: "90%",
						}}
						filterOption={(input, option) => {
							return (
								option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
							);
						}}
						onChange={handleAccountChange}
					></Select>
				</Col>
				<Col span={5}>
					<Select
						placeholder="Cuentas"
						bordered={false}
						className="select_style"
						style={{
							width: "90%",
						}}
						filterOption={(input, option) => {
							return (
								option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
							);
						}}
					></Select>
				</Col>
				<Col span={9} align="left">
					<RangePicker
						defaultValue={[
							moment(new Date(), dateFormat),
							moment(new Date(), dateFormat),
						]}
						format={customFormat}
						style={{
							width: "270px",
						}}
						onCalendarChange={onChangeFecha}
					/>
				</Col>
				<Col span={24}>
					<ReportLiquidacionTable />
				</Col>
			</Row>
		</>
	);
};
