import { Col, Row, Button, Modal,Radio,Table} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { getAdminCobros,setActiveAdminCobro,getUserCobros,saveAdminCobro} from '../../../store/admincobros';
import './style.css'
import { useForm } from '../../../hooks';
import TextArea from 'antd/lib/input/TextArea';
import { toast } from 'react-toastify';
import { Select } from 'antd';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { utils, writeFile }  from "xlsx";
import { async } from '@firebase/util';
import $ from 'jquery';


const { Option } = Select;
const { RangePicker } = DatePicker;

export const AdminCobrosPage = () => {

  const [surveysList, setSurveysList] = useState([]);
  const [opcionesGrafica, setOpcionesGrafica] = useState([]);
  const [adminCobroDetail, setAdminCobroDetail] = useState(null);
  const [adminCobroNuevo, setAdminCobroNuevo] = useState(null);
  const { adminCobros } = useSelector( state => state.adminCobros );
  const [isModalVisiblePasoUno, setIsModalVisiblePasoUno] = useState(false);
  const [isModalVisiblePasoDos, setIsModalVisiblePasoDos] = useState(false);
  const [isModalVisiblePasoTres, setIsModalVisiblePasoTres] = useState(false);
  const [isModalVisibleUser, setIsModalVisibleUser] = useState(false);
  const [listPreguntas, setListPreguntas] = useState([]);
  const [listPreguntasEliminadas, setListPreguntasEliminadas] = useState([]);
  const [tipoCobro, setTipoCobro] = useState("Mensual");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(false);
  const [handleSelectTabs, setHandleSelectTabs] = useState(false);
  const [idBorrado, setIdBorrado] = useState("");
  const [diaEmision, setDiaEmision] = useState("");
  const [diaVencimiento, setDiaVencimiento] = useState("");
  const [userPagoList, setUserPagoList] = useState([]);
  const [onlyRead, setOnlyRead] = useState(false);
  const [visibleRegresarPasoDos, setVisibleRegresarPasoDos] = useState(false);

  
  const [loadSendData, setLoadSendData] = useState(false);
  const [loadSendDataBorrador, setLoadSendDataBorrador] = useState(false);
  const [valueOpcionPago, setValueOpcionPago] = useState(1);
  const [listDias, setListDias] = useState([
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28
  ]);

  
  const columns = [
        {
          title: 'NOMBRE  DE USUARIO',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'DIRECCIÓN RESIDENCIA',
          dataIndex: 'address',
          key: 'address'
        },
        {
          title: 'VALOR DE COBRO',
          dataIndex: 'valor',
          key: 'index',
          align:"center",
          width: 120,
          render: (id, record, index) => (
            <div className="">
              {
                onlyRead ? 
                  <div className='form-control inputValorRead'>{record.valor}</div>
                :
                <input 
                    className='form-control inputValor'
                    name="titulo" 
                    type="number"
                    value={ record.valor }
                    onChange={e => {
                      var listClone = {... adminCobroNuevo}
                      listClone.listadoUser[listClone.listadoUser.indexOf(record)].valor = e.target.value
                      setAdminCobroNuevo(listClone)
                      //console.log(listClone.listadoUser.indexOf(record) )
                    }}
                    //onChange={ onInputChange }
                    />
                
              }
              
            </div>
          ),
        }
      ]
  
  const dateFormat = 'DD/MM/YYYY';
  const customFormat = (value) => `${value.format(dateFormat)}`;
  const [placement, SetPlacement] = useState('topLeft');

  
  const { titulo, valor, resetValuesInput,onInputChange, onResetForm } = useForm({
    titulo: '',
    valor: ''
  });

  const onChangeRadio = (e) => {
    console.log('radio checked', e.target.value);
    setValueOpcionPago(e.target.value);
  };

  const placementChange = (e) => {
    SetPlacement(e.target.value);
  };

  const dispatch = useDispatch();

  const disabledDate = (current) => {

      /*var today = new Date();
    var tomorrow = new Date(today);
    tomorrow.setDate(today.getDate()+1);
    tomorrow.toLocaleDateString();*/

    return current && current.valueOf() < Date.now();

    //const weekStart = moment().startOf('week');
    //const weekEnd = moment().endOf('week');
    //return !(weekStart.isSameOrBefore(current) && weekEnd.isAfter(current));
  }

  const handleChange = (value,key) => {
    let newArr = [...listPreguntas]; // copying the old datas array
    newArr[key].question = value
  
    setListPreguntas(newArr);
  };

  const handleStatusModal = (statusModal, index = null) => {
    

    setTimeout(() => {
      setModalVisible(statusModal);
    }, 50);

  }
  
 
  useEffect(()=>{

    dispatch(getAdminCobros());
    dispatch(getUserCobros());

    
  }, [])

  

  useEffect(()=>{
    if(adminCobros.data.length > 0){
      setSurveysList(adminCobros.data)
    }
    setIsModalVisiblePasoTres(false)
    setIsModalVisiblePasoTres(false)
    setIsModalVisiblePasoDos(false)
    setIsModalVisiblePasoUno(false)
    setIsModalVisibleUser(true)

    setLoadSendData(false)
    setLoadSendDataBorrador(false)
    setAdminCobroNuevo(null)

    onResetForm();

  }, [adminCobros.data])


  useEffect(()=>{
    console.log("userResidentes",adminCobros.userResidentes)

    setUserPagoList(adminCobros.userResidentes)

  }, [adminCobros.userResidentes])

  const descargarEncuesta = () => {
    console.log("aqui")

    var i = 0;
    var wb = utils.book_new();
    adminCobroDetail.preguntas.map((item, index) => {

      const dataSet = []
      if( item.type == "qualify" || item.type == "option" ){

        opcionesGrafica[index]?.map((itemP, index) => {
          dataSet.push({
            nombre: itemP.titulo,
            cantidad: itemP.cantidad,
            porcentaje: itemP.porcentaje+"%",
          })
        })
       
      }else if(item.type == "commentary"){
        opcionesGrafica[index]?.map((itemP, index) => {
          dataSet.push({
            respuesta: itemP.response
          })
        })
      }
     
      var ws = utils.json_to_sheet([{}],{
        header: [item.question],
      });
      utils.sheet_add_json(ws, dataSet, { origin: 'A3' });
      utils.book_append_sheet(wb,ws,"Pregunta "+(index+1));

      
    })

    writeFile(wb,adminCobroDetail.name+".xlsx")
  }

  const handleCancelPasoUno = () => {
    setIsModalVisiblePasoUno(false)
  }

  const handleCancelPasoDos = () => {
    setIsModalVisiblePasoDos(false)
  }

  const handleCancelPasoTres = () => {
    setIsModalVisiblePasoTres(false)
  }
  
  useEffect(()=>{
    setAdminCobroDetail(adminCobros.active)

  }, [adminCobros.active])
  

  const handleActiveDetail = (data)=>{
    console.log("data",data)
    dispatch( setActiveAdminCobro(data) );
  }

  const handleEdit = (data)=>{
    console.log(data)
    var formInfo = {"titulo":data.name,"valor":data.descripcion}
    resetValuesInput(formInfo)

    let newArr = [];
    var i = 0;
    while (i < data.preguntas.length) {
      var item =  data.preguntas[i]
      console.log("item",item)
      var itemNew = {
        idR: item.idR,
        question: item.question,
        type: item.type,
        posicion:newArr.length
      }

      if(item.type == "option"){
        var j = 0
        var opciones = []
        while (j < item.option.length) {
          opciones.push({posicion:j,valor:item.option[j]})
          ++j;
        }
        
        itemNew.opciones = opciones
      }


      newArr.push(itemNew)
      ++i;
    }

    setListPreguntas(newArr);
    setStartDate("")
    setEndDate("")
    setIdBorrado(data.id)


    setIsModalVisiblePasoUno(true)
  }


  const eliminarPregunta = (posicion) => {
    let newArr = [...listPreguntas];
    let newArrEliminadas = [...listPreguntasEliminadas];
    newArrEliminadas.push(newArr[posicion])
    delete newArr[posicion]
   
    var newArrPosition = []
    var i = 0;
    while (i < newArr.length) {
      if(typeof newArr[i] !== 'undefined'){
        newArr[i].posicion = newArrPosition.length
        newArrPosition.push(newArr[i])
      }
      ++i;
    }

    setListPreguntas(newArrPosition);
    setListPreguntasEliminadas(newArrEliminadas);
  }


  const onChangeFecha = (date, dateString) => {
    console.log(date, dateString);
    setStartDate(dateString[0])
    setEndDate(dateString[1])
    //const [startDate, setstartDate] = useState("");
    //const [endDate, setendDate] = useState("");
  };

  const actualizarCobro = async(lista,adminCobroD) => {
    console.log("adminCobroDetail",adminCobroD)
    setOnlyRead(lista)

    var dataNew = {}
    dataNew.new = false
    dataNew.title = adminCobroD.title
    dataNew.id = adminCobroD.id
    dataNew.amount = adminCobroD.amount
    dataNew.type = adminCobroD.type
    dataNew.dateEmision = adminCobroD.dateEmision
    dataNew.dateVencimiento = adminCobroD.dateVencimiento
    dataNew.dayEmision = adminCobroD.dayEmision
    dataNew.dayVencimiento = adminCobroD.dayVencimiento
    dataNew.eraser = adminCobroD.eraser
    var listadoUser = []

    var i = 0;
    while (i < userPagoList.length) {
      var itemUser = {... userPagoList[i]}
      let findUser = adminCobroD.respuesta.find( request => (request.idProfile == itemUser.id));
      if(typeof findUser !== 'undefined'){
        itemUser.valor = findUser.amount
        itemUser.idP = findUser.idP
      }else{
        itemUser.valor = valor
        itemUser.idP = 0
      }
      listadoUser.push(itemUser)
      ++i;
    }
    dataNew.listadoUser = listadoUser
    console.log("dataNew",dataNew)
    setAdminCobroNuevo(dataNew)
    setVisibleRegresarPasoDos(false)

    if(lista){
      setIsModalVisibleUser(true)
    }else{
      var formInfo = {"titulo":dataNew.title,"valor":dataNew.amount}
      resetValuesInput(formInfo)
      setTipoCobro(dataNew.type == 1 ? "Única vez" : "Mensual")
      if(dataNew.type == 1){
        setDiaEmision("")
        setDiaVencimiento("")
        setStartDate(dataNew.dateEmision)
        setEndDate(dataNew.dateVencimiento)
        //$(".contFechaEmision .ant-select-selection-item").html("hola");
      }else{
        setDiaEmision(dataNew.dayEmision)
        setDiaVencimiento(dataNew.dayVencimiento)
        setStartDate("")
        setEndDate("")
        $(".contDiaEmision .ant-select-selection-item").html(dataNew.dateEmision);
        $(".contDiaVencimiento .ant-select-selection-item").html(dataNew.dateVencimiento);
        
      }

      $(".contIdTipo .ant-select-selection-item").html(tipoCobro);
      


      setIsModalVisibleUser(false)
      setIsModalVisiblePasoDos(true)
    }
    

  }

  const onHandleSubmit = () => {

    setIsModalVisiblePasoUno(false); 
    setIsModalVisiblePasoDos(true)
    //onResetForm();
  }

  const onHandleGuardarEncuesta = async(eraser) => {
    if(eraser){
      setLoadSendDataBorrador(true)
    }else{
      setLoadSendData(true)
    }
    

    var data = {... adminCobroNuevo}
    data.active = true
    data.eraser = eraser

    dispatch( saveAdminCobro(data) );


  }

  const onHandleSubmitForm = async() => {

    if(!titulo){
      toast.warn("Debe ingresar titulo del cobro");
      return;
    }

    if(!valor){
      toast.warn("Debe ingresar valor del cobro");
      return;
    }
    
    //aqui
    setIsModalVisiblePasoDos(false)
    setIsModalVisiblePasoTres(true)
  }

  const onHandleSubmitValidar = async(eraser) => {
    if(tipoCobro == "Mensual"){
      if(diaEmision == ""){
        toast.warn("Debe ingresar día de emisión del cobro");
        return
      }

      if(diaVencimiento == ""){
        toast.warn("Debe ingresar día de vencimiento del cobro");
        return
      }

    }else{
      if(startDate == ""){
        toast.warn("Debe ingresar fecha de emisión del cobro");
        return
      }

      if(endDate == ""){
        toast.warn("Debe ingresar fecha de vencimiento del cobro");
        return
      }
    }



    var dataNew = {}
    dataNew.new = true
    dataNew.title = titulo
    dataNew.id = ""
    dataNew.amount = valor
    dataNew.type = tipoCobro == "Única vez" ? 1:2
    dataNew.dateEmision = startDate
    dataNew.dateVencimiento = endDate
    dataNew.dayEmision = diaEmision
    dataNew.dayVencimiento = diaVencimiento
    dataNew.eraser = true
    var listadoUser = []

    var i = 0;
    while (i < userPagoList.length) {
       var itemUser = {... userPagoList[i]}
       itemUser.valor = valor
       itemUser.idP = 0
       listadoUser.push(itemUser)
        ++i;
    }
    dataNew.listadoUser = listadoUser
    console.log("dataNew",dataNew)
    setAdminCobroNuevo(dataNew)
    setIsModalVisiblePasoTres(false)
    setIsModalVisibleUser(true)
    console.log("adminCobros",adminCobroNuevo)

    //const [adminCobroNew, setAdminCobroNew] = useState(null);

   

    //dispatch( setSurveys(data) );
  }

  

  return ( 
    <>
        <div className="wrapper-page">
        <h2 className='title-page'>
            Administración de cobros
        </h2>

            <Row>

              <Col lg={10} sm={24}>

                
                
                <Button className='btn-primary-radio'  onClick={() => {
                  onResetForm()
                  setIdBorrado("")
                  setListPreguntas([])
                  setListPreguntasEliminadas([])
                  setIsModalVisiblePasoUno(true)
                  setVisibleRegresarPasoDos(true)
                  setOnlyRead(false)
                  $(".ant-picker-input :input").val('');
                 

                  }}>
                    <img src='/assets/images/shape20.png' />
                    NUEVO COBRO
                </Button>

                <Tabs style={{marginTop:"10px"}} className="tabsMovil">
                    <TabList>
                    <Tab tabFor={0}>COBROS ACTIVOS</Tab>
                    <Tab tabFor={1}>COBROS INACTIVOS</Tab>
                    </TabList>

                    <TabPanel tabId={0}>
                      <ul className='primary-card-list'>
                        {
                          surveysList.map((survey, key) => {
                            if(survey.eraser == false){
                              return(
                                <li key={key} onClick={() => {handleActiveDetail(survey)}} className={adminCobroDetail == null ? "primary-card-card" : adminCobroDetail.id == survey.id ? "primary-card-card selectedSurvery" : "primary-card-card"}>
                                  <img className='iconMetodo' src="/assets/images/metodo_pago.png" alt="" />
                        
                                  <div className='primary-card-info'>
                                      <h3>{survey.title}</h3>
                                      <span>Última modificación: {survey.update_at}</span><br />
                                    
                                  </div>
                        
                                  <img className='shape-next' src="/assets/images/shape22.png" alt="" />
                                </li>
                              )
                            }
                            
                          })
                        }
                      </ul>
                    </TabPanel>
                    <TabPanel tabId={1}>
                      <ul className='primary-card-list'>
                          {
                            surveysList.map((survey, key) => {
                              if(survey.eraser == true){
                                return(
                                  <li key={key} onClick={() => {
                                    
                                    actualizarCobro(false,survey) 
                                    }} className={adminCobroDetail == null ? "primary-card-card" : adminCobroDetail.id == survey.id ? "primary-card-card selectedSurvery" : "primary-card-card"}>
                                    <img className='iconMetodo' src="/assets/images/metodo_pago.png" alt="" />
                          
                                    <div className='primary-card-info'>
                                      
                                      <h3>{survey.title}</h3>
                                      <span>Última modificación: {survey.update_at}</span><br />
                                    </div>
                          
                                    <img className='shape-next' src="/assets/images/shape22.png" alt="" />
                                  </li>
                                )
                              }
                            })
                          }
                      </ul>
                    </TabPanel>

                    
                </Tabs>


                
              </Col>

              <Col lg={{span:12, offset:2 }} sm={24}>
                {
                  adminCobroDetail == null ?

                  <div className='contCenter'>


                    <div className='contVacio'>
                      <img className='shape' src="/assets/images/shape.png" alt="" />
                      <img className='iconFactura' src="/assets/images/factura.png" alt="" />
                    </div>
                    <span>SELECCIONAR COBRO</span>
                  </div>

                  :

                  <div className='primary-card-detail survey-detail'>

                  <div className="primary-card-section" style={{lineHeight: "16px"}}>
                    <h4>COBRO</h4>
                    <h2>
                      {adminCobroDetail.title}
                    </h2>
                  </div>


                  <div className="primary-card-section">
                    
                    <Row style={{padding:"0px"}}>
                      <Col lg={12}>
                        <span className="tituloDetalle">VALOR DE COBRO:</span>
                        <span className="montoDetalle"> ${adminCobroDetail.amount}</span>

                        <span className="tituloDetalle">CREADO:</span>
                        <span className="fechaDetalle">{adminCobroDetail.created_at}</span>


                        <span className="tituloDetalle">ÚLTIMA MODIFICACIÓN:</span>
                        <span className="fechaDetalle">{adminCobroDetail.update_at}</span>

                      </Col>
                      
                      <Col lg={12} className="contCreacion">
                        <Button onClick={() => {
                            actualizarCobro(true,adminCobroDetail)
                        }} modalVisible className='btn-primary-radio'>
                          <div style={{display:"flex"}}>
                            <img className="iconButton" src="/assets/images/vision.png" alt="" />
                            VISUALIZAR LISTADO
                            {
                              loadMoreData ?
                                <div style={{marginLeft:20}}>
                                  <Spinner />
                                </div>
                              : null
                            }
                          
                            
                          </div>
                          
                        </Button>


                        <Button onClick={() => {
                            //mostrarEstadisticas()
                        }} modalVisible className='btn-primary-radio'>
                          <div style={{display:"flex"}}>
                            <img className="iconButton" src="/assets/images/reporte.png" alt="" />
                            USUARIOS EN MORA
                            {
                              loadMoreData ?
                                <div style={{marginLeft:20}}>
                                  <Spinner />
                                </div>
                              : null
                            }
                          
                            
                          </div>
                          
                        </Button>
                      </Col>
                    </Row>

                    <Row style={{borderTop: "1px solid #ccc", borderBottom: "1px solid #ccc",}}>
                      <Col lg={12}>
                        <div style={{paddingLeft:"50px"}}>
                          <h4>TIPO DE COBRO:</h4>
                          <span className="fechaDetalle">{adminCobroDetail.type ==  1 ? "Única vez" : "Mensual"}</span>
                        </div>
                      </Col>
                      <Col lg={12}>
                        
                        <div style={{paddingLeft:"50px"}}>
                          <h4>COBRO DESDE:</h4>
                          <span className="fechaDetalle">{adminCobroDetail.type ==  1 ? adminCobroDetail.dateEmision : adminCobroDetail.dayEmision+" de cada mes"}</span>
                        </div>

                      </Col>
                    </Row>

                    <Row style={{ textAlign: "center", margin: "auto", display: "block" }}>
                      <Button onClick={() => {
                          actualizarCobro(false,adminCobroDetail)
                       }}   className='btn-primary' 
                       type="primary" 
                       shape="round">
                        <div style={{display:"flex"}}>
                          
                           ACTUALIZAR COBRO 
                          {
                            loadMoreData ?
                              <div style={{marginLeft:20}}>
                                <Spinner />
                              </div>
                            : null
                          }
                         
                          
                        </div>
                        
                      </Button>
                    </Row>

                  </div>



                  </div>
                }
                  
                

              </Col>


            </Row>


            <Modal 
              className='primary-modal'
              title="CREAR NUEVO COBRO" 
              visible={isModalVisiblePasoUno}
              onCancel={() => handleCancelPasoUno()}
              footer = { null }>

              <div>
                  <div className="pasos">PASO 1 DE 3</div>
                  
                    <Radio.Group onChange={onChangeRadio} value={valueOpcionPago} style={{width:"100%"}}>
                      <Row>
                        <Col span={12} style={{textAlign:"center"}}>
                          <div className="opcionPago" onClick={() => { eliminarPregunta() }}>
                            <img className="iconOpcionPago" src="/assets/images/doc_online.png" alt="Agregar" />
                            <Radio value={1}>Crear online</Radio>
                          </div>
                        </Col>

                        <Col span={12} style={{textAlign:"center"}}>
                          <div className="opcionPago" onClick={() => { eliminarPregunta() }}>
                            <img className="iconOpcionPago" src="/assets/images/hoja_excel.png" alt="Agregar" />
                            <Radio value={2}>Subir plantilla Excel</Radio>
                          </div>
                        </Col>
                      </Row>
                      </Radio.Group>
                  
              </div>

              <div className='footer-buttons'>          
                  <Button 
                      // disabled={requests.isSaving} 
                      className='btn-primary' 
                      type="primary" 
                      shape="round" 
                      style={{ width: "150px" }} 
                      onClick={onHandleSubmit}>
                      SIGUIENTE
                  </Button>
              </div>
            </Modal>

            <Modal 
              className='primary-modal'
              title="CREAR NUEVO COBRO" 
              visible={isModalVisiblePasoDos}
              onCancel={() => handleCancelPasoDos()}
              footer = { <Row>

                <Col span={6} style={{textAlign:"left"}}>
                  {
                    visibleRegresarPasoDos ?
                    <Button 
                      className='btn-cancel' 
                        type="primary" 
                        shape="round" 
                        style={{ width: "90%" }}
                        onClick={() => { 
                          setIsModalVisiblePasoDos(false)
                          setIsModalVisiblePasoUno(true)
                        }}
                        >
                        REGRESAR
                    </Button>
                    : null
                  }
                  
                </Col>

                <Col span={12} style={{textAlign:"right"}}></Col>

                <Col span={6} style={{textAlign:"right"}}>
                  <Button 
                      // disabled={requests.isSaving} 
                      className='btn-primary' 
                      type="primary" 
                      shape="round" 
                      style={{ width: "90%" }} 
                      onClick={() => { 
                        onHandleSubmitForm()
                      }}
                      >
                      SIGUIENTE
                  </Button>
                </Col>
              </Row> }>

              <form action="">
                  <div className="pasos">PASO 2 DE 3</div>
                  <div className="form-group">
                      <label htmlFor="">TITULO DE COBRO:</label>
                      <input 
                          className='form-control'
                          name="titulo" 
                          value={ titulo }
                          onChange={ onInputChange }/>
                  </div>
                  <div className="form-group">
                      <label htmlFor="">VALOR DEL COBRO:</label>
                      <input 
                          type="number" 
                          className='form-control'
                          name="valor" 
                          placeholder="$ 0.0"
                          value={ valor }
                          onChange={ onInputChange }/>
                  </div>
                  <span className="leyenda">* Este valor puede editado posteriormente y además colocar valores diferentes de cobro para cada usuario residencial.</span>

              </form>

              
            </Modal>

            <Modal 
              className='primary-modal'
              title="CREAR NUEVO COBRO" 
              visible={isModalVisiblePasoTres}
              onCancel={() => handleCancelPasoTres()}
              footer = { <Row>

                <Col span={6} style={{textAlign:"left"}}>
                  <Button 
                      className='btn-cancel' 
                      type="primary" 
                      shape="round" 
                      style={{ width: "90%" }}
                      onClick={() => { 
                        setIsModalVisiblePasoTres(false)
                        setIsModalVisiblePasoDos(true)
                       }}
                      >
                      REGRESAR
                  </Button>
                </Col>

                <Col span={6}style={{textAlign:"center"}}>
                  
                </Col>

                <Col span={12} style={{textAlign:"right"}}>
                  <Button 
                      // disabled={requests.isSaving} 
                      className='btn-primary' 
                      type="primary" 
                      shape="round" 
                      style={{ width: "90%" }} 
                      onClick={() => { 

                        onHandleSubmitValidar(false)
                       }}
                      >
                        {
                          loadSendData ?
                          <div className='centerSpinner'> <Spinner /></div>
                         
                          :
                          "SIGUIENTE"
                        }
                      
                  </Button>
                </Col>
              </Row> }
              >
       

            
                  <div className="pasos">PASO 3 DE 3</div>
                  <div className="form-group contIdTipo">
                      <label htmlFor="">FRECUENCIA DE COBRO:</label>
                      <Select
                          id={"idTipo"}
                          defaultValue={tipoCobro}
                          style={{
                            width: "100%",
                          }}
                          onChange={(value)=>{
                            console.log(value)
                            setTipoCobro(value)
                            
                          }}
                        >
                          <Option value="Única vez">Única vez</Option>
                          <Option value="Mensual">Mensual</Option>
                        </Select>
                  </div>


                  {
                    tipoCobro == "Mensual" ?
                    <div className="contOpciones">
                      <div className="form-group contDiaEmision">
                        <Row>
                          <Col span={24} style={{textAlign:"left"}}>
                            <label htmlFor="">DíA DE EMISIÓN</label>
                            <Select
                              id={"diaEmision"}
                              defaultValue={diaEmision}
                              style={{
                                width: "100%",
                              }}
                              onChange={(value)=>{
                                console.log(value)
                                setDiaEmision(value)
                              }}
                            >
                              {
                                  listDias.map((item, index) => {
                                    return (
                                      <Option value={item}>{item}</Option>
                                    )
                                  })
                              }
                            </Select>
                          </Col>
                        </Row>
                      </div>

                      <div className="form-group contDiaVencimiento">
                        <Row>
                          <Col span={24} style={{textAlign:"left"}}>
                            <label htmlFor="">DíA DE VENCIMIENTO</label>
                            <Select
                              id={"idDiaVencimiento"}
                              defaultValue={diaVencimiento}
                              style={{
                                width: "100%",
                              }}
                              onChange={(value)=>{
                                console.log(value)
                                setDiaVencimiento(value)
                              }}
                            >
                              {
                                  listDias.map((item, index) => {
                                    return (
                                      <Option value={item}>{item}</Option>
                                    )
                                  })
                              }
                            </Select>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    : 
                    <div className="contOpciones">
                      <div className="form-group contFechaEmision">
                        <Row>
                          <Col span={24} style={{textAlign:"left"}}>
                            <label htmlFor="">FECHA DE EMISIÓN &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FECHA DE VENCIMIENTO</label>
                            <RangePicker format={customFormat} locale={locale} style={{
                                width: "100%",
                              }}
                              
                              onChange={onChangeFecha} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }

            </Modal>

            {
                  adminCobroNuevo == null ?
                  null
                  :
                  <Modal
                    className='side-modal alert-modal modalEncuesta'
                    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 170px)' }}
                    visible={isModalVisibleUser}
                    title={
                      <Row className='encabezado' style={{textAlign:"left",paddingLeft:"25px"}}>
                          <Col span={20}>
                            <div>NOMBRE DE COBRO</div>
                            <h2>
                              {adminCobroNuevo.title}
                            </h2>
                            <Row>
                              <Col span={8}><strong>FRECUENCIA DE COBRO:</strong> {adminCobroNuevo.type ==  1 ? "Única vez" : "Mensual" }</Col>
                              <Col span={8}><strong>INICIO DE COBRO:</strong>  {adminCobroNuevo.type ==  1 ? adminCobroNuevo.dateEmision : adminCobroNuevo.dayEmision+" de cada mes" }
                              
                              </Col>
                              <Col span={8}><strong>FINALIZACIÓN DE COBRO:</strong> {adminCobroNuevo.type ==  1 ? adminCobroNuevo.dateVencimiento : adminCobroNuevo.dayVencimiento+" de cada mes" }</Col>
                            </Row>
                          </Col>

                          {
                            adminCobroNuevo.new == true ?
                              null
                            : <Col span={4} style={{display: "flex"}}>
                            <div className="bloqueEncuesta">
                              <div className="descargar" onClick={() => {
                                descargarEncuesta()
                                  }}>
                                  <img className="sheets" src="/assets/images/sheets.png" />
                                  DESCARGAR ARCHIVO
                                
                              </div>
                            </div>

                          </Col>
                          }
                          
                          
                      </Row>
                    }
                    style={{ top: 0, right:0,width:"100%" }}
                    onCancel={() => setIsModalVisibleUser(false)}
                    footer = { <Row className="widthFooter">

                      <Col span={6} style={{textAlign:"left"}}>
                        {
                          onlyRead == false ?
                          <Button 
                              className='btn-cancel' 
                              type="primary" 
                              shape="round" 
                              style={{ width: "90%" }}
                              onClick={() => { 
                                setIsModalVisiblePasoTres(true)
                                setIsModalVisiblePasoDos(false)
                                setIsModalVisiblePasoUno(false)
                                setIsModalVisibleUser(false)
                              }}
                              >
                              REGRESAR
                          </Button>
                          : null 

                        }
                        
                       
                      </Col>
      
                      <Col span={12} style={{textAlign:"center"}}>
                        {
                          onlyRead == true ?
                          <Button 
                                className='btn-cancel' 
                                type="primary" 
                                shape="round" 
                                style={{ width: "90%" }}
                                onClick={() => { 
                                  setIsModalVisiblePasoTres(false)
                                  setIsModalVisiblePasoDos(false)
                                  setIsModalVisiblePasoUno(false)
                                  setIsModalVisibleUser(false)
                                }}
                                >
                                CERRAR
                            </Button>
                            : null

                        }

                        {
                          adminCobroNuevo.eraser == true ?
                          <Button 
                              // disabled={requests.isSaving} 
                              className='btn-borrador' 
                              type="primary" 
                              shape="round" 
                              style={{ width: "90%" }} 
                              onClick={() => { 
                                onHandleGuardarEncuesta(true)
                              }}
                              >
                                {
                                  loadSendDataBorrador ?
                                  <div className='centerSpinner'> <Spinner /></div>
                                
                                  :
                                  "GUARDAR Y CONTINUAR DESPUÉS"
                                }

                              
                          </Button>
                          : null
                        }
                        
                      </Col>
      
                      <Col span={6} style={{textAlign:"right"}}>
                      {
                          onlyRead == false ?

                              <Button 
                                  // disabled={requests.isSaving} 
                                  className='btn-primary' 
                                  type="primary" 
                                  shape="round" 
                                  style={{ width: "90%" }} 
                                  onClick={() => { 
                                    onHandleGuardarEncuesta(false)
                                  }}
                                  >
                                    {
                                        loadSendData ?
                                        <div className='centerSpinner'> <Spinner /></div>
                                      
                                        :
                                        "CREAR COBRO"
                                      }
                                    

                                  
                              </Button>
                              : null
                              }
                      </Col>
                    </Row> 
                    }
                    width={"95%"}
                    >
                  <div style={{  height: '80vh'}}>
                    <Table  className='primary-table' columns={columns} dataSource={adminCobroNuevo.listadoUser} pagination={{ defaultPageSize: 8 }} />
                  </div>
                    
                  </Modal>   
            }
                         
        </div>
    </>
  )
}
