import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import FormItem from "antd/lib/form/FormItem";
import { useNavigate } from "react-router-dom";
import { NewsEvent } from "../components/NewsEvent";
import { EventEvent } from "../components/EventEvent";
import { NotificationEvent } from "../components/NotificationEvent";
import { InformationEvent } from "../components/InformationEvent";
import { useParams } from "react-router-dom";
import { getNewsByIdHelper } from "../../../../helpers/newsHelper";
import { getAllResidentials } from "../../../../helpers/globalHelper";

export const EditNewsPagePrincipal = () => {
	const [news, setNews] = useState([]);
	const [accounts, setAccounts] = useState([]);
	const [accountsOptions, setAccountsOptions] = useState([]);
	const { Option } = Select;

	useEffect(() => {
		getAccounts();
	}, []);

	useEffect(() => {
		let accountOptions = [];
		accounts.map((item, index) => {
			let id = item.id;
			accountOptions.push(
				<Option key={index} value={index} label={item.name}>
					{item.name}
				</Option>
			);
			return item;
		});
		setAccountsOptions(accountOptions);
	}, [accounts]);

	const getAccounts = async () => {
		const accounts = await getAllResidentials().then((result) =>
			setAccounts(result)
		);
	};

	const { id } = useParams();

	const navigate = useNavigate();

	useEffect(() => {
		getNewsById(id).catch(console.error);
	}, []);

	const getNewsById = async (newsId) => {
		const getNews = await getNewsByIdHelper(newsId).then((result) =>
			setNews(result)
		);
	};

	const renderFormByType = (news) => {
		if (news.type === "event") {
			return (
				<EventEvent
					accounts={accounts}
					accountsOp={accountsOptions}
					data={news}
				/>
			);
		} else if (news.type === "info") {
			return (
				<NotificationEvent
					accounts={accounts}
					accountsOp={accountsOptions}
					data={news}
				/>
			);
		} else if (news.type === "news") {
			return (
				<NewsEvent
					accounts={accounts}
					accountsOp={accountsOptions}
					data={news}
				/>
			);
		} else {
			return <></>;
		}
	};
	const showCategory = (category) => {
		let name = "";
		if (category === "event") {
			name = "Evento";
		}
		if (category === "news") {
			name = "Noticia";
		}
		if (category === "info") {
			name = "Notificación";
		}
		return name;
	};
	return (
		<>
			{news ? (
				<Layout>
					<Content>
						<div className="wrapper-page">
							<Row>
								<Col span={24}>
									<h2 className="title-page">
										Editar {showCategory(news.type)}
									</h2>
								</Col>
								<Col span={24}>
									<Card
										style={{
											width: "100%",
											borderRadius: "10px",
											boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
											marginTop: "30px",
											marginBottom: "30px",
										}}
										bordered
									>
										{console.log("noticia a editar", news)}
										{renderFormByType(news)}
									</Card>
								</Col>
							</Row>
						</div>
					</Content>
				</Layout>
			) : (
				<></>
			)}
		</>
	);
};
