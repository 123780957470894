import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import {
	Col,
	Row,
	Button,
	DatePicker,
	Tabs,
	Table,
	Select,
	Space,
	Input,
	Modal,
} from "antd";
import { getColumnSearchProps } from "../../../../utils/tables";
import { SearchOutlined } from "@ant-design/icons";
import "../styles.css";
const { RangePicker } = DatePicker;

export const AccreditedSettlementTab = (props) => {
	const [residentials, setResidentials] = useState([]);
	const [selected, setSelected] = useState([]);
	const [detailModalActive, setDetailModalActive] = useState(false);
	const [recordActive, setRecordActive] = useState([]);
	const dateFormat = "DD/MM/YYYY";
	const customFormat = (value) => `${value.format(dateFormat)}`;

	const { searchAccreditedByDate, data, loadingBt } = props;

	const config = {
		rules: [{ required: true, message: "Por favor llenar el campo" }],
	};

	useEffect(() => {
		setResidentials(data);
	}, [data]);

	const onChangeFecha = (date, dateString) => {
		console.log(date, dateString);
		searchAccredited(dateString[0], dateString[1]);
	};

	const searchAccredited = async (start, end) => {
		var fechaI = new Date(moment(start, "DD/MM/YYYY"));
		var fechaIn = new Date(fechaI.setHours(0, 0, 0, 0));

		var fechaF = new Date(moment(end, "DD/MM/YYYY"));
		var fechaFi = new Date(fechaF.setHours(23, 59, 59, 0));
		searchAccreditedByDate(fechaIn, fechaFi);
		//[moment(new Date(), dateFormat), moment(new Date(), dateFormat)]
	};

	const countAllCharges = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.total).toFixed(2);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllTaxes = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.taxFee).toFixed(2);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllComissions = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.accFee).toFixed(2);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllTopiaFee = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.feeTopia).toFixed(2);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	const countAllLiquidations = (transData) => {
		let total = 0;
		let totalDiscount = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.total).toFixed(2);
			totalDiscount += +(item.accFee + item.taxFee + item.feeTopia);
		});

		total = parseFloat(total).toFixed(2) - parseFloat(totalDiscount).toFixed(2);
		return total;
	};
	const countSingleLiquidation = (transData) => {
		let total = 0;
		transData.map(async (item, index) => {
			total += +parseFloat(item.settle_amount).toFixed(2);
		});

		total = parseFloat(total).toFixed(2);
		return total;
	};

	function currencyFormat(num) {
		return "$" + num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	}

	const onChangeSelector = (value, data) => {
		if (value === "all") {
			setResidentials(data);
		} else if (value === "clubs") {
			const newData = data.reduce(function (filtered, option) {
				if (option.id === "hspYWQ7JliJRQbeWx10N") {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setResidentials(newData);
		} else if (value === "priv_offices") {
			const newData = data.reduce(function (filtered, option) {
				if (
					option.id === "nqLvvhjKOJ8P6C08iGRk" ||
					option.id === "OwEoolPfoMXQCb6G0LLN"
				) {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setResidentials(newData);
		} else if (value === "residentials") {
			const newData = data.reduce(function (filtered, option) {
				if (
					option.id === "NzDZxRcxqRV2dHtDETj9" ||
					option.id === "bEytjH9pEGS4nsPKf05E" ||
					option.id === "ow5D60c2R0tMWX2m0kx4" ||
					option.id === "vLNhENQZIuDs6h58RAZK" ||
					option.id === "Yd5MV7jkA0MjQeCmzKrZ"
				) {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setResidentials(newData);
		} else if (value === "gym") {
			const newData = data.reduce(function (filtered, option) {
				if (option.id === "cfQ3edDuBHHB09DAeumQ") {
					let newOption = option;
					filtered.push(newOption);
				}
				return filtered;
			}, []);
			setResidentials(newData);
		}
	};

	const handleDetailModal = (statusModal, record = null) => {
		if (record != null) {
			let active_record = record;

			setRecordActive(active_record);
		}
		setTimeout(() => {
			setDetailModalActive(statusModal);
		}, 50);
	};

	const accreditedColumns = [
		{
			title: "NOMBRE",
			dataIndex: "name",
			key: "name",
			onCell: (record) => ({ className: "table-name-cell-invited" }),
			width: "22%",
			...getColumnSearchProps("name"),
		},
		{
			title: "IMPUESTOS",
			dataIndex: "taxes",
			key: "taxes",
			width: "13%",
			sorter: (a, b) => countAllTaxes(a.payments) - countAllTaxes(b.payments),
			render: (text, record) => (
				<>{currencyFormat(countAllTaxes(record.payments).toString())}</>
			),
		},
		{
			title: "COMISIÓN",
			dataIndex: "commission",
			key: "commission",
			width: "13%",
			sorter: (a, b) =>
				countAllComissions(a.payments) - countAllComissions(b.payments),
			render: (text, record) => (
				<>{currencyFormat(countAllComissions(record.payments).toString())}</>
			),
		},
		{
			title: "FEE TOPIA",
			dataIndex: "fee",
			key: "fee",
			width: "13%",
			sorter: (a, b) =>
				countAllTopiaFee(a.payments) - countAllTopiaFee(b.payments),
			render: (text, record) => (
				<>{currencyFormat(countAllTopiaFee(record.payments).toString())}</>
			),
		},
		{
			title: "TOTAL",
			dataIndex: "total",
			key: "total",
			width: "13%",
			onCell: (record) => ({ className: "darkText" }),
			sorter: (a, b) =>
				countAllCharges(a.payments) - countAllCharges(b.payments),
			render: (text, record) => (
				<>{currencyFormat(countAllCharges(record.payments).toString())}</>
			),
		},
		{
			title: "REINTEGRAR",
			dataIndex: "reiny",
			key: "reiny",
			width: "13%",
			onCell: (record) => ({ className: "greenText" }),
			sorter: (a, b) =>
				countSingleLiquidation(a.payments) - countSingleLiquidation(b.payments),
			render: (text, record) => (
				<>
					{currencyFormat(countSingleLiquidation(record.payments).toString())}
				</>
			),
		},

		{
			title: "DETALLE",
			dataIndex: "details",
			key: "details",
			width: "13%",
			render: (id, record) => (
				<img
					className="iconoPerfil"
					src="/assets/icons/details.svg"
					style={{ cursor: "pointer", height: "26px", width: "26px" }}
					alt="Topia"
					onClick={() => handleDetailModal(true, record)}
				/>
			),
		},
	];
	const columnsPayment = [
		{
			title: "FECHA",
			dataIndex: "select",
			key: "select",
			width: "33%",
			align: "center",
			render: (text, record) => <>{record.registered_at}</>,
		},

		{
			title: "NUMERO DE REFERENCIA",
			dataIndex: "settle",
			key: "settle",
			width: "33%",
			align: "center",
			render: (text, record) => <>{record.ref_number}</>,
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
		},

		{
			title: "CUENTA",
			dataIndex: "financial_details.account",
			key: "financial_details.account",
			width: "33%",
			align: "center",
			//sorter: (a, b) => a.constructionFee - b.constructionFee,
			render: (text, record) => (
				<>
					{recordActive.financial_details
						? recordActive.financial_details.account
						: "N/A"}
				</>
			),
		},
	];

	return (
		<>
			{console.log("accredited", residentials)}
			<Row>
				<Col span={24} style={{ marginLeft: "2rem" }}>
					<Space>
						<Select
							defaultValue={{
								value: "all",
								label: "Todos",
							}}
							placeholder="Tipo de cuenta"
							className="select_style"
							style={{ width: "261px" }}
							options={[
								{
									value: "all",
									label: "Todos",
								},
								{
									value: "clubs",
									label: "Clubes",
								},
								{
									value: "priv_offices",
									label: "Oficinas privadas",
								},
								{
									value: "residentials",
									label: "Residenciales",
								},
								{
									value: "gym",
									label: "Gimnasios",
								},
							]}
							onChange={(e) => {
								onChangeSelector(e, data);
							}}
						></Select>
						<RangePicker
							defaultValue={[
								moment(new Date(), dateFormat),
								moment(new Date(), dateFormat),
							]}
							format={customFormat}
							style={{
								width: "270px",
							}}
							onCalendarChange={onChangeFecha}
						/>
					</Space>
				</Col>
				<Col span={24} style={{ marginTop: "3rem" }}>
					<Table
						className="primary-table"
						dataSource={residentials}
						columns={accreditedColumns}
						pagination={{ defaultPageSize: 10, simple: true }}
						loading="true"
						rowKey="id"
						scroll={{ x: "auto", y: "calc(100vh - 19em)" }}
					/>
				</Col>
			</Row>
			<Modal
				className="side-modal"
				title="Detalle de la acreditación"
				style={{ top: 0, right: 0 }}
				visible={detailModalActive}
				onCancel={() => handleDetailModal(false)}
				footer={null}
				bodyStyle={{
					overflowY: "auto",
					maxHeight: "calc(100vh - 100px)",
				}}
				width={600}
			>
				{recordActive.payments ? (
					<>
						{console.log("recordactive", recordActive.payments)}
						<Row className="section-modal" style={{ paddingTop: "0px" }}>
							<Col span={24}>
								<p>CUENTA</p>
							</Col>
							<Col span={18}>
								<h2>{recordActive.name}</h2>
							</Col>
							<Col span={6} align="middle">
								<p>TOTAL:</p>
								<h3 style={{ color: "#10B48C" }}>
									{currencyFormat(
										countAllCharges(recordActive.payments).toString()
									)}
								</h3>
							</Col>
						</Row>
						<Row className="section-modal" style={{ paddingTop: "0px" }}>
							<Col span={24}>
								<Table
									className="primary-table"
									dataSource={recordActive.payments}
									columns={columnsPayment}
									pagination={{ defaultPageSize: 8, simple: true }}
									rowKey="id"
								/>
							</Col>
						</Row>
					</>
				) : (
					<>Datos no encontrados</>
				)}
			</Modal>
		</>
	);
};
