import React, { useEffect, useState } from "react";
import {
	Col,
	Row,
	Layout,
	Tabs,
	Button,
	DatePicker,
	Modal,
	Card,
	Radio,
	Space,
	Form,
	Table,
	Switch,
	Input,
	Select,
} from "antd";

export const AppBanner = ({ bannerColor, title, description }) => {
	const [color, setColor] = useState("#10B48C");
	const [bannerTitle, setBannerTitle] = useState("");
	const [bannerDescription, setBannerDescription] = useState("");
	useEffect(() => {
		setBannerTitle(title);
		setBannerDescription(description);
	}, []);
	/* useEffect(() => {
		setColor(banner);
	}, [banner]); */
	return (
		<>
			<div style={{ height: "96px", width: "320px" }}>
				<Card
					bodyStyle={{
						borderRadius: "16px",
						backgroundColor: bannerColor,
						height: "96px",
						width: "320px",
						margin: "0px",
						padding: "0px",
					}}
				>
					<Row>
						<Col span={6}>
							<div>
								<img src="/assets/icons/handIcon.svg" />
							</div>
						</Col>
						<Col
							span={18}
							style={{ maxWidth: "230px", display: "inline-block" }}
						>
							<div
								style={{
									marginTop: "15px",
									marginLeft: "20px",
									marginRight: "5px",
								}}
							>
								<span
									style={{
										fontSize: "17px",
										fontWeight: "500",
										lineHeight: "22px",
										textAlign: "right",
										color: "white",
									}}
								>
									{title}
								</span>
								<br />
								<span
									style={{
										fontSize: "10px",
										fontWeight: "400",
										lineHeight: "8px",
										textAlign: "right",
										color: "white",
										textTransform: "uppercase",
									}}
								>
									{description}
								</span>
							</div>
						</Col>
					</Row>
				</Card>
			</div>
		</>
	);
};
